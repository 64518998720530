@import "assets/css/_base.scss";

.timeline {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: rem(8);
  box-shadow: $box_shadow_icon;
  padding: rem(16);
  background-color: $col_white;

  .timeline-header {
    display:inline-flex;
    justify-content: right;

    .title {
      display: flex;
      margin-right: auto;
      font-size: rem(18);
      color: $col_gray-600;
      font-weight: $fw_5;
    }

    .preview-control-search {
      display: flex;
      align-items: center;
      margin-left: rem(15);
  
      input {
          height: rem(28) !important;
          font-size: rem(14) !important;
      } 
    }

    .preview-control-interval {
      display: flex;
      align-items: right;
      justify-content: right !important;

      .timeline-dropdown {
        height: rem(28);
        max-width: rem(102);

        span {
          font-size: rem(12);
          font-weight: $fw_4;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: rem(1) solid $col_gray-800;
    border-radius: rem(8);
    margin-top: rem(14);
    padding-bottom: rem(21);

    .time-line-selector {
      padding: rem(16);
      border-bottom: rem(1) solid $col_gray-800;
    }

    .time-line-info {
      padding: rem(16);
      position: relative;

      .time-line-info-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        p,
        span {
          font-size: rem(24);
          font-weight: $fw_5;
          color: $col_gray-600;
        }

        span {
          font-size: rem(20);
        }
      }

      .hidden {
        visibility: hidden;
      }
    }

    .timeline-body {
      display: flex;
      height: 100%;

      .loading-container {
        display: flex;
        width: 100%;
        align-items: center;
      }

      .timeline-content {
        display: flex;
        width: 100%;
        flex-direction: column;

        .empty-data {
          width: 100%;
          height: 100%;
        }
      }

      .card {
        border: none;
        padding: rem(16) rem(16) 0;

        .total-record {
          font-weight: $fw_4;
          font-size: rem(16);
          @include text-center;
          min-width: rem(40);
          color: $col_gray;
        }

        &-header {
          background-color: transparent;
          border: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;

          .break-line {
            width: 100%;
            border-top: rem(1) solid $col_gray-800;
            margin: 0 rem(16);
          }
        }

        .card-body {
          padding: 0;
        }
      }

      .card-type {
        padding: rem(16) 0 0;

        .card-body-type {
          padding: rem(20) 0 rem(10);

          .time-line-list-2-columns {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-between;
            gap: rem(15);
            column-gap: rem(45);
          }

          .time-line-list-1-column {
            display: grid;
            grid-template-columns: 1fr;
            justify-content: space-between;
            gap: rem(15);
          }
          
          .time-line-list {
            display: grid;
            grid-template-columns: repeat(auto-fill, rem(254));
            justify-content: space-between;

            .time-line-list-group {
              position: relative;

              &::before,
              &::after {
                content: "";
                position: absolute;
                border: rem(1) solid $col_gray-800;
                border-radius: rem(8);
                background: $col-white;
                min-height: rem(80);
                width: rem(245);
              }

              &::before {
                top: rem(19);
                left: rem(4);
                z-index: 10;
              }

              &::after {
                top: rem(22);
                left: rem(8);
                z-index: 9;
                box-shadow: $box_shadow;
              }

              :global(.container-group) {
                width: rem(245);
                height: rem(80);
                min-height: rem(60);
              }
            }
          }
        }
      }
    }
  }
}

@media print {
  .timeline {
    .card-body-type {
      padding-top: 0 !important;
    }

    .time-line-list {
      grid-template-columns: repeat(3, 32%) !important;
    }
  }

  .time-line-list-group {

    :global(.container-group),
    &::before,
    &::after {
      width: calc(100% - rem(7)) !important;
    }
  }

  .timeline,
  .content,
  .timeline-content,
  .card {
    display: block !important;
  }

  .content {
    height: auto !important;
  }

  .timeline-body {
    height: auto !important;
    display: block !important;

    .card {
      background: none;
    }
  }

  @-moz-document url-prefix() {
    .time-line-list-group {

      :global(.container-group),
      &::before,
      &::after {
        width: rem(225) !important;
      }
    }
  }
}
