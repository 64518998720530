@import "assets/css/_base.scss";

.time-btn {
  min-width: rem(85);
  height: rem(28);
  background-color: $col_gray;
  border: none;
  border-radius: rem(4);
  font-weight: $fw_4;
  font-size: rem(16);
  color: $col_white;
}

.arrow-down,
.arrow-up {
  cursor: pointer;
  width: rem(6);
  height: rem(8);
}

.arrow-up {
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.type-icon {
  display: flex;
  margin-left: rem(8);
  .type {
    white-space: nowrap;
    margin-left: rem(13);
    font-weight: $fw_4;
    font-size: rem(16);
    color: $col_gray;
  }
}
