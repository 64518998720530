@import "assets/css/_base.scss";

.search-selector {
  height: 100%;
  display: flex;
  flex-direction: column;
  .ss-header {
    display: flex;
    justify-content: space-between;
  }
  .ss-body {
    border: rem(1) solid $col_gray-300;
    padding: rem(8);
    border-radius: rem(4);
    margin-top: rem(8);
    height: calc(100% - rem(30));
    display: flex;
    flex-direction: column;
    .ss-actions {
      position: relative;
      border: rem(1) solid $col_gray-300;
      padding: rem(8);
      border-radius: rem(4);
      margin-bottom: rem(8);
      display: flex;
      .ss-search {
        display: flex;
        align-items: center;
        border-left: rem(1) solid $col_gray-300;
        padding-left: rem(12);
        margin-left: rem(12);
        img {
          width: rem(16);
          height: rem(16);
          margin-right: rem(10);
        }
        input {
          border: none;
          background-color: transparent;
          font-size: rem(12);
          &:disabled {
            cursor: not-allowed;
          }
          &:focus {
            border: none;
            outline: none;
          }
        }
      }
    }
    .ss-check-list {
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0;
      @include scroll-flexible;
      .ss-check-item {
        font-size: rem(12);
        display: flex;
        align-items: center;
        padding: rem(6) rem(8);

        label {
          span {
            width: rem(280);
            display: block;
            @include text-ellipsis;
          }
        }
      }
      .ss-no-result {
        @include text-center;
        font-size: rem(12);
        margin-top: rem(16);
      }
    }
  }
}
.suggestions-wrapper {
  position: absolute;
  top: calc(100% - rem(10));
  right: rem(-2);
  min-width: rem(220);
  z-index: 99;
  &.suggestions-wrapper-reverse {
    top: unset;
    bottom: calc(100% + rem(8));
  }
  .suggestions {
    list-style: none;
    max-height: rem(170);
    overflow-y: auto;
    width: 100%;
    background-color: $col_white;
    font-size: rem(14);
    box-shadow: $box_shadow;
    border-radius: rem(4);
    padding: rem(6) 0;
    margin-top: rem(16);
    @include scroll-flexible;

    li {
      padding: rem(6) rem(12);
      .no-highlight {
        color: $col_gray-400;
      }
      .highlight {
        font-weight: bold;
      }
    }
  }

  .suggestion-active,
  .suggestions li:hover {
    font-weight: $fw_5;
    color: $col_blue;
    background-color: $col_blue-3;
    cursor: pointer;
  }
}
.ss-item-selected {
  font-weight: $fw_5;
  color: $col_blue;
  background-color: $col_blue-3;
}

@media screen and (min-width: 3840px) {
  .search-selector {
    .ss-body {
      .ss-check-list {
        .ss-check-item {
          label {
            span {
              width: rem(370);
            }
          }
        }
      }
    }
  }
}
