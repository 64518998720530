@import "assets/css/_base.scss";
@import url('https://cdn.syncfusion.com/ej2/material.css');

:global {
  .spinner {
    display: flex;
    justify-content: center;
    margin-top: 80rem;
  }

  .empty-record {
    display: flex;
    justify-content: center;
    margin-top: 35rem;
    font-size: rem(16);
  }

  .e-grid .e-headercontent {
    border-right: none;
  }

  .e-btn.e-flat.e-primary, 
  .e-css.e-btn.e-flat.e-primary {
    color: $col_blue;
  }

  .e-btn.e-flat.e-primary:hover, 
  .e-css.e-btn.e-flat.e-primary:hover {
      color: $col_blue !important;
      background: rgba($col_blue, 0.1) !important;
      border-radius: 0.8rem !important;
  }

  .e-btn.e-flat.e-primary:active, 
  .e-css.e-btn.e-flat.e-primary:active,
  .e-btn.e-flat.e-primary:focus,
  .e-css.e-btn.e-flat.e-primary:focus {
    color: $col_blue;
    background: none;
  }

  .e-dialog .e-dlg-content {
    border-radius: 0.8rem !important;
  }

  .e-dialog .e-footer-content {
    border-radius: 0.8rem !important;
  }

  .e-control .e-dialog .e-lib .e-dlg-modal .e-popup .e-popup-open {
    border-radius: 0.8rem !important;
  }

  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
  .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: $col_blue;
  }

  input.e-input::selection, textarea.e-input::selection, .e-input-group input.e-input::selection,
  .e-input-group.e-control-wrapper input.e-input::selection, .e-float-input input::selection,
  .e-float-input.e-control-wrapper input::selection, .e-input-group textarea.e-input::selection,
  .e-input-group.e-control-wrapper textarea.e-input::selection, .e-float-input textarea::selection,
  .e-float-input.e-control-wrapper textarea::selection {
    background: $col_blue;
    color: #fff;
  }

  .e-droppable {
    background-color: #f9f9f9 !important;
  }

  .e-grid .e-gridheader  {
    padding-right: 17px !important;
  }

  .e-lastcell {
    border: none !important;
  }

  .e-grid {
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.08);
    border-radius: 0.8rem !important;
  }

  .e-grid .e-gridheader {
    border-color: #e0e0e0;
    border-left-style: solid;
    border-left-width: 1px;
  }

  .e-grid .e-toolbar {
    border-color: #e0e0e0;
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }

  .e-grid .e-toolbar-items {
    border-top-left-radius: 0.8rem;
  }

  .e-content {
    border-color: #e0e0e0;
    border-style: none solid solid;
    border-width: 1px;
    border-bottom-left-radius: 0.8rem;
     border-bottom-right-radius: 0.8rem;
  }

  .e-gridcontent {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }

  .e-grid .e-headercell {
    background-color: #f9f9f9;
  }
}

.search-terms-dashboard {
  &__table {
    margin-top: rem(20);
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.08);
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
}
