@import "assets/css/base";

.wrap {
  min-height: rem(148);

  .label {
    @include flex-between;
    margin-bottom: rem(12);

    h5 {
      font-size: rem(12);
      line-height: rem(16);
      font-weight: $fw_7;
      color: $col_gray-600;
    }
  
    .see-more {
      position: relative;
      cursor: pointer;
  
      >span {
        display: flex;
        align-items: center;
      }
  
      &-label {
        color: $col_blue;
        font-size: rem(12);
        line-height: rem(16);
        font-weight: $fw_4;
        font-style: italic;
        margin-right: rem(10);
        user-select: none;
      }
  
      &-icon {
        border-radius: rem(2);
        width: rem(14);
        height: rem(14);
        background: $col_white;
        border: rem(1) solid $col_blue;
      }
  
      &-list {
        padding: rem(16);
        position: absolute;
        background: $col_white;
        box-shadow: $box_shadow;
        border-radius: rem(4);
        right: 0;
        top: rem(24);
        width: rem(300);
        max-height: rem(214);
        overflow-x: hidden;
        overflow-y: auto;
        @include scroll-flexible;
        z-index: 999;

        .associated-item {
          margin-bottom: rem(8);
          cursor: text;

          span {
            @include text-ellipsis;
          }

          span {
            @include text-ellipsis;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .no-more {
        font-weight: $fw_4;
        font-size: rem(16);
        line-height: rem(24);
        color: $col_gray-600; 
      }
    }
  }

  .associated-item {
    display: flex;
    align-items: center;
    margin-bottom: rem(12);

    img {
      width: rem(20);
      height: rem(20);
      margin-right: rem(10);
    }

    span {
      font-weight: $fw_4;
      font-size: rem(16);
      line-height: rem(24);
      color: $col_gray-600;
      @include text-ellipsis;
    }
  }

  .no-result {
    font-size: rem(16);
    line-height: rem(24);
    font-weight: $fw_4;
    color: $col_gray-400;
    min-height: rem(120);
    @include flex-center;
  }
}

@media print {
  @-moz-document url-prefix() {
    .wrap {
      .associated-item {
        img {
          width: rem(16);
          height: rem(16);
          margin-right: rem(4);
        }
    
        span {
          font-size: rem(12);
          line-height: rem(16);
        }
      }

      .no-result {
        font-size: rem(12);
        line-height: rem(16);
      }
    }
  }
}
