@import "assets/css/base";

.location-container {
  &::before {
    content: "React Azure Map is not supported to print PDF in this browser. Please use Chrome or Microsoft Edge.";
    font-size: rem(16);
    display: none;
  }

  height: 100%;

  :global(.azure-map) {
    height: 100%;

    :global(.mapboxgl-canvas) {
      width: 100% !important;
      height: 100% !important;
    }
  }

  p {
    font-size: rem(16);
  }
}

@media print {
  .location-container {
    height: rem(614) !important;
    width: 100% !important;
  }

  @-moz-document url-prefix() {
    .location-container {
      &::before {
        display: block;
      }

      :global(.azure-map) {
        display: none;
      }
    }
  }
}