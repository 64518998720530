@import "assets/css/_base.scss";

.key-block {
  .kb-actions {
    display: flex;
    img {
      cursor: pointer;
      margin-left: rem(10);
    }
    &.kb-actions-child {
      border-left: none;
      padding-top: 0;
      &::before {
        margin-left: 0;
      }
    }
  }
  .kb-within-words {
    font-size: rem(12);
    color: $col_gray;
    margin-left: rem(4);
  }
  .kb-within-empty {
    border: rem(1) solid $col_red !important;
  }

  .kb-footer {
    margin-bottom: rem(16);
    .kb-add {
      display: flex;
      align-items: center;
      img,
      span {
        cursor: pointer;
      }
      img {
        &:active {
          opacity: 0.8;
        }
      }
      span {
        padding-left: rem(12);
        color: $col_blue;
        font-size: rem(14);
      }
      &.kb-add-disabled {
        img,
        span {
          cursor: not-allowed;
        }
        span {
          color: $col_gray-300;
        }
      }
    }
  }
  .kb-method {
    margin-bottom: rem(16);
    &.kb-method-nested {
      margin-bottom: rem(8);
    }
  }
  &:last-of-type {
    .kb-footer {
      .kb-method {
        padding-top: rem(16);
      }
    }
  }
}
.kb-input {
  flex: 1;
  height: rem(30);
  border-radius: rem(4);
  border: rem(1) solid $col_gray-300;
  padding: 0 rem(10);
  font-size: rem(12);
  &:focus {
    border-color: $col_blue;
    outline: none;
  }
  &.kb-input-within {
    display: inline-block;
    width: rem(72);
    height: rem(28);
    margin: 0 rem(12);
    flex: none;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    -moz-appearance: textfield;
  }
}
.kb-nested-child {
  margin-left: rem(8);
  .kb-actions {
    margin: 0;
    border-left: rem(1) solid $col_gray-300;
    &::before {
      content: "";
      width: rem(12);
      border-bottom: rem(1) solid $col_gray-300;
      border-left: rem(1) solid $col_gray-300;
      border-bottom-left-radius: rem(8);
      margin-bottom: rem(12);
      margin-left: rem(-1);
    }
  }
  .kb-add {
    padding-left: rem(12);
    padding-top: rem(16);
  }
  .kb-nested-method {
    display: flex;
    align-items: center;
    padding-left: rem(12);
    padding-bottom: rem(16);
    border-left: rem(1) solid $col_gray-300;
  }
}

.kb-nested-child-1 {
  .kb-nested-method {
    padding-bottom: rem(16);
    padding-top: rem(16);
    &.kb-nested-method-main {
      padding-bottom: rem(8);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &:last-of-type {
      padding-bottom: rem(10);
    }
  }
  .kb-add {
    img {
      width: rem(20);
    }
    &:last-of-type {
      padding-bottom: rem(10);
    }
  }
  &:first-of-type {
    .kb-actions {
      padding-top: rem(8);
    }
  }
  &.kb-no-border {
    > .kb-nested-method {
      border-left: none;
    }
    .kb-nested-child-2 {
      border-left: none;
    }
  }
  &.kb-last-border {
    > .kb-actions {
      border-left: none;
      padding-left: rem(1);
    }
  }
}

.kb-nested-child-2 {
  margin-left: 0;
  padding-left: rem(20);
  border-left: rem(1) solid $col_gray-300;
  .kb-nested-first {
    padding-top: rem(8);
  }
  .kb-nested-method {
    padding-bottom: rem(16);
  }
  .kb-add {
    padding-bottom: rem(10);
    img {
      width: rem(16);
    }
  }
}
