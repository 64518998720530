@import "assets/css/base";

.create-export {
  &-header {
    @include flex-between;
    width: 100%;
    margin: rem(8) 0 rem(20) 0;

    h2 {
      font-size: rem(32);
      color: $col_gray-600;
      width: 100%;
    }
  }

  .export-form-block {
    height: rem(900);
    display: flex;
    background-color: $col_white;

    .export-detail {
      width: 78%;
      box-shadow: $box_shadow;
      padding: rem(24) rem(32);
      border-radius: rem(8);
      position: relative;

      &-footer-line {
        width: 100%;
        border: rem(1) solid $col_gray-700;
        opacity: 0.5;
        position: absolute;
        top: calc(100% - rem(90));
        left: 0;
      }
    }

    .export-preview {
      width: 22%;
      margin-left: rem(32);
      box-shadow: $box_shadow;
      padding: rem(24) rem(32);
      border-radius: rem(8);
      overflow-x: hidden;
      overflow-y: auto;
      @include scroll-flexible;
    }
  }
}

.no-events {
  pointer-events: none;
}

@media print {
  .create-export {
    &-header {
      display: none;
    }
    .bread-crumb {
      display: none;
    }
  }
  .export-form-block {
    .export-detail {
      &-footer-line {
        display: none;
      }
    }
  }
}
