@import "assets/css/base";

.modal-container {
  .modal-dialog {
    min-width: rem(480);

    .modal-content {
      padding: rem(24);
      border-radius: rem(8);

      .add-member-popup {
        &__title {
          font-weight: $fw_5;
          font-size: rem(18);
          line-height: rem(28);
          color: $col_gray-600;
        }

        &__close {
          cursor: pointer;
          position: absolute;
          top: rem(16);
          right: rem(16);
        }

        &__search {
          padding-top: rem(12);

          :global(.search-wrapper) {
            :global(.input-autocomplete) {
              width: 100%;

              input {
                width: 100%;
              }
            }
          }
        }

        &__members {
          margin-top: rem(16);
          background: $col_white;
          border: rem(1) solid rgba(211, 211, 211, 0.5);
          box-sizing: border-box;
          border-radius: rem(8);
          padding: rem(16);
          max-height: rem(495);
          overflow-y: auto;
          min-height: rem(200);
          position: relative;

          .no-users {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: $fw_4;
            font-size: rem(16);
            line-height: rem(24);
            color: $col_gray;
          }

          .member-list__item {
            display: flex;

            &:not(:first-child) {
              padding-top: rem(16);
            }

            .user-avatar {
              width: rem(32);
              height: rem(32);
              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: rem(4);
              }
            }

            .user-name {
              padding-left: rem(12);
              font-weight: $fw_5;
              font-size: rem(16);
              line-height: rem(24);
              display: flex;
              align-items: center;
              color: $col_gray-600;
            }

            .user-action {
              margin-left: auto;

              .btn-remove-user {
                width: rem(71);
                height: rem(28);
                background: $col_orange;
                border-radius: rem(4);
                color: $col_white;
                font-weight: $fw_4;
                font-size: rem(14);
                line-height: rem(20);
                border: 0;
              }
            }
          }
        }
      }
    }
  }
}