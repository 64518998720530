@import "assets/css/base";

.loading {
  .spinner-border-edit {
    position: fixed;
    width: rem(30);
    height: rem(30);
    top: 40%;
    left: 0;
    z-index: 9999;
    right: 0;
    margin: 0 auto;
  }

  .loading-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    z-index: 9998;
    opacity: 1;
  }
  .blur-background {
    background-color: $col_black;
    opacity: 0.4;
  }
}

.component-loading {
  .spinner-border-edit {
    position: fixed;
    width: rem(30);
    height: rem(30);
    top: 40%;
    left: 0;
    z-index: 9999;
    right: 0;
    margin: 0 auto;
  }

  .loading-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    z-index: 9998;
    opacity: 1;
  }
}
