@import "assets/css/base";

.select-item {
  @include flex-between;
  margin-bottom: rem(12);

  .name-group {
    @include flex-between;
    cursor: pointer;
    @include text-ellipsis;

    :global(span) {
      font-weight: 400;
      font-size: rem(12);
      color: $col_gray-600;
      @include text-ellipsis;
    }

    .icon-type {
      width: rem(16);
      height: rem(16);
      margin-right: rem(8);
    }

    .syncfusion-icon-circle-remove {
      width: rem(16);
      height: rem(16);
      margin-right: rem(5);
      margin-left: rem(2);
    }
  }

  .emoji {
    :global(span) {
      font-weight: 400;
      font-size: rem(16) !important;
      color: $col_gray-600;
      width: rem(24);
      @include flex-center;
    }

    :hover {
      background-color: rgba($col_blue, 0.15);
      border-radius: rem(4);
    }
  }

  .icon-select {
    cursor: pointer;
  }

  .not-allowed {
    cursor: not-allowed !important;
  }
}