@import "assets/css/base";

.wrap {
  background: $col_white;
  border: rem(1) solid $col_gray-50;
  border-radius: rem(8);
  box-shadow: $box_shadow_icon;
  padding: rem(24);
  min-height: rem(770);
  width: 100%;
  position: relative;
  margin: 0 rem(32) rem(32) 0;

  .select-entity {
    position: absolute;
    right: rem(16);
    top: rem(16);
    z-index: 9;

    input {
      display: none;
    }

    label {
      > span {
        display: block;
        margin: 0;
        width: rem(16);
      }
    }
  }

  .entity-avatar {
    @include flex-center;
    position: relative;
    margin-bottom: rem(20);

    figure {
      width: rem(160);
      height: rem(160);
      border-radius: 50%;
      position: relative;
      background-color: $col_blue-15;
      @include flex-center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }

      .default-avatar {
        width: rem(64);
        height: rem(64);
        border-radius: unset;
      }
    }

    &-update {
      position: absolute;
      top: calc(100% - rem(12));
      left: 50%;
      transform: translateX(-50%);
      background-color: $col_blue;
      border-radius: 50%;
      @include flex-center;
      width: rem(24);
      height: rem(24);
      cursor: pointer;

      img {
        width: rem(16);
        height: rem(16);
      }
    }
  }

  .entity-info {
    @include text-center;
    font-size: rem(16);
    line-height: rem(24);
    color: $col_gray-400;
    margin-bottom: rem(16);

    .entity-name {
      font-size: rem(24);
      line-height: rem(32);
      font-weight: $fw_4;
      color: $col_black;
      width: 100%;
      @include text-ellipsis;
      margin-bottom: rem(8);
    }

    .job-title,
    .company {
      font-size: rem(16);
      line-height: rem(24);
      color: $col_gray-400;
      @include text-ellipsis;
    }
  }

  .label {
    font-size: rem(12);
    line-height: rem(16);
    font-weight: $fw_7;
    margin-bottom: rem(12);
    color: $col_gray-600;
  }

  .entity-note,
  .entity-statistics,
  .associated-identifier {
    margin-bottom: rem(24);
  }

  .entity-note {
    p {
      min-height: rem(48);
      font-size: rem(16);
      line-height: rem(24);
      font-weight: $fw_4;
      color: $col_gray-400;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }

  .entity-statistics {
    font-size: rem(14);

    h5 {
      font-size: rem(12);
      line-height: rem(16);
      font-weight: $fw_7;
      margin-bottom: rem(12);
      color: $col_gray-600;
    }

    dl {
      @include flex-between;
      dd {
        margin-left: auto;
      }
    }
  }

  .edit-button {
    position: absolute;
    top: calc(100% - rem(64));
    left: 50%;
    transform: translateX((-50%));
  }
}

@media print {
  .wrap {
    margin-bottom: rem(100);
  }

  @-moz-document url-prefix() {
    .wrap {
      margin-bottom: 0;
      min-height: fit-content !important;

      .entity-avatar {
        figure {
          width: rem(120);
          height: rem(120);
        }
        .default-avatar {
          width: rem(50) !important;
          height: rem(50) !important;
        }
      }

      .entity-info {
        .entity-name {
          font-size: rem(16);
          line-height: rem(24);
        }

        .job-title,
        .company {
          font-size: rem(12);
          line-height: rem(16);
        }
      }

      .entity-note p {
        min-height: fit-content !important;
      }

      .entity-note,
      .entity-statistics {
        margin-bottom: rem(16);
      }

      dl {
        margin-bottom: 0;
        dd, dt {
          font-size: rem(13);
          line-height: rem(16);
        }
      }
    }
  }
}
