@import "assets/css/base";

.record-control-wrap {
    display: flex;
    font-size: 0;
    justify-content: flex-end;
    flex: 1;

    .record-id {
        min-width: rem(28);
        height: rem(28);
        background-color: #e9e9e9;
        border-radius: rem(6);
        margin-right: rem(12);
        display: flex;
        align-items: center;

        p {
        font-size: rem(16);
        @include text-center;
        width: 100%;
        padding: 0 rem(6);
        }
    }

    .btn {
        width: rem(28);
        height: rem(28);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        transition: opacity 0.3s ease;

        &:hover {
        opacity: 0.7;
        }

        &:disabled {
        background-color: $col_white;
        border: rem(1) solid $col_gray-300;
        border-radius: rem(4);
        cursor: not-allowed;
        }
    }
}

.btn-previous-record,
.btn-next-record {
    border: rem(1) solid $col_blue;
    height: rem(28);
    width: rem(28);
    padding: 0;
    border-radius: rem(4);
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 0.7;
    }
}
