@import "assets/css/base";

.is-result {
  background: $col_white;
  box-shadow: $box_shadow;
  border-radius: rem(8);
  padding: rem(14);

  .is-result-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: rem(10);

    .is-result-head-title {
      font-weight: $fw_5;
      font-size: rem(18);
      color: $col_gray-600;
    }

    .is-result-search-control {
      display: flex;
      align-items: center;

      .is-show-recent {
        display: flex;
        margin-right: rem(8);

        &__title {
          font-weight: $fw_4;
          font-size: rem(12);
          margin-left: rem(8);
          white-space: nowrap;
        }
      }

      input {
        height: rem(28);
        font-size: rem(14);
      }
    }
  }

  .is-result-list {
    width: 100%;
    height: 100%;

    .is-result-table {
      border: rem(1) solid $col_gray-800;
      box-sizing: border-box;
      border-radius: rem(8);
      border-collapse: unset;
      border-spacing: inherit;
      max-height: rem(250);
      overflow: auto;
      @include scroll-flexible;
      @include scroll-flexible-horizontal;

      table {
        width: 120%;

        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }

        thead {
          font-weight: bold;
          font-size: rem(12);
          color: $col_gray-400;
          background: $col_gray-50;
          height: rem(48);
          position: sticky;
          position: -webkit-sticky; /* Safari */
          top: 0;
          z-index: 9;

          th {
            text-align: left;
            padding-left: rem(15);
            color: $col_gray-400;
            border-bottom: rem(1) solid $col_gray-800;

            a {
              position: relative;
              white-space: pre-wrap;
              color: $col_gray-400;
              cursor: pointer;

              &::after {
                position: absolute;
                left: 100%;
                top: calc(100% - rem(10));
                margin-left: rem(6);
                border-left: rem(4) solid transparent;
                border-right: rem(4) solid transparent;
                border-top: rem(6) solid $col_gray-400;
                clear: both;
              }
            }

            .disable-pointer {
              pointer-events: none;
            }

            a.sort-asc::after {
              content: "";
              border-top: none;
              border-bottom: rem(6) solid $col_gray-400;
            }

            a.sort-desc::after {
              content: "";
              border-top: rem(6) solid $col_gray-400;
              border-bottom: none;
            }
          }
        }

        tbody {

          tr {
            font-size: rem(12);
            height: rem(48);

            td {
              padding-left: rem(16);
              color: $col_gray-600;
              position: relative;

              p {
                margin: rem(5) 0;
                cursor: pointer;
              }
            }

            .participant {
              padding-right: rem(10);
              white-space: pre-wrap;

              .background {
                background-color: $col_gray-300;
                padding: rem(7) rem(10);
                min-height: rem(32);
                border-radius: rem(4);
                cursor: pointer;
              }
              
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }

            td.description span {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }

          tr:hover,
          .conv-select {
            background: $color_lavender;
          }
        }

        td {
          &:last-child,
          &:nth-last-child(2),
          &:nth-last-child(3) {
            padding-left: rem(20);
          }
        }

        th,
        td {
          width: 10%;
          min-width: 2%;

          &:first-child { // Checkbox
            width: 3%;
          }

          &:nth-child(2) { // Type
            width: 5%;
          }

          &:nth-child(3) { // App
            width: 5%;
          }

          &:nth-child(4) { // Record ID
            width: 11%;
          }

          &:nth-child(5) { // Data Source
            width: 10%;
          }

          &:nth-child(6) { // Participants
            width: 20%;
          }

          &:nth-child(7), // Date First
          &:nth-child(8) { // Date Last
            width: 12%;
          }

          &:nth-child(9) { // Description
            width: 18%;
          }
          
          &:nth-child(10), // Message Count
          &:nth-child(11), // Attachment Count
          &:nth-child(12) {// Duration
            width: 8%;
          } 
          &:nth-child(13) { // Deleted
            width: 6%;
          }
        }
        

      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items:center;
      height: rem(32);
      margin-bottom: rem(6);

      .tagging {
        margin-top: rem(20);
        margin-right: rem(8);
      }

      .pagination-results {
        width: 100%;
      }
    }

    .no-result-wrapper {
      .no-result {
        width: 100%;
        @include text-center;
        margin-top: rem(40);
        margin-bottom: rem(32);
        font-size: rem(32);
        font-weight: normal;
        color: $col_gray-400;
      }
    }

    .spinner-border {
      margin: rem(32) auto;
    }

    .disable-pointer {
      cursor: default;

      &:hover {
        color: $col_gray-600;
      }
    }
  }
}
