@import "assets/css/_base.scss";

.emoji-container {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  overflow-y: auto;
  @include scroll-flexible;

  .information-emoji-cloud {
    position: relative;
    padding: 0 rem(12) 0;

    .information-emoji-cloud-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, rem(42));
      justify-content: space-between;

      .emoji-item {
        margin: 0 rem(10) rem(10) 0;
        width: rem(32);

        span.emoji {
          @include text-center;
          width: 100%;
          display: inline-block;
          font-size: rem(24);
          cursor: pointer;
        }
      }
    }

    .empty-page {
      width: 100%;
      @include text-center;
      color: $col_gray-400;
      padding-bottom: rem(5);
      font-size: rem(14);
    }
  }

  .spinner-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include scroll-flexible;
    min-height: rem(100);
    max-height: rem(218);
    overflow-y: auto;
  }
}

.emoji-container-table-view {
  margin-top: rem(10);
  padding-left: rem(16);

  .information-emoji-cloud {
    max-height: rem(180);
    height: 100%;
    margin-top: 0;
  }
}

@media print {
  .emoji-container {
    .information-emoji-cloud {
      height: rem(218);
      overflow-y: hidden;
    }
  }
}