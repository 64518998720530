@import 'BaseAttachmentRenderer.module.scss';

.html-renderer {
    height: 100%;
    white-space: pre-wrap;
    padding: rem(8);
    overflow: auto;
    @include scroll-flexible;
}

.html-renderer-hidden {
    visibility: hidden;
}
