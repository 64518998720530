@import "assets/css/base";

.project-no-results {
  width: 100%;
  @include text-center;
  margin-top: rem(25);
  font-weight: $fw_4;
  font-size: rem(24);
  line-height: rem(40);
  color: $col_gray-400;
}

.not-found-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty {
  &-md {
    .not-found-image {
      img {
        max-width: rem(250);
      }
    }

    .project-no-results {
      font-size: rem(16);
      line-height: rem(24);
    }
  }

  &-sm {
    .not-found-image {
      img {
        max-width: rem(120);
      }
    }

    .project-no-results {
      font-size: rem(12);
      line-height: rem(16);
      color: $col_gray-600;
      margin-top: rem(8);
    }
  }

  &-timeline {
    .not-found-image {
      img {
        width: rem(250);
      }
    }

    .project-no-results {
      font-size: rem(18);
    }
  }

  &-empty-image {
    .project-no-results {
      margin-top: rem(18);
      line-height: rem(24);
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .not-found-image {
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .project-no-results {
      font-size: rem(14);
    }
  }
}
