@import "assets/css/base.scss";

.entity-card-list {
  .sub-header {
    @include flex-between;
    padding: rem(12) 0;
    color: $col_gray;

    span {
      width: 100%;
    }

    .total-record {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-weight: $fw_4;
      font-size: rem(14);
      color: $col_gray;
    }
  }
  .entity-card {
    min-height: rem(600);
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(100% / 3 - rem(22)));
    justify-content: space-between;
    position: relative;

    .no-result {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (min-width: 3840px) {
  .entity-card-list {
    .entity-card {
      grid-template-columns: repeat(auto-fill, calc(100% / 4 - rem(22)));
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .entity-card-list {
      .entity-card {
        min-height: fit-content !important;
      }
    }
  }
}
