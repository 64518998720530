@import "assets/css/_base.scss";

.confirm-delete-member-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff88;
  z-index: 100;

  .confirm-delete-member-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    width: rem(320);
    height: rem(181);
    background: $col_white;
    box-shadow: $box_shadow;
    border-radius: rem(4);
    flex-wrap: wrap;

    p {
      width: 100%;
      @include text-center;
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      margin-bottom: rem(40);
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn-common + .btn-common {
        margin-left: rem(16);
      }
      button {
        font-weight: $fw_5;
        font-size: rem(14);
        line-height: rem(20);
        padding: rem(6) rem(16);
      }

      .yes {
        background: $col_orange;
        color: $col_white;
        border-radius: rem(4);
        border: 0;
      }

      .no {
        border: 0;
        color: $col_gray-600;
        text-decoration: none;
        margin-right: rem(8);
      }
    }
  }
}
