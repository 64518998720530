@import "assets/css/base";

.wrap {
  height: 100%;

  .data-source-info ul {
    height: fit-content;
    max-height: rem(120);
  }

  .participants-info ul {
    height: fit-content;
    max-height: rem(436);

    li ul {
      height: fit-content;
      max-height: rem(210);
    }
  }
}

.wrap-preview {
  .data-source-info ul {
    height: fit-content;
    max-height: rem(120);
  }

  .participants-info ul {
    height: fit-content;
    max-height: rem(200);
  }
}