@import "assets/css/base";

.is-wrap {
  .is-head {
    .is-head-control {
      margin: rem(8) 0 rem(24) 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .is-head-info {
        .is-head-title {
          font-size: rem(32);
        }
      }

      .is-head-print {
        display: flex;
        align-items: center;
        gap: rem(10);
      }
    }
  }

  .is-body {
    display: flex;

    .is-quick-select {
      min-height: rem(700);
      width: 23%;
      padding-right: rem(32);
    }

    .is-search-result {
      flex: 1;
      width: 77%;
    }

    .is-result-info-and-preview {
      margin-top: rem(32);
      display: flex;

      .is-result-info {
        width: 40%;
        padding-right: rem(32);

        .rw-wrap {
          background: $col_white;
          box-shadow: $box_shadow;
          border-radius: rem(8);
          padding: rem(14);
          position: relative;
          height: 100%;

          .rw-title-wr {
            @include flex-between;

            .rw-title {
              display: flex;
              align-items: center;
              h5 {
                font-weight: $fw_5;
                font-size: rem(18);
                color: $col_gray-600;
                line-height: rem(24);
              }
          
              span {
                font-weight: $fw_4;
                font-size: rem(14);
                line-height: rem(24);

                &::before {
                  content: "";
                  margin: rem(6);
                  height: rem(24);
                  width: rem(1);
                  border: solid rem(1) $col_gray;
                }
              }
            }
          }
        }
      }
      .is-result-preview {
        width: 60%;
      }
    }

    .full-table {
      margin-top: rem(32);
      position: relative;

      .is-result-info {
        width: 100%;

        .rw-wrap {
          background: $col_white;
          box-shadow: $box_shadow;
          border-radius: rem(8);
          padding: rem(14);
          position: relative;

          .review-info {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 20% 25% 32% auto;
          }

          .rw-title-wr {
            @include flex-between;

            .rw-title {
              display: flex;
              align-items: center;
              h5 {
                font-weight: $fw_5;
                font-size: rem(18);
                color: $col_gray-600;
                line-height: rem(24);
              }
          
              span {
                font-weight: $fw_4;
                font-size: rem(14);
                line-height: rem(24);

                &::before {
                  content: "";
                  margin: rem(6);
                  height: rem(24);
                  width: rem(1);
                  border: solid rem(1) $col_gray;
                }
              }
            }
          }
        }
      }

      .is-result-preview {
        width: 100%;
      }
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .is-body {
      display: table;

      .is-quick-select,
      .is-search-result {
        display: table-cell;
      }

      .is-quick-select {
        padding-right: rem(12) !important;
      }
    }
  }
}
