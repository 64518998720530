@import "assets/css/_base.scss";

.email-wrap {
  padding: rem(18);
  border: rem(1) solid $col_gray-200;
  border-radius: rem(8);
  margin: rem(8) 0;
  overflow-wrap: anywhere;
  overflow-x: hidden;
  background-color: $col_white;

  .email-box {
    width: 100%;

    .email-title {
      font-weight: $fw_5;
      font-size: rem(20);
      color: $col_black;
      margin-bottom: rem(24);
    }

    .email-header {
      display: flex;
      margin-bottom: rem(24);

      img {
        width: rem(48);
        height: rem(48);
        margin-right: rem(16);
      }

      .email-header-info {
        &-name {
          font-size: rem(16);
          display: flex;
          align-items: center;

          .mail-sender {
            position: relative;
          }

          img {
            width: rem(20);
            height: rem(20);
            margin: 0 0 rem(2) rem(20);
          }
        }

        &-date {
          font-size: rem(12);
          color: $col_gray;
        }

        &-to {
          font-size: rem(12);
          color: $col_gray-600;
        }
      }
    }

    .email-content-wrap {
      font-size: rem(14);

      .txt-message {
        position: relative;
        white-space: pre-wrap;
        word-break: break-word;
      }

      .email-content-header {
        margin-bottom: rem(20);
      }

      .email-contents {
        margin-bottom: rem(20);
        overflow-wrap: anywhere;
      }
    }

    .attach-item {
      position: relative;

      .attach-item-link {
        color: $col_blue;
        font-weight: $fw_5;
        font-size: rem(14);
        cursor: pointer;
        display: inline-block;
        margin: rem(6) 0;
      }

      .attach-item-img {
        object-fit: contain;
        object-position: left top;
        max-width: rem(300);
      }
    }

    .tag-thumb-wrap {
      position: absolute;
      top: rem(30);
      right: 0;
    }
  }

  .email-action {
    position: relative;

    &-hidden {
      display: none;
    }

    .input-check {
      width: rem(13);
      height: rem(13);
      margin-bottom: rem(20);
    }
  }

  .conversation-action {
    font-style: italic;
    font-size: rem(14);
    width: 100%;
    @include flex-between;

    .content {
      color: $col_blue;
    }

    .leave {
      color: $col_red-4;
    }

    .date {
      color: $col_gray;
      padding-right: rem(10);
    }
  }
}