@import "assets/css/base";
.wrap {
  table {
    width: 100%;
    height: auto;
    table-layout: fixed;

    thead,
    tbody tr {
      width: 100%;
    }

    thead {
      background: $col_gray-50;
      height: rem(48);
      border-bottom: rem(1) solid $col_gray-800;
      overflow: hidden;

      th {
        text-align: left;
        font-weight: $fw_7;
        font-size: rem(12);
        line-height: rem(16);
        color: $col_gray-400;
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: rem(8);
        }

        &:last-child {
          border-top-right-radius: rem(8);
        }

        a,
        > span:not(.no-sort) {
          padding-right: rem(14);
          position: relative;
          white-space: nowrap;
          cursor: pointer;
          color: $col_gray-400;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: rem(5);
            width: 0;
            height: 0;
            border-left: rem(4) solid transparent;
            border-right: rem(4) solid transparent;
            border-top: rem(6) solid $col_gray-600;
            clear: both;
          }
        }

        :global(span.sort-asc::after) {
          border-top: none;
          border-bottom: rem(6) solid $col_gray-600;
        }

        :global(span.sort-desc::after) {
          border-top: rem(6) solid $col_gray-600;
          border-bottom: none;
        }
        position: relative;

        .status-control {
          display: block;
          position: absolute;
          background: $col_white;
          box-shadow: $box_shadow;
          border-radius: rem(8);
          top: rem(40);
          z-index: 1;
          width: rem(170);

          .status-list {
            display: block;
            div {
              margin-top: rem(12);
              padding-left: rem(12);
              padding-right: rem(12);
            }
          }

          .btn-control {
            display: flex;
            justify-content: space-between;
            border-top: rem(0.5) solid $col_gray-700;
            padding: rem(12);
            margin-top: rem(16);
          }
        }
      }
    }

    tbody {
      tr {
        text-align: left;
        font-weight: $fw_4;
        font-size: rem(12);
        line-height: rem(16);
        height: rem(48);

        td {
          color: $col_gray-600;
          @include text-ellipsis;

          .not-trim-line {
            white-space: pre;
          }

          .item-count {
            border: rem(1) solid;
            border-color: $col_gray;
            border-radius: rem(4);
            padding: rem(4) rem(12);
            background-color: $col_gray-550;
            width: rem(92);
            height: rem(24);
            cursor: pointer;
            @include flex-center;
          }

          .view-latest {
            background-color: $col_blue;
            color: $col_white;
            border-color: $col_blue;
          }

          .preview-item {
            position: absolute;
            z-index: 9999;
            width: rem(320);
            background: $col_white;
            box-shadow: $box_shadow;
            border-radius: rem(8);
            max-height: rem(460);
            padding: rem(24);
            @include scroll-flexible;
            overflow-y: auto;
            overflow-x: hidden;
          }
        }

        &:nth-child(n + 5) {
          position: relative;
          .preview-item {
            position: absolute;
            bottom: rem(40);
          }
        }

        .statuses {
          .status {
            border: rem(1) solid;
            border-radius: rem(4);
            margin-right: rem(20);
            padding: rem(4) rem(12);
            width: rem(88);
            height: rem(24);
            @include flex-center;

            > span:first-child {
              top: rem(19) !important;
              left: rem(46) !important;
            }
          }
        }

        td.actions {
          font-weight: $fw_5;
          .inprogress {
            color: $col_orange;
            display: flex;
            align-items: center;
            > span:first-child {
              top: rem(19) !important;
              left: rem(19) !important;
            }

            .cancel {
              margin-left: rem(24);
              cursor: pointer;
            }
          }

          .action-control {
            background: $col_white;
            padding: rem(4);
            box-shadow: $box_shadow;
            position: absolute;
            width: rem(150);
            right: rem(-110);
            border-radius: rem(4);
            z-index: 9;
          }
        }
      }

      tr:hover {
        background: $color_lavender;
      }

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }

    th,
    td {
      padding-left: rem(10);

      &:first-child {
        width: 20%;
        padding-left: rem(32);
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 3%;
      }
    }
  }
  .no-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
