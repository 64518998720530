@import "assets/css/base";
.wrap {
  .loading {
    margin-right: rem(14);
  }

  .status {
    cursor: pointer;
    margin: rem(12);
    font-size: rem(14);
  }
  .disable {
    color: $col_orange;
  }

  .no-action {
    margin: rem(12);
    font-size: rem(14);
    color: $col_gray-400;
    @include text-center;
  }
}
