@import "assets/css/base.scss";

.wrap {
  :global(.modal-dialog) {
    min-width: rem(960);
    margin: 0 auto;
  }

  .modal-header {
    @include flex-between;
    border-bottom: none;
    padding-bottom: rem(16);
    position: relative;

    .title {
      color: $col_gray-600;
      font-weight: $fw_5;
      font-size: rem(24);
      line-height: rem(32);
    }

    .close-icon {
      position: absolute;
      top: rem(16);
      right: rem(16);
      cursor: pointer;

      img {
        width: rem(24);
        height: rem(24);
      }
    }
  }

  .modal-body {
    padding-top: 0 !important;
    min-height: rem(500);

    .entity-profile {
      width: 50%;
      margin-right: rem(24);
      position: relative;

      .form-error {
        width: 100%;
        color: $col_red;
        font-size: rem(12);
        position: absolute;
        top: calc(100% - rem(16));
        left: 0;
      }
    }

    .entity-statistic {
      width: 50%;

      dl {
        justify-content: flex-start;
      }
    }

    .group-info {
      display: flex;
      width: 100%;
      margin-bottom: rem(24);

      &-left {
        width: 50%;
        margin-right: rem(24);
      }

      &-right {
        width: 50%;
        z-index: 999;
        position: relative;

        .form-error {
          width: 100%;
          span {
            position: absolute;
            top: calc(100% - rem(32));
            left: rem(16);
          }
        }
      }

    }
  }

  .modal-footer {
    padding-bottom: rem(20) !important;
    @include flex-between;

    .btn-delete {
      button {
        width: 100%;
      }
    }
    
    .btn-control {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: rem(16);
      }
    }

    &>* {
      margin: 0;
    }
  }
}