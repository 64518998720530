@import "assets/css/_base.scss";

.container {
  border: rem(1) solid $col_blue;
  border-radius: rem(4);
  margin-right: rem(8);
  position: relative;

  .content {
    padding: rem(6) rem(12);
    cursor: default;

    .title {
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_gray-600;
    }
  }

  &:hover {
    .description {
      display: block;
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: rem(10);
    }

    &::before {
      bottom: 100%;
    }
  }

  .description {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: rem(360);
    max-height: rem(178);
    overflow-y: auto;
    background: rgba(34, 34, 34, 0.8);
    box-shadow: $box_shadow;
    border-radius: rem(4);
    @include scroll-flexible;

    padding: rem(16) rem(12);
    margin-top: rem(8);
    z-index: 1000;

    p {
      color: $col_white;
      font-weight: $fw_7;
      font-size: rem(14);
      line-height: rem(20);
    }
    span {
      display: block;
      width: 100%;
      margin-top: rem(12);
      color: $col_white;
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      white-space: normal;

      p {
        margin: rem(8) 0;
        font-weight: $fw_4;
      }
    }
  }
}

.preview-container {
  &:hover {
    &::after,
    &::before {
      display: none;
    }
  }

  .preview-description {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;

    .description {
      position: relative !important;
      max-width: rem(360);
    }
  }
}

tr:nth-child(n + 9) {
  .description {
    position: absolute;
    top: unset;
    bottom: 100%;
    margin-bottom: rem(8);
  }
}

.source {
  background-color: rgba($col_blue, 0.15);
  border-color: $col_blue;
}

.entity {
  background-color: rgba($col_liberty, 0.15);
  border-color: $col_liberty;
}

.datatype {
  background-color: rgba($col_blue_green, 0.15);
  border-color: $col_blue_green;
}

.date {
  background-color: rgba($col_begonia, 0.15);
  border-color: $col_begonia;
}

.concept {
  background-color: rgba($col_sunglow, 0.15);
  border-color: $col_sunglow;
}

.sematic {
  background-color: rgba($col_polished_pine, 0.15);
  border-color: $col_polished_pine;
}

.global {
  background-color: rgba($col_maya_blue, 0.15);
  border-color: $col_maya_blue;
}
