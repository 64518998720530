@import "assets/css/_base.scss";

.config-search {
  height: 100%;
  display: flex;
  flex-direction: column;
  .cs-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(16);
    .cs-buttons {
      display: flex;
      :global(.btn-common + .btn-common) {
        margin-left: rem(16);
      }
    }
  }
  .cs-body {
    border: rem(1) solid $col_gray-300;
    border-radius: rem(8);
    padding: rem(16);
    flex: 1;
    overflow-y: auto;
    @include scroll-flexible;
  }
}
