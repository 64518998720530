@import "assets/css/base";

.search-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

  img {
    cursor: pointer;
  }

  .input-autocomplete {
    position: relative;

    .btn-input {
      padding: rem(7) rem(58) rem(7) rem(10);
      height: rem(40);
      width: rem(320);
      font-size: rem(16);
      &:disabled {
        background-color: $col_gray-300;
        border-color: $col_gray-300;
        color: $col_gray;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    .input-filter-control {
      border: none;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    .no-suggestions {
      color: #999;
      padding: rem(5);
      position: absolute;

      em {
        padding: 0 rem(10);
        font-size: rem(12);
      }
    }

    .suggestions {
      list-style: none;
      max-height: rem(300);
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      position: absolute;
      background-color: $col_white;
      z-index: 999;
      font-size: rem(15);
      box-shadow: $box_shadow_icon !important;
      border-radius: rem(8);
      padding: rem(10) 0;
      margin-top: rem(10);

      .note {
        background: $bg_color;
        border: rem(1) solid $col_gray-700;
        border-radius: rem(4);
        padding: rem(12);
        margin: rem(16);

        &-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: rem(4);
          span {
            font-weight: $fw_7;
            font-size: rem(14);
            color: $col_gray-400;
          }
        }
        &-msg {
          font-weight: $fw_4;
          font-size: rem(12);
          color: $col_gray-400;
        }
      }

      @include scroll-flexible;

      li {
        padding: rem(7) rem(15);
        white-space: pre-wrap;

        .no-highlight {
          color: $col_gray-400;
        }

        .highlight {
          font-weight: bold;
        }
      }
    }

    .suggestion-active,
    .suggestions li:hover {
      background-color: $col_gray-30;
      cursor: pointer;
    }
  }

  .icon-search {
    position: absolute;
    right: rem(10);
    top: 50%;
    transform: translateY(-50%);
    width: rem(24);
    height: rem(24);
    border: none;
  }

  .icon-clear {
    position: absolute;
    right: rem(10);
    top: 50%;
    right: 12%;
    transform: translateY(-50%);
    width: rem(16);
    height: rem(16);
    border: none;
  }
}
