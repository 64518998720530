@import "assets/css/base.scss";

.toggle {
  position: relative;
  display: inline-block;
  width: rem(28);
  height: rem(16);
  box-shadow: $box_shadow_icon;
  border-radius: rem(100);
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  box-shadow: $box_shadow_icon;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $col_gray-20;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: rem(14);
  width: rem(14);
  left: rem(1);
  bottom: rem(1);
  background-color: $col_white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $col_white;
}

input:checked + .slider:before {
  background-color: $col_blue;
  -webkit-transform: translateX(rem(12));
  -ms-transform: translateX(rem(12));
  transform: translateX(rem(12));
}

/* Rounded sliders */
.slider.round {
  border-radius: rem(16);
}

.slider.round:before {
  border-radius: 50%;
}

input:disabled + .slider {
  cursor: not-allowed;
}
