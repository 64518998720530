@import "assets/css/_base.scss";

.collapse {
  font-size: rem(12);
  &-wrap {
    @include flex-between;
    padding: rem(10);
    border: rem(1) solid $col_gray-800;
    border-radius: rem(4);
    display: flex;
    gap: rem(8);
    cursor: pointer;
  }

  &-show {
    display: flex;
    gap: rem(16);
  }

  &-info {
    display: flex;
    align-items: center;
    gap: rem(8);
  }

  &-color {
    width: rem(14);
    height: rem(14);
    border-radius: rem(2);
  }

  &-data {
    margin-bottom: rem(10);
    &-item {
      padding: rem(10) rem(10) 0;
      font-weight: $fw_4;
    }
  }

  .arrow-up {
    transition: transform 0.2s ease-in-out;
  }

  .arrow-down {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }
}
