@import "assets/css/base";

.btn {
  font-size: rem(14);
  border-radius: rem(4);
  border-width: rem(1);
  background-color: $col_white;
  height: rem(28) !important;

  &::after {
    content: none;
  }
}

.cancel-button {
  position: absolute;
  right: rem(269);
}

.remove-button {
  position: absolute;
  right: rem(105);
}

.tag-button {
  width: rem(54);
  height: rem(28);
}

.btn:disabled, .disable {
  opacity: .65;
  height: rem(28);
  font-weight: $fw_5;
  margin-right: rem(16);
  font-size: rem(14) !important;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-select-tag {
  height: rem(28);
  width: rem(54) !important;
  background-color: $col_gray-700 !important;
  border: rem(1) solid $col_gray-700 !important;
  font-weight: $fw_5;
  color: $col_gray !important;
  margin-right: rem(16);
  font-size: rem(14) !important;
  border-radius: rem(4) !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8 !important;

  &::after {
    content: none !important;
  }
}

.btn-select-tag.active {
  background-color: #91d67a !important;
  opacity: 1 !important;
  border: unset !important;
  color: $col-white !important;
}

.dropdown-menu {
  width: rem(500);
  box-shadow: $box_shadow;
  border: none !important;
  padding-bottom: 0 !important;
  transform: translateY(rem(35)) !important;
  border-radius: rem(4) !important;
  z-index: 150000 !important;

  .menu-header {
    @include flex-between;
    padding: rem(10) rem(14);

    .label-title {
      font-size: rem(16);
    }
  }

  .add-hot-tag {
    font-size: rem(16);
    color: $col_blue;
    cursor: pointer;
    &.disabled { 
      pointer-events: none;
      color: $col_gray;
      user-select: none;
    }
  }

  &-tag {
    max-width: rem(360);
    max-height: rem(556);
    overflow-y: auto;
    @include scroll-flexible;
  }

  &-action {
    border-top: rem(1) solid rgba($col_gray-700, 0.5);
    display: flex;
    justify-content: flex-end;
    padding: rem(12);

    .dropdown-item {
      width: auto;
      max-width: rem(78);
      padding: 0;
    }
  }
}

:global(.dropdown-item) {
  width: auto;
  padding: 0;
  margin-right: 0 !important;
}