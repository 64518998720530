@import "assets/css/_base.scss";

.audio-wrap {
  border: rem(1) solid $col_gray-800;
  background: $col_white;
  border-radius: rem(12);
  padding: rem(8);
  width: 100%;
  max-width: rem(400);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(8);

  audio {
    width: 100%;
  }
}

.failed {
  font-size: rem(16);
  display: flex;
  flex-direction: column;

  .error-msg {
    font-style: italic;
    color: $col_red;
  }
}

.audio-wrap:empty {
  border: none;
}