@import "assets/css/base.scss";

.wrap {
  position: relative;

  .dropdown-select {
    width: 100%;
  }

  .no-result {
    @include flex-center;
    margin: rem(12) 0;
    font-weight: $fw_4;
    font-size: rem(12);
    line-height: rem(20);
    color: $col_gray-600;
  }

  .clear-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(24);
    width: rem(14);
    height: rem(14);
    cursor: pointer;
  }

  .menu-list {
    position: absolute;
    top: calc(100% + rem(8));
    left: 0;
    width: rem(224);
    padding: rem(16);
    background-color: $col_white;
    box-shadow: $box_shadow;
    border-radius: rem(6);
    z-index: 999;
    display: none;

    input {
      height: rem(30);
      font-size: rem(12);
    }

    img {
      width: rem(18);
      height: rem(18);
    }

    .add-new {
      font-weight: $fw_4;
      font-size: rem(12);
      line-height: rem(20);
      color: $col_blue;
      display: flex;
      align-items: center;
      cursor: pointer;
      
      &-icon {
        width: rem(12);
        height: rem(12);
        margin-right: rem(4);
      }
    }

    ul {
      max-height: rem(160);
      overflow-x: hidden;
      overflow-y: auto;
      @include scroll_flexible;
      margin: rem(12) 0;
      li {
        font-size: rem(12);
        line-height: rem(20);
        font-weight: $fw_4;
        color: $col_gray-600;
        margin-bottom: rem(8);
        @include text_ellipsis;
  
        &:hover {
          font-weight: $fw_7;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .active {
        font-weight: $fw_7;
      }
    }
  }

  :global(.dropdown-react-select) {
    >div {
      min-height: rem(28);
    }
  }

  :global(.react-select__control) {
    height: rem(28);
  }

  :global(.react-select__value-container) {
    font-size: rem(12);
    line-height: rem(18);
    color: $col_gray-600;
    padding: 0 rem(8) !important;
  }

  :global(.react-select__single-value) {
    color: $col_black;
  }

  :global(.react-select__indicators) {
    height: rem(26);
    margin-left: rem(4);

    svg {
      width: rem(16);
    }
  }

  :global(.react-select__dropdown-indicator) {
    padding: rem(2) rem(4);
  }
}

tr:nth-child(n + 7) {
  .menu-list {
    top: unset;
    bottom: calc(100% + rem(8));
  }
}