@import "assets/css/base";

.data-source-page {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;

  .data-source-grid {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: rem(16);
    margin: rem(16) 0;

    h3 {
      font-size: rem(32);
      font-weight: $fw_4;
      margin-right: auto;
    }

    .source-header-control {
      justify-content: flex-end; 
      display: flex;
      align-items: center;
      gap: rem(16);
      z-index: 999;
    }

    .source-header-matter-view {
      display: flex;
      justify-content: flex-end; 
      align-items: center;
    }

    .source-table {
      background-color: $col-white;
      box-shadow: $box_shadow_icon;
      border-radius: rem(8);
      padding: rem(20);
      min-width: 100%;
      height: 100%;
    }

    .source-card {
      min-width: rem(250);
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
}

@media print {
  .data-source-page {
    zoom: 95%;
  }

  @-moz-document url-prefix() {
    @page {
      size: B4 landscape !important;
    }
  }
}
