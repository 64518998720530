@import "assets/css/_base.scss";

.field-container {
  position: relative;

  &:first-child {
    label {
      margin-top: 0;
    }
  }

  label {
    margin: rem(24) 0 rem(10);
    font-size: rem(12);
    font-weight: $fw_5;
  }

  :global(.field-select__control) {
    min-height: rem(40);
    
    &:hover,
    &:focus {
      border-color: $col_dark-jeans;
      box-shadow: 0 0 rem(2) $col_blue;
      outline: unset;
      cursor: pointer;
    }
  }

  :global(.field-select__control--is-focused) {
    border-color: $col_dark-jeans;
    box-shadow: 0 0 rem(2) $col_blue;
  }

  :global(.field-select__value-container) {
    font-size: rem(16);
    padding: 0 rem(14);
  }

  .border-error {
    :global(.field-select__control) {
      border-color: $col_red;
    }
  }

  .form-error {
    position: absolute;
    top: calc(100% + rem(4));
    left: 0;
  }

  .suggestion-list {
    position: absolute;
    top: calc(100% + rem(10));
    left: 0;
    width: rem(450);
    padding: rem(16);
    background-color: $col_white;
    box-shadow: $box_shadow;
    border-radius: rem(6);
    z-index: 999;
  }
}
