@import "assets/css/base.scss";

.dropdown-container {
  position: relative;
  width: 100%;

  :global(svg) {
    width: rem(18);
    color: $col_gray-600 !important;
  }

  :global(.react-select__control--is-disabled) {
    background-color: $col_gray-50 !important;

    :global(svg) {
      opacity: 0.4;
    }
  }

  .clear-icon {
    position: absolute;
    top: rem(12);
    right: rem(32);
    cursor: pointer;
  }
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  height: auto;
  margin-top: rem(16);
  padding: rem(12);
  background-color: $col_white;
  box-shadow: 0 rem(4) rem(25) rgba(0, 0, 0, 0.12);
  border-radius: rem(8);
  z-index: 999;
  display: none;

  :global(.input-autocomplete) {
    width: 100%;

    :global(.btn-input) {
      width: 100%;
    }
  }

  :global(.icon-clear) {
    right: 10%;
  }

  .no-result {
    @include flex-center;
    margin-top: rem(12);
    font-weight: $fw_5;
    font-size: rem(14);
    line-height: rem(20);
    color: $col_gray-600;
  }

  &-list {
    margin: rem(16) 0 rem(8);
    max-height: rem(240);
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll_flexible;
  }

  .list-title {
    font-weight: $fw_7;
    font-size: rem(14);
    line-height: rem(16);
    color: $col_gray-600;
    margin: rem(12) 0;
  }

  &-multi-list {
    max-height: rem(180);
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll_flexible;

    &-item {
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(16);
      color: $col_gray-600;
      margin-bottom: rem(12);
      @include flex-between;

      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 90%;

        .tag-color {
          min-width: rem(16);
          height: rem(16);
          margin-right: rem(8);
          border-radius: rem(2);
        }

        .tag-name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5; /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        > span:not(.tag-color) {
          margin: 0 rem(2);
        }
      }

      img {
        cursor: pointer;
        margin: 0 rem(8);
      }
    }

    .highlight-item {
      background-color: $col_blue-op-10;
    }
  }
}

@media print {
  .clear-icon {
    display: none;
  }
}
