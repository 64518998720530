@import "assets/css/_base.scss";

.modal-container {
  .modal-dialog {
    min-width: rem(800);

    .modal-content {
      padding: rem(24) 0;
      border-radius: rem(8);

      .modal-header {
        border: none;
        padding: 0 rem(24) rem(16) rem(24);

        .header-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: rem(16);

          .title {
            font-size: rem(24);
            font-weight: $fw_5;
            color: $col_gray-600;
          }

          .download-btn-icon {
            font-size: rem(14);
            border-radius: rem(4);
            border-width: rem(1);
            border: solid rem(1) $col_blue;
            background-color: transparent;
          }
        }

        button.close {
          border: rem(1) solid $col_gray-600;
          opacity: 1;
          margin: 0;
          position: absolute;
          top: rem(16);
          right: rem(16);
        }
      }

      .modal-body {
        min-height: rem(150);
        max-height: rem(500);
        font-size: rem(16);
        overflow-y: auto;
        padding-top: 0;
        padding-bottom: 0;
        color: $col_gray-600;
        @include scroll-flexible;
        position: relative;

        .err {
          color: red;
        }
      }

      .modal-footer {
        border: none;
        padding: 0 rem(24);
        margin-top: rem(5);

        .loading {
          width: 100%;
          display: flex;
          position: relative !important;

          .fade-loader {
            position: absolute;
            top: 0;
            left: 0;
            margin: rem(10) rem(-6) 0 rem(10);
          }

          span {
            font-size: rem(16);
          }
        }
      }
    }
  }
}