@import "assets/css/base.scss";

.et-detail-body {
  margin-top: rem(24);
  display: flex;

  .et-detail-result {
    width: 74%;
    height: rem(780);
  }

  .et-detail-information {
    width: 26%;
    padding-right: rem(32);

    :global(.card-body) {
      height: rem(780);
      overflow-y: auto;
      @include scroll-flexible;
    }
  }
}

@media print {
  .et-detail-body {
    margin-top: 0;
  }

  @-moz-document url-prefix() {
    .et-detail-body {
      display: block !important;
      margin-top: rem(16);

      .et-detail-result {
        display: inline-block !important;
        width: 75%;
      }

      .et-detail-information {
        display: inline-block !important;
        width: 25%;
      }
    }
  }
}
