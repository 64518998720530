@import "assets/css/base.scss";

.wrap {
  .header-control {
    @include flex-between;
    h2 {
      font-weight: $fw_4;
      font-size: rem(32);
      line-height: rem(40);
    }

    .print-export {
      z-index: 999;
    }
  }

  .container {
    width: 100%;
    height: fit-content;
    background: $col-white;
    box-shadow: $box_shadow_icon;
    border-radius: rem(8);
    margin-top: rem(24);
    padding: rem(24);

    .header {
      @include flex-between;

      h3 {
        font-weight: $fw_4;
        font-size: rem(24);
        line-height: rem(32);
        color: $col_gray_600;
      }
    }

    .sub-header {
      @include flex-between;
      margin-top: rem(12);
      margin-bottom: rem(16);

      .identifiers-total {
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(24);
        color: $col_gray;
      }
    }

    .identifiers-table {
      border: rem(1) solid $col_gray-800;
      box-sizing: border-box;
      border-radius: rem(8);
      border-collapse: unset;
      border-spacing: inherit;
      position: relative;
      flex: 1;
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .wrap {
      .container {
        padding: rem(8) !important;
        margin-top: rem(8) !important;
      }

      .sub-header {
        margin: 0 !important;
      }

      .identifiers-table {
        th,
        td,
        span,
        input,
        :global(.react-select__single-value),
        :global(.react-select__placeholder) {
          font-size: rem(11.5);
        }

        th,
        td {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(6) {
            width: 11%;
          }
        }
      }
    }
  }
}
