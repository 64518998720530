@charset "UTF-8";
/* ===================================================================
	common.css
=================================================================== */

@import "./_base.scss";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 2560px,
  xxl: 3840px,
) !default;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 3.2,
) !default;

@import "node_modules/bootstrap/scss/bootstrap.scss";

html,
body {
  font-size: 10px;
}

body,
td,
th,
input,
textarea {
  color: $col_black;
  @include font_default;
  line-height: 1.5;
}

body {
  overflow-wrap: break-word;

  * {
    box-sizing: border-box;
  }
}

a,
a:link,
a:visited,
a:active,
a:hover,
a:hover img {
  color: $col_black;
  text-decoration: none;
  /*
	filter         : alpha(opacity=80);
	-ms-filter     : "alpha(opacity=80)";
	opacity        : .80;
*/
}

a:hover {
  text-decoration: none;
}

a:hover,
a:hover img,
.img_hover:hover {
  /*
	filter: alpha(opacity=80);
	-ms-filter: "alpha(opacity=80)";
	opacity: .80;
*/
}

img {
  vertical-align: middle;
  border-style: none;
}

/* clearfix (micro) */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 only */
.clearfix {
  *zoom: 1;
}

div,
p {
  /*word-break: break-all;*/
}

h2,
h3,
h4,
h5,
h6,
div,
p,
ul,
ol,
li,
dl,
dt,
dd,
img,
nav {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
}

.top-right {
  float: right;
  margin: 0 0 3rem 3rem;
}

.top-left {
  float: left;
  margin: 0 3rem 3rem 0;
}

.top-center {
  margin: 0 0 3rem 0;
  @include text-center;
}

.bottom-right {
  float: right;
  margin: 3rem 0 0 3rem;
}

.bottom-left {
  float: left;
  margin: 3rem 3rem 0 0;
}

.bottom-center {
  margin: 3rem 0 0 0;
  @include text-center;
}

.entry-container b {
  font-weight: bold;
}

.entry-container i {
  font-style: italic;
}

/* margin */
.mar_t0 {
  margin-top: 0 !important;
}

.mar_t5 {
  margin-top: 0.5rem !important;
}

.mar_t10 {
  margin-top: 1rem !important;
}

.mar_t15 {
  margin-top: 1.5rem !important;
}

.mar_t20 {
  margin-top: 2rem !important;
}

.mar_t25 {
  margin-top: 2.5rem !important;
}

.mar_t30 {
  margin-top: 3rem !important;
}

.mar_t35 {
  margin-top: 3.5rem !important;
}

.mar_t40 {
  margin-top: 4rem !important;
}

.mar_t45 {
  margin-top: 4.5rem !important;
}

.mar_t50 {
  margin-top: 5rem !important;
}

.mar_b0 {
  margin-bottom: 0 !important;
}

.mar_b5 {
  margin-bottom: 0.5rem !important;
}

.mar_b10 {
  margin-bottom: 1rem !important;
}

.mar_b15 {
  margin-bottom: 1.5rem !important;
}

.mar_b16 {
  margin-bottom: 1.6rem !important;
}

.mar_b20 {
  margin-bottom: 2rem !important;
}

.mar_b24 {
  margin-bottom: 2.4rem !important;
}

.mar_b25 {
  margin-bottom: 2.5rem !important;
}

.mar_b30 {
  margin-bottom: 3rem !important;
}

.mar_b35 {
  margin-bottom: 3.5rem !important;
}

.mar_b40 {
  margin-bottom: 4rem !important;
}

.mar_b45 {
  margin-bottom: 4.5rem !important;
}

.mar_b50 {
  margin-bottom: 5rem !important;
}

.mar_l0 {
  margin-left: 0 !important;
}

.mar_l5 {
  margin-left: 0.5rem !important;
}

.mar_l10 {
  margin-left: 1rem !important;
}

.mar_l15 {
  margin-left: 1.5rem !important;
}

.mar_l20 {
  margin-left: 2rem !important;
}

.mar_l25 {
  margin-left: 2.5rem !important;
}

.mar_l30 {
  margin-left: 3rem !important;
}

.mar_l35 {
  margin-left: 3.5rem !important;
}

.mar_l40 {
  margin-left: 4rem !important;
}

.mar_l45 {
  margin-left: 4.5rem !important;
}

.mar_l50 {
  margin-left: 5rem !important;
}

.mar_r0 {
  margin-right: 0 !important;
}

.mar_r5 {
  margin-right: 0.5rem !important;
}

.mar_r10 {
  margin-right: 1rem !important;
}

.mar_r15 {
  margin-right: 1.5rem !important;
}

.mar_r16 {
  margin-right: 1.6rem !important;
}

.mar_r20 {
  margin-right: 2rem !important;
}

.mar_r25 {
  margin-right: 2.5rem !important;
}

.mar_r30 {
  margin-right: 3rem !important;
}

.mar_r35 {
  margin-right: 3.5rem !important;
}

.mar_r40 {
  margin-right: 4rem !important;
}

.mar_r45 {
  margin-right: 4.5rem !important;
}

.mar_r50 {
  margin-right: 5rem !important;
}

/* float */
.fl_l {
  float: left !important;
}

.fl_r {
  float: right !important;
}

.fl_n {
  float: none !important;
}

/* font color */
.f_c_red {
  color: #ff0000 !important;
}

.f_c_yellow {
  color: #ffff00 !important;
}

.f_c_blue {
  color: #0000ff !important;
}

.f_c_gray {
  color: #cccccc !important;
}

.f_c_gray_2 {
  color: #999999 !important;
}

.t_align_l {
  text-align: left !important;
}

.t_align_c {
  text-align: center !important;
}

.t_align_r {
  text-align: right !important;
}

.f_weight_bold {
  font-weight: bold !important;
}

.f_weight_normal {
  font-weight: normal !important;
}

/*---------------------------------------------------------------------------*/
a {
  text-decoration: none;
}

a:hover {
  outline: none;
}

a:focus {
  outline: none;
}

.btn_alpha {
  transition: all 0.45s ease;
  transform: translate3d(0, 0, 0);
  display: inline-block;
}

.btn_alpha:hover {
  opacity: 0.8 !important;
  /* For IE8 and earlier */
}

.row {
  zoom: 1;
  position: relative;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

figure {
  margin: 0;
  line-height: initial;
}

table {
  border-collapse: collapse;
}

.hide-element {
  display: none !important;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  pointer-events: unset;
  cursor: not-allowed;
  opacity: 1;
}

.asterisk {
  color: #d90000;
}

.input-error {
  border-color: #d90000 !important;
}

@import "_common_pc";

@import "_animate";
