@import "assets/css/base";

.tag-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: rem(48);
  background-color: $col_white;
  border: rem(1) solid $col_gray-300;
  border-radius: rem(6);
  padding: rem(12) rem(16);
  margin-bottom: rem(16);
  user-select: none;

  &:hover {
    background-color: $col_gray-100;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-info {
    width: 70%;
    display: flex;
    align-items: center;
    label {
      cursor: pointer;
    }
    label.tag-name {
      font-weight: $fw_4;
      font-size: rem(16);
      line-height: rem(24);
      color: $col_gray-600;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-color {
      width: rem(24);
      height: rem(24);
      border-radius: rem(2);
      margin-right: rem(16);
      margin-left: rem(8);
    }
  }

  &-action {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    .tag-type {
      font-size: rem(12);
      display: flex;
      align-items: center;
      border: rem(1) solid $col_blue;
      border-radius: rem(4);
      padding: rem(6) rem(12);
      color: $col_gray-600;
      background-color: $col_blue-15;
      &-global {
        border-color: $col_sunglow;
        background-color: $col_sunglow-15;
      }
    }
    img {
      margin-left: rem(16);
      cursor: pointer;
      &.tag-drap-icon {
        cursor: move;
      }
    }
  }
  .tag-edit-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
