@import "assets/css/base";

.container {
  &-actions {
    display: flex;

    .dropbox-analytics {
      width: rem(350) !important;
    }

    &-right {
      display: flex;
      margin-left: rem(16);
      align-items: center;

      .calendar-icon {
        margin-right: rem(20);
        cursor: pointer;

        img {
          width: 145%;
          height: 145%;
          filter: brightness(40%);
        }
      }
    }
  }

  &-print {
    margin-left: rem(12);
  }

  &-header {
    margin-top: rem(16);
    @include flex-between;
  }

  &-heading {
    font-size: rem(32);
    line-height: rem(40);
    margin-right: rem(24);
  }

  &-body {
    margin-top: rem(24);
  }

  &-quick-select {
    margin-right: rem(32);
    min-width: rem(320);
  }
}

@media print {
  .container-body {
    margin-top: 0 !important;
  }
  @-moz-document url-prefix() {
    .container-body {
      margin-top: 0 !important;
    }
  }
}
