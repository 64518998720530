@import "assets/css/_base.scss";

.last-activity {
  background: $col_white;
  border-radius: rem(8);
  padding: rem(16);
  margin-bottom: rem(16);
  color: $col_black;
  box-shadow: $box_shadow_icon;
  position: relative;
  min-height: rem(152);

  &__ttl {
    display: flex;
    align-items: center;

    figure {
      position: relative;
      height: rem(24);
      margin-right: rem(8);

      img {
        width: 100%;
        height: 100%;
      }
    }

    span {
      font-size: rem(16);
      @include text-ellipsis;
    }
  }

  &__content-title {
    padding: rem(15) 0 rem(1) 0;
    font-weight: $fw_7;
    font-size: rem(12);
  }

  &__post-time {
    padding-top: rem(2);
    color: $col_gray;
    text-align: right;
    font-size: rem(12);
    margin: 0;
    position: absolute;
    right: rem(16);
    bottom: rem(16);
  }

  &__content-detail {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    .link {
      color: $col_blue;
      font-style: italic;
    }
  }
}
.last-activity__content-detail {
  font-size: rem(14);
}
