@import "assets/css/base";

.wrap {
  border: rem(1) solid $col_gray-700;
  background-color: $col_gray-10;
  border-radius: rem(4);
  padding: rem(4) rem(12);
  min-width: rem(90);
  width: fit-content;
  height: rem(24);
  @include flex-center;
  position: relative;

  span {
    color: $col_gray-600;
    font-size: rem(12);
    font-weight: $fw_4;
  }

  .loading {
    position: absolute !important;
    top: 80% !important;
    left: 100% !important;
    margin-left:rem(32);
  }
}