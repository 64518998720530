@import "assets/css/_base.scss";

.config-page {
  .config-header {
    @include flex-between;

    h2 {
      margin: rem(32) 0 rem(20);
      font-size: rem(32);
      color: $col_gray-600;
    }
  }

  .config-body {
    width: 76%;
    height: fit-content;
    box-shadow: $box_shadow;
    padding: rem(36);
    border-radius: rem(8);

    hr {
      flex: 1;
      margin: rem(20) 0;
    }
  }
}
