@import "assets/css/base";

.contact-name {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info {
    display: none;
    position: absolute;
    right: 0;
    top: rem(50);
    padding: rem(15);
    box-shadow: $box_shadow;
    border-radius: rem(4);
    font-size: rem(16);
    min-width: rem(200);
    max-width: calc(100% - rem(20));
    background-color: $col-white;
    &::before {
      content: " ";
      height: rem(8);
      width: 100%;
      position: absolute;
      bottom: 100%;
      right: 0;
    }

    p {
      display: flex;
      align-items: center;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      img {
        padding-right: rem(8);
        height: rem(20);
        width: rem(26);
      }
    }
  }

  img:hover + .info, .info:hover {
    display: block;
  }
}
