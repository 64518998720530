@import "assets/css/base.scss";

.wrap {
  font-weight: $fw_4;
  font-size: rem(12);
  line-height: rem(16);
  color: $col_gray-600;
  margin-bottom: rem(12);

  &:last-child {
    margin-bottom: 0;
  }

  .filter-label {
    @include flex-between;
    padding: rem(8) rem(12);
    border: rem(1) solid $col_gray-800;
    border-radius: rem(4);
    background-color: $col_white;
    margin-bottom: rem(10);
    cursor: pointer;
    user-select: none;

    &-left {
      display: flex;
      align-items: center;

      span {
        font-weight: $fw_5;
        font-size: rem(14);
      }
    }

    &-right {
      display: flex;
      align-items: center;

      .total-value {
        color: $col_black;
      }

      .arrow-icon {
        width: rem(10);
        height: rem(10);
        margin-left: rem(12);
      }
    }
  }

  .filter-value {
    padding: 0 rem(12);

    &-item {
      @include flex-between;
      margin-bottom: rem(10);

      &:last-child {
        margin-bottom: 0;
      }

      .source {
        display: flex;
        align-items: center;

        &-icon {
          width: rem(16);
          height: rem(16);
          margin-right: rem(8);
        }
      }
    }
  }
}