@import "assets/css/base.scss";

.print-export {
  position: relative;
  cursor: pointer;
  @include flex-between;
  gap: rem(12);

  img {
    width: rem(40);
    height: rem(40);
  }

  .print,
  .export {
    position: relative;
    p {
      display: none;
      color: $col-white;
      background-color: rgba(34, 34, 34, 0.8);
      box-shadow: $box_shadow;
      padding: rem(16);
      width: fit-content;
      position: absolute;
      top: calc(100% + rem(8));
      right: 0;
      font-size: rem(16);
      border-radius: rem(4);
      white-space: nowrap;
      z-index: 99;
    }

    &:hover p {
      display: block;
    }
  }

  .menu-option {
    padding: rem(10);
    position: absolute;
    background-color: $col-white;
    top: calc(100% + rem(8));
    right: 0;
    border-radius: rem(4);
    border: rem(1) solid $col_gray-50;
    box-shadow: $box_shadow;
    min-height: rem(48);
    min-width: rem(130);
    width: fit-content;
    z-index: 999;

    li {
      padding: rem(6);
      margin: 0;
      font-size: rem(16);
      line-height: rem(24);
      color: $col_gray_600;
      white-space: nowrap;

      &:hover {
        background: $col_gray-800;
      }
    }
  }
}

@media print {
  .print-export p {
    visibility: hidden;
    opacity: 0;
  }
}
