@import "assets/css/base";

.paging_control {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-showing {
    font-weight: $fw_4;
    font-size: rem(12);
    color: #a1a8b2;
    margin-top: rem(20);
  }

  &-pagination {
    @include flex-center;

    .search-paging {
      margin-top: rem(20);
      position: relative;
      display: flex;
      align-items: center;
      span {
        font-weight: $fw_4;
        font-size: rem(12);
        padding: 0 rem(16);
      }
      form {
        input {
          border: rem(1) solid $col_gray-700;
          border-radius: rem(6);
          padding: rem(5);
          padding-right: rem(28);
          font-size: rem(12);
          line-height: rem(12);
          width: rem(65);
          outline: unset;
          height: rem(28);

          &:hover,
          &:focus {
            border-color: $col_dark-jeans;
            box-shadow: 0 0 rem(4) $col_blue;
            outline: unset;
          }

          &.input-wide {
            width: rem(132);
            padding-right: rem(28);
          }
        }
      }
      img {
        cursor: pointer;
        position: absolute;
        top: rem(3);
        right: rem(5);
      }

      .react-select-custom {
        @include flex-between;
        margin-right: rem(10);
        font-size: rem(12);
        z-index: 1000;
        padding-left: rem(5);

        span {
          padding: 0px 0.5px !important;
        }
        
        .css-tlfecz-indicatorContainer {
          padding: 5px !important;
        }
      
        .react-select__control {
          width: 11rem !important;
          min-height: 25px !important;
          padding: 0 !important;
        }
        
        .react-select__value-container {
          padding: 0 8px !important;
          min-height: 25px !important;
        }
        
        .react-select__indicators {
          height: 25px !important;
        }
      }
    }

    .pagination {
      margin: rem(15) auto 0;
      display: flex;
      list-style: none;
      outline: none;
      justify-content: flex-end;
    }

    .pagination > .active > a {
      background-color: $col_blue;
      color: $col_white;
      border: rem(1) solid $col_blue;
    }

    .pagination > li {
      padding: rem(4) rem(4) 0;

      &.previous > a {
        background: url("~/public/images/ic-prev.svg") center no-repeat;
        text-indent: -999px;
        border-color: $col_blue;
      }

      &.next > a {
        background: url("~/public/images/ic-next.svg") center no-repeat;
        text-indent: -999px;
        border-color: $col_blue;
      }

      &.disabled {
        &.previous > a {
          background: url("~/public/images/ic-prev-disabled.svg") center
            no-repeat;
          text-indent: -999px;
          border-color: $col_gray-700;
          cursor: not-allowed;
        }
        &.next > a {
          background: url("~/public/images/ic-next-disabled.svg") center
            no-repeat;
          text-indent: -999px;
          border-color: $col_gray-700;
          cursor: not-allowed;
        }
      }
    }

    .pagination > li > a {
      padding: rem(6) rem(2);
      outline: none;
      cursor: pointer;
      color: #0f0202;
      text-decoration: none;
      @include text-center;
      overflow: hidden;
      display: inline-block;
      height: rem(32);
      min-width: rem(32);
      vertical-align: top;
      color: $col_gray-700;
    }

    .pagination > li > a,
    .pagination > li > span {
      border: rem(1) solid $col_gray-700;
      border-radius: rem(6);
      font-size: rem(12);
    }

    .pagination-small > li > a {
      padding: rem(2);
      height: rem(24);
      width: auto;
      min-width: rem(24);
    }
  }
}
