@import "assets/css/base";

.btn-container {
  width: fit-content;
}
.btn-container {
  button {
    border-radius: rem(4);
    width: auto;
    background: $col-white;
    display: flex;
    outline: unset;
    align-items: center;
    font-size: rem(16);
    color: $col_blue;
    height: rem(40);
    padding: 0 rem(12);
    span {
      line-height: 100%;
    }
    img {
      margin-right: rem(8);
      &.btn-no-name {
        margin: 0 auto;
      }
    }
    &.btn-primary,
    &.btn-primary-fill {
      padding: rem(6) rem(16);
      img {
        margin-right: rem(10);
      }
    }
    &:disabled {
      background-color: $col_gray-300;
      border-color: $col_gray-300;
      color: $col_gray;
      cursor: not-allowed;

      &:active {
        opacity: 1;
      }
    }
    &:active {
      opacity: 0.8;
    }
    &.btn-warning-md {
      background-color: $col_orange;
      width: rem(84);
      height: rem(32);
      color: $col-white;
      font-size: rem(14);
      @include flex-center;
    }
    &.btn-warning-lg {
      background-color: $col_orange;
      width: rem(67);
      height: rem(40);
      color: $col-white;
      font-size: rem(16);
      @include flex-center;

      &:disabled {
        background-color: $col_gray-50;
        border-color: $col_gray-50;
        color: $col_gray;
      }
    }

    &.btn-primary-fill {
      background-color: $col_blue;
      color: $col_white;
      &:disabled {
        background-color: $col_gray-300;
        border-color: $col_gray-300;
        color: $col_gray;
      }
    }
    &.btn-secondary {
      padding: rem(6) rem(16);
      height: unset;
      font-size: rem(12);
      background-color: $col_blue;
      color: $col-white;
      border: rem(1) solid $col_blue;
      &.btn-border {
        background-color: $col-white;
        color: $col_blue;
        border: rem(1) solid $col_blue;
      }
      &:disabled {
        background-color: $col_gray-50;
        border-color: $col_gray-50;
        color: $col_gray;
      }
    }
    &.btn-secondary-md {
      min-width: rem(78);
      max-width: rem(84);
      height: rem(32);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: rem(14);
      background-color: $col_blue-10;
      color: $col-white;
      border: none;
      &:disabled {
        background-color: $col_gray-300;
        border-color: $col_gray-300;
        color: $col_gray;
      }
      &.btn-border {
        background-color: $col-white;
        color: $col_blue;
        border: rem(1) solid $col_blue;
      }
    }

    &.btn-icon {
      width: rem(74);
      border-radius: rem(6);
      margin-bottom: rem(24);

      &:disabled {
        background-color: $col_white;
        border-color: $col_blue;
        color: $col_blue;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &.btn-gray {
      background-color: rgba($col_gray, 0.15);
      border: rem(1) solid $col_gray;
      height: rem(32);
      span {
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(20);
        color: $col_gray-600;
      }
    }
    &.btn-medium {
      height: rem(32);
      span {
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(20);
      }
    }
    &.btn-medium-dashed {
      height: rem(32);
      border-style: dashed;
      span {
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(20);
      }
    }
    &.btn-medium-blue {
      height: rem(32);
      background-color: $col_blue;
      color: $col_white;

      span {
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(20);
      }

      &:disabled {
        background-color: $col_gray-300;
        border-color: $col_gray-300;
        color: $col_gray;
        cursor: not-allowed;
      }
    }
    &.btn-small {
      height: rem(28);
      span {
        font-weight: $fw_5;
        font-size: rem(12);
        line-height: rem(16);
        white-space: nowrap;
      }
    }

    &.btn-small-blue {
      height: rem(28);
      padding: 0 rem(16);
      border: none;
      background-color: $col_blue;

      span {
        color: $col_white;
        font-weight: $fw_5;
        font-size: rem(12);
        line-height: rem(16);
        white-space: nowrap;
      }

      &:disabled {
        background-color: $col_gray-300;
        border-color: $col_gray-300;
        color: $col_gray;
        cursor: not-allowed;
      }
    }

    &.btn-prev-icon,
    &.btn-next-icon {
      width: rem(24);
      height: rem(24);
      padding: 0;
    }

    &.btn-gray-noborder {
      color: $col_gray-600;
      font-weight: $fw_5;
    }
  }

  &-transparent {
    button {
      &:disabled {
        background: transparent;
      }
    }
  }
  &-full-width {
    width: 100%;
    button {
      width: 100%;
      justify-content: center;
    }
  }
}
.btn-display {
  display: flex;
}

.btn-border {
  border: rem(1) solid $col_blue;
}

.btn-border-none {
  border: unset;
}

.btn-add-date-filter {
  display: flex !important;
  background-color: $col_blue !important;
  height: rem(30) !important;
  color: $col_white !important;
  font-size: rem(12) !important;
  font-weight: $fw_4;
}
