@import "assets/css/base";

.PII-Popup {
  position: absolute;
  width: rem(280);
  height: auto;
  padding: rem(16);
  background: $col_white;
  box-shadow: $box_shadow_icon;
  top: calc(100% + rem(16));
  right: rem(-120);
  z-index: 9;

  &-header {
    @include flex-between;
    font-size: rem(14);
    font-weight: $fw_7;
  }

  &-description {
    color: $col_gray;
    font-weight: $fw_4;
    font-size: rem(11);
    margin-top: rem(8);
    margin-bottom: rem(16);
  }

  .close-btn {
    cursor: pointer;
    width: remm(16);
    height: remm(16);
  }
}
