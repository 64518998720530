@import "assets/css/base.scss";

.wrap {
  :global(.modal-dialog) {
    max-width: rem(550);
    margin: 0 auto;
  }

  .modal-header {
    @include flex-between;
    border-bottom: none;
    padding-bottom: rem(16);
    position: relative;
    justify-content: left;

    .note-tooltip-group {
        display: relative;
        margin-left: rem(20);
    
        span {
          position: absolute;
          top: calc(100% - rem(30));
          left: rem(240);
          animation-delay: 0s !important;
          display: none;
          background: $col_gray-750;
          box-shadow: $box_shadow;
          border-radius: rem(4);
          width: rem(253);
          height: rem(92);
          padding: rem(16);
          z-index: 99;
          color: $col_white;
          font-weight: $fw_4;
          font-size: rem(14);
        }
    
        .tooltip-description {
          display: none;
          z-index: 9999;
        }
    
        &:hover > .tooltip-description {
          display: block;
        }
      }

    .title {
      color: $col_gray-600;
      font-weight: $fw_5;
      font-size: rem(24);
      line-height: rem(32);
    }

    .close-icon {
      position: absolute;
      top: rem(16);
      right: rem(16);
      cursor: pointer;

      img {
        width: rem(24);
        height: rem(24);
      }
    }
  }

  .modal-body {
    padding-top: 0 !important;
    min-height: rem(525);
    width: rem(450);

    .sb-input {
      flex: 1;
      height: rem(490);
      width: rem(500);
      border-radius: rem(4);
      border: rem(1) solid $col_gray-300;
      padding: rem(10);
      font-size: rem(16);
      resize: none;
      overflow-y: auto;
      white-space: nowrap;
      overflow-x: auto; 
    
      &:focus {
        border-color: $col_blue;
        outline: none;
      }
    }
  }

  .modal-footer {
    padding-bottom: rem(20) !important;
    @include flex-between;
    justify-content: right;
    
    .btn-control {
      display: flex;

      button {
        margin-left: rem(16);
      }
    }

    &>* {
      margin: 0;
    }
  }
}