@import "assets/css/base";

.dropdown-menu {
  &-select {
    padding: 0 rem(16) rem(16);
    max-height: rem(439);
    overflow-y: auto;
    @include scroll-flexible;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: rem(4);

      &:last-child {
        margin-bottom: 0;
      }
    }

    span {
      width: rem(16);
      height: rem(16);
      display: block;
      border-radius: rem(2);
      margin-right: rem(10);
    }

    p {
      flex: 1;
      font-size: rem(14);
      padding-right: rem(16);
      @include text-ellipsis;
    }

    .form-check-input {
      width: rem(13);
      height: rem(13);
      cursor: pointer;
    }
  }
}
