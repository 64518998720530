@import "assets/css/_base.scss";

.container {
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: rem(18);
      font-weight: $fw_5;
      color: $col_gray-600;
    }
  }

  .consolidated-list {
    margin-top: rem(16);
    border: rem(1) solid $col_gray-300;
    border-radius: rem(8);
    overflow-y: auto;
    @include scroll-flexible;

    .consolidated-list-content {
      margin: 0 rem(16);
    }
  }
}
