@import "assets/css/_base.scss";

.search-terms-page {
  margin-bottom: 0 !important;
}

.search-dashboard {
  &__header {
    @include flex-between;
  }

  &__header-title {
    font-size: rem(32);
    line-height: rem(40);
    margin-right: rem(24);
    margin-top: rem(16);
  }

  &__header-control {
    display: flex;
    margin-top: rem(16);

    &__button2 {
      margin-right: 1.5rem;
    }
  }

  .dropbox-change-projects {
    width: rem(352) !important;
  }
}

.search-terms-container {
  @include flex-between;
  align-items: flex-start;
  gap: rem(35);
  height: rem(1000);
}

.search-terms {
  height: inherit;
}

.search-terms-scope-filter {
  height: inherit;
  margin-top: rem(20);
  min-width: rem(300) !important;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.08);
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}