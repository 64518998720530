@import "assets/css/_base.scss";

.intelligent-search {
  min-height: unset !important;
  height: calc(100vh - rem(96));
  margin-bottom: 0 !important;
  .is-header {
    display: flex;
    justify-content: space-between;
    .is-buttons {
      display: flex;
      :global(.btn-common + .btn-common) {
        margin-left: rem(16);
      }
    }
  }
  .is-content {
    padding: rem(24) 0;
    height: calc(100% - rem(64));
    margin: 0;
    .is-content__col {
      height: 100%;
    }
    .is-content__col {
      padding: 0 0 rem(12) rem(32);
      .is-col {
        height: 100%;
        padding: rem(16);
        box-shadow: $box_shadow;
        border-radius: rem(8);
      }
    }
    .is-content__col-query {
      height: 100%;
      padding: 0;
      .is-col-query {
        height: 100%;
        padding-bottom: rem(12);
      }
    }
  }
}
