@import "assets/css/_base.scss";

.login {
  display: flex;
  padding: vw(54) vw(66);
  height: 100vh;
  overflow: hidden;

  &-container {
    width: vw(512);
    height: 100%;
    margin: 0 vw(60);

    .login-form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .logo {
      width: vw(100);
      margin: 0 0 vw(66);
      position: absolute;
      top: 0;

      button {
        background-color: $col_white;
        border: none;
      }

      a {
        display: flex;
      }

      img {
        width: vw(333);
        height: vw(80);
      }
    }

    .login-ttl {
      font-weight: $fw_5;
      font-size: vw(32);
      line-height: vw(48);
      color: $col_black;
    }

    .login-txt {
      color: $col_gray;
      font-size: vw(14);
      line-height: vw(20);
      margin: vw(16) 0 vw(32);
    }

    .btn-login {
      height: vw(48);
      font-size: vw(16);
      @include hov;
    }

    .btn_alpha {
      display: flex;
      align-items: center;
    }
  }

  &-background {
    flex: 1;
    height: 100%;
    position: relative;
    border-radius: vw(8);

    figure {
      height: 100%;

      img {
        width: 100%;
        height: inherit;
      }

      video {
        width: 100%;
        height: inherit;
        object-fit: cover;
        object-position: center;
        border-radius: vw(8);
      }
    }
  }
}

.copy-right {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: vw(16);
  width: 100%;
  color: $col_gray-200;
  position: absolute;
  bottom: 0;
}

.terms-privacy {
  display: flex;
  justify-content: space-between;
  
  a {
    font-weight: $fw_4;
    font-size: vw(14);
    color: $col_blue !important;
  }
}
