@import "assets/css/_base.scss";

.wrap {
  h3 {
    font-weight: $fw_5;
    font-size: rem(18);
    line-height: rem(24);
    color: $col_gray-600;
    margin-bottom: rem(16);
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: rem(16);
  }
}