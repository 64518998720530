@import "assets/css/base";

.preview-control-container {
    position: relative;
}

.preview-control-search {
    display: flex;
    align-items: center;

    input {
        height: rem(28) !important;
        font-size: rem(14) !important;
    } 
}