@import "../../assets/css/base";

.heatmap-content {
  border: rem(1) solid $col_gray-500;
  border-radius: rem(8);
  padding-top: rem(100);
  height: calc(100% - rem(38));

  .heatmap-content-articles {
    display: flex;
  }

  .heatmap-empty {
    height: rem(650);
  }

  .heatmap-matrix {
    padding: 0 0 rem(16) rem(16);
    margin-left: rem(1);
  }

  table {
    margin-right: rem(100);
    font-size: rem(12);

    tbody {
      .header-table {
        .heatmap-col {
          position: relative;
          @include text-center;

          &__title {
            position: absolute;
            transform-origin: left;
            transform: rotate(313deg);
            text-align: left;
            white-space: nowrap;
            left: rem(10);
            bottom: rem(16);
            width: rem(100);
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .heatmap-col::after {
          content: "|";
        }
      }

      tr {
        &:first-child td {
          height: rem(32);
        }

        td {
          min-width: vw(24);
          height: rem(32);
          border: rem(1) solid $col_white;
          text-align: right;
          background-repeat: no-repeat !important;
          background-size: 100% 100% !important;
          border-collapse: collapse;

          &:first-child {
            width: rem(100);
            overflow: hidden;
            display: inline-block;
            padding: 0 0 0 rem(5);
            text-align: left;
            line-height: vw(32);
          }
        }

        .heatmap-row-title {
          padding-right: rem(16);
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .progress-bar-heatmap {
    padding: rem(18) 0 0 0;
  }
}

.heatmap-empty-data {
  @include text-center;
  height: rem(550);

  .heatmap-empty-title {
    font-size: rem(24);
    color: $col_gray-400;
  }
}

@media print {
  html,
  body {
    height: 100%;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
  }
  .heatmap-content {
    .heatmap-matrix {
      padding-left: 0 !important;
    }

    table {
      margin-right: rem(10);
      font-size: rem(10);

      tbody {
        tr {
          .heatmap-row-title {
            padding-right: rem(5) !important;
          }

          td {
            border: rem(2) solid $col_white;
            height: rem(23);

            &:first-child {
              width: rem(100);
            }
          }
        }
      }
    }

    .progress-bar-heatmap {
      margin-right: 0;
      margin-left: auto;
      padding-right: rem(10);
    }
  }
}
