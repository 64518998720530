@import "assets/css/_base.scss";

.wrap {
    display: flex;
    flex-direction: column;

    h3 {
        font-weight: $fw_5;
        font-size: rem(18);
        line-height: rem(24);
        color: $col_gray-600;
        margin-bottom: rem(16);
    }

    .form-group {
        width: 100%;
        margin-right: rem(24);

        &:last-child {
            margin-right: 0;
        }

        label {
            margin-bottom: rem(10);
            margin-top: rem(10);
        }

        .form-required {
            color: $col_red;
        }
    }

    :global(.btn-common) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: rem(16);
    }

    .beat-loader {
        width: 100%;
        height: rem(40);
        display: flex !important;
        justify-content: flex-end;
        align-items: center;
        margin-top: rem(16);
    }
}