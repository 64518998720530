@import "assets/css/base.scss";

.back-btn {
  margin-top: rem(7);
}

.et-detail-header {
  margin-top: rem(8);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media print {
  :global(.main) {
    margin-bottom: rem(8) !important;
  }

  .et-detail-header {
    margin-top: 0;
    margin-bottom: rem(5);
    overflow: hidden;
  }

  @-moz-document url-prefix() {
    .et-detail-header {
      display: block !important;
      h2,
      div {
        display: inline-block !important;
      }
      div {
        float: right !important;
      }
    }
  }
}
