@import "assets/css/_base.scss";

.field-container {
  position: relative;
  &:first-child {
    label {
      margin-top: 0;
    }
  }
  
  label {
    margin: rem(24) 0 rem(10);
    font-size: rem(12);
    font-weight: $fw_5;
  }

  .field-upload {
    display: flex;

    input {
      width: 100%;
      height: rem(40);
      border: rem(1) solid $col_gray-850;
      border-radius: rem(4);
      padding: 0 rem(16);
      font-size: rem(16);
      font-weight: $fw_4;
      color: $col_black_pearl;
      margin-right: rem(16);
      @include text-ellipsis;
      pointer-events: none;
  
      &:read-only {
        box-shadow: unset;
        outline: unset;
      }
    }
  }


  .form-error {
    position: absolute;
    top: calc(100% + rem(4));
    left: 0;
  }
}
