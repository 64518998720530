@import "assets/css/base";

.dropdown {
  background: $col-white;
  width: fit-content;
  position: relative;
  &.lg {
    background: transparent;
    button {
      background: transparent;
      font-size: rem(18);
      width: rem(170);
      font-weight: $fw_5;
    }
    .dropdown-selectors {
      li {
        font-size: rem(16);
        &:hover,
        &.selected {
          font-weight: $fw_7;
          background-color: transparent;
          color: $col_black;
        }
      }
    }
  }
  &.sm {
    background: transparent;
    button {
      background: transparent;
      font-size: rem(12);
      width: rem(80);
      color: $col_blue;
      padding: rem(8);
      img {
        margin-right: 0;
      }
      &.btn-border {
        border: rem(1) solid $col_blue;
      }
    }
    .dropdown-selectors {
      width: rem(90);
      li {
        font-size: rem(12);
        &:hover,
        &.selected {
          font-weight: $fw_7;
          background-color: transparent;
          color: $col_black;
        }
      }
    }
  }
}
.dropdown button {
  border-radius: rem(4);
  border: rem(1) solid $col_gray-300;
  width: rem(130);
  background: $col-white;
  display: flex;
  outline: unset;
  align-items: center;
  font-size: rem(16);
  color: $col_gray-600;
  height: rem(28);
  font-size: rem(12);
  img {
    position: absolute;
    right: rem(12);
  }
  &:hover {
    border-color: $col_gray-600;
  }

  span {
    display: inline-block;
    width: calc(100% - rem(20));
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: left;
  }
  &.btn-border {
    border: rem(1) solid $col_gray-800;
  }

  &.btn-border-none {
    border: none;
  }
}

.dropdown-selectors {
  padding: rem(10) 0;
  position: absolute;
  border: rem(1) solid $col_gray-50;
  background-color: $col_white;
  z-index: 99;
  width: rem(234);
  max-height: rem(172);
  overflow-y: auto;
  right: 0;
  border-radius: rem(4);
  top: 100%;
  margin-top: rem(4);
  box-shadow: $box_shadow;
  font-size: rem(14);
  @include scroll-flexible;
  li {
    padding: rem(6) rem(12);
    cursor: pointer;
    &:hover,
    &.selected {
      font-weight: $fw_5;
      color: $col_blue;
      background-color: $col_blue-3;
    }
  }
}

.dropdown-auto {
  width: 100%;
  button {
    width: 100%;
    height: rem(48);
    border-radius: rem(6);
    border: rem(1) solid $col_gray-300;
    padding: rem(12) rem(16);

    span {
      font-weight: $fw_4;
      font-size: rem(16);
      line-height: rem(24);
      color: $col_gray_600;
    }

    img {
      width: rem(10);
      right: rem(20);
    }
  }

  .dropdown-selectors {
    width: 100%;
    padding: 0;
    max-height: rem(200);

    li {
      padding: rem(8) rem(16);
      font-size: rem(16);
    }
  }
}

.disabled {
  cursor: not-allowed;
    background-color: $col_gray-300;
    border-color: $col_gray-300;
    color: $col_gray;
    opacity: 0.7;
}
