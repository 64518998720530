@import "assets/css/_base.scss";

.query-types {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include scroll-flexible;
  .query-type {
    padding: rem(16);
    box-shadow: $box_shadow;
    border-radius: rem(8);
    height: calc(100% / 3 - rem(16));
  }
}
