@import "assets/css/_base.scss";

.export-detail-form {
  height: 100%;
  position: relative;

  h3 {
    font-weight: $fw_5;
    font-size: rem(20);
    line-height: rem(32);
    color: $col_gray-600;
    margin-bottom: rem(24);
  }

  .app-form-required {
    color: $col_red;
  }

  :global(.app-form-group) {
    position: relative;

    :global(.form-label) {
      margin-bottom: rem(6);
    }

    .form-error {
      position: absolute;
      top: calc(100% - rem(2));
    }

    .error {
      :global(.react-select__control) {
        border: rem(1) solid $col_red;
      }
    }
  }

  .export-title-header {
    @include flex-between;
    margin-bottom: rem(24);

    h4 {
      font-weight: $fw_5;
      font-size: rem(16);
      line-height: rem(24);
      color: $col_gray-600;
      margin-right: rem(16);
    }

    .header-line {
      flex: 1;
      border: rem(1) solid $col_gray-700;
      opacity: 0.5;
    }
  }

  .export-type {
    display: flex;
    width: 100%;
    margin-bottom: rem(10);

    &-form {
      width: 50%;

      &:first-child {
        padding-right: 9%;
      }
    }

    &-toggle {
      top: rem(2);
      margin-right: rem(16);
    }

    .select-container {
      flex: 1;
    }

    .type-form-group {
      @include flex-between;
      margin-bottom: rem(24);

      :global(.form-label) {
        margin-bottom: 0;
      }

      .schedule-export {
        display: flex;
        flex: 1;
      }

      .type-form-label {
        font-weight: $fw_4;
        font-size: rem(16);
        line-height: rem(24);
        color: $col_gray_600;
        white-space: nowrap;
        padding: rem(8) 0;
        width: 36%;
        min-width: rem(150);
        cursor: pointer;
      }

      .type-form-control {
        width: 100%;
        font-weight: $fw_4;
        font-size: rem(16);
        line-height: rem(24);
        color: $col_gray_600;
        padding: 0 rem(12);
        pointer-events: none;
      }

      .calender-icon {
        width: rem(40);
        margin-left: rem(16);
        cursor: pointer;

        &-disabled {
          pointer-events: none;
        }
      }
    }
  }

  .form-disabled {
    opacity: 0.5;
  }

  .text-disabled {
    cursor: not-allowed !important;
  }

  :global(.form-control:disabled) {
    background-color: $col_gray-50;
    color: rgba($col_black, 0.6) !important;
  }

  .time-format-setting {
    display: flex;
    width: 100%;
    margin-right: rem(30);
  }

  .export-detail-footer {
    @include flex-between;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;

    .save-button-group {
      display: flex;
    }

    :global(.btn-common:not(:first-child)) {
      margin-left: rem(16);
    }
  }
}

@media print {
  .export-detail-form {
    h3 {
      font-size: rem(18);
    }
    .export-title-header {
      h4 {
        font-size: rem(14);
      }
    }
    .export-type {
      .type-form-group {
        .type-form-label {
          font-size: rem(13);
        }
        .type-form-control {
          font-size: rem(13);
        }
      }
    }
    .export-detail-footer {
      display: none;
    }
  }
}
