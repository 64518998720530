@import "assets/css/base.scss";

.statistics-wrap {
  h5 {
    color: $col_black;
    margin-bottom: rem(12);
    font-size: rem(16);
    font-weight: $fw_7;
    display: flex;
    justify-content: flex-start;

    .title {
      margin-right: rem(7);
    }
  }

  .statistics-row {
    font-size: rem(16);
    line-height: rem(24);
    display: flex;

    .statistics-column {
      width: 50%;
      margin-right: rem(32);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
