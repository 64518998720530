@import "assets/css/_base.scss";

.conversation-information-filter {
  .note-tooltip-group {
    position: relative;
    margin-right: rem(17);

    span {
      position: absolute;
      top: calc(100% + rem(2));
      right: 0;
      animation-delay: 0s !important;
      display: none;
      background: $col_gray-750;
      box-shadow: $box_shadow;
      border-radius: rem(4);
      width: rem(253);
      height: rem(92);
      padding: rem(16);
      z-index: 99;
      color: $col_white;
      font-weight: $fw_4;
      font-size: rem(14);
    }

    .tooltip-description {
      display: none;
      z-index: 9999;
    }

    &:hover > .tooltip-description {
      display: block;
    }
  }

  .search-wrapper {
    .btn-input {
      height: rem(28);
      width: vw(170);
      font-size: rem(14);
      padding-right: rem(44);
    }
  }

  &-search {
    position: relative;
    .search-button {
      width: rem(28);
      height: rem(28);
      border: rem(1) solid $col_gray-300;
      cursor: pointer;
      border-radius: rem(4);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .search-input-container {
      min-width: rem(362) !important;
      display: flex !important;
      justify-content: center;
      border: none;
      box-shadow: $box_shadow;
      padding: 0 !important;
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: rem(4);

      .search-parent-element {
        display: flex !important;
        height: rem(32);

        .filter-spinner {
          width: rem(20);
          margin-right: rem(8);

          .spinner-section {
            width: rem(16);
            height: rem(16);
          }
        }

        .input-autocomplete {
          margin-right: rem(32);

          .search-input {
            padding: rem(4) rem(14) rem(4) rem(10);
            font-size: rem(14);
            font-weight: $fw_4;
            width: rem(167);
          }
        }
        .count-index {
          color: $col_black;
          font-size: rem(14);
          font-weight: $fw_4;
          margin: auto rem(8);
          line-height: rem(32);
        }

        .btn-input {
          border: none;

          &:focus {
            box-shadow: none;
          }
          &:hover {
            box-shadow: none;
          }
        }

        .vertical-divider {
          border-right: rem(1) solid $col_gray-700;
          height: rem(16);
          margin: 0;
        }

        button {
          width: fit-content;
        }

        .warning-tooltip {
          margin-left: rem(10);

          img {
            position: relative;
          }

          &:hover {
            .warning-description {
              display: block;
            }
          }

          .warning-description {
            display: none;
            width: rem(253);
            height: auto;
            background-color: rgba(34, 34, 34, 0.8);
            box-shadow: $box_shadow;
            border-radius: rem(4);
            padding: rem(16);

            position: absolute;
            top: 100%;
            z-index: 999;
            margin-top: rem(5);

            span {
              font-weight: $fw_4;
              font-size: rem(14);
              line-height: rem(20);
              color: $col_white;
            }
          }
        }

        .up-down-container {
          width: rem(56);
          padding: 0 rem(11);

          .up-search,
          .down-search {
            margin: auto 0;
            cursor: pointer;
          }

          .up-search {
            margin-right: rem(14);
          }
        }

        .dropdown-search-option {
          > button {
            width: rem(40);
            height: rem(32);
            font-size: rem(14);
            border: none;
            cursor: pointer;
            margin-left: rem(8);
            padding: 0;
          }
        }

        .dropdown-container {
          min-width: rem(110) !important;
          > button {
            height: rem(32);
            font-size: rem(14);
            border: none;
            cursor: pointer;
            margin: 0 rem(8);
            padding: 0;
          }
          > ul {
            margin-right: 0;
            width: rem(110);

            > li {
              font-size: rem(14);
            }
            > li.active {
              color: $col_black;
              font-size: rem(14);
              font-weight: $fw_7;
            }
          }
        }
      }
    }

    .hide-element {
      display: none !important;
    }

    .icon-search {
      width: rem(16);
      height: rem(16);
    }

    .icon-clear {
      width: rem(14);
      height: rem(14);
      right: rem(28);
    }
  }
}
