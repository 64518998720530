@import "assets/css/base";

.wrap {
  margin-top: rem(16);
  .select-label {
    font-weight: $fw-7;
    font-size: rem(12);
    color: $col_gray-600;
    margin-bottom: rem(12);
  }
  .select-list {
    width: 100%;
    max-height: rem(180);
    @include scroll-flexible;
    overflow-y: auto;
    padding-right: rem(5);
  }

  .emojis {
    display: grid;
    grid-template-columns: repeat(auto-fill, rem(25));
    justify-content: space-between;
    max-height: rem(144);
  }
}
