@import "assets/css/base";

.wrap {
  .tab-control {
    margin-top: rem(16);
    margin-bottom: rem(24);
  }
}

@media print {
  @-moz-document url-prefix() {
    .wrap {
      .tab-control {
        margin: rem(12) 0;
      }
    }

    :global(.main) {
      min-height: fit-content !important;
    }
  }
}