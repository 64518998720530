@import "assets/css/base.scss";

.source-list {
  cursor: default;
  position: absolute;

  .source-info {
    display: flex;
    align-items: center;
    position: relative;
    bottom: rem(10);
  }

  .source-icon {
    width: rem(18);
    height: rem(18);
    margin-right: rem(6);
  }

  .total-source {
    font-weight: $fw_4;
    font-size: rem(14);
    line-height: rem(20);
    color: $col_gray-600;
    margin-left: rem(4);
    cursor: pointer;

    .arrow-icon {
      margin-left: rem(4);
      width: rem(7);
    }
  }

  .source-list {
    position: absolute;
    top: calc(100% + rem(6));
    right: 0;
    min-width: rem(148);
    width: rem(148);
    height: fit-content;
    padding: rem(16) rem(12);
    background-color: $col-white;
    box-shadow: $box_shadow;
    border-radius: rem(4);
    z-index: 999;
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(100% / 5));
    justify-content: space-between;
    gap: rem(4) 0;
  }
}

.dropdown-source {
  margin-right: rem(8);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  img {
    margin-right: rem(4);
  }

  .source-title {
    font-size: rem(14);
    display: flex;
    align-items: center;

    .arrow-icon {
      margin: rem(2) rem(4) 0;
      width: rem(6);
    }
  }

  .source-list {
    position: absolute;
    top: calc(100% + rem(6));
    right: 0;
    min-width: rem(148);
    width: fit-content;
    max-height: rem(210);
    padding: rem(14);
    background-color: $col-white;
    box-shadow: $box_shadow;
    border-radius: rem(4);
    z-index: 999;
    overflow-x: hidden;
    overflow-y: scroll;
    @include scroll-flexible;

    li {
      display: flex;
      align-items: center;
      margin-bottom: rem(10);
      cursor: pointer;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-size: rem(14);
        margin-left: rem(4);
      }

      .active {
        font-weight: $fw_7;
      }

      &:hover {
        span {
          font-weight: $fw_7;
        }
      }
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .source-list {
      .source-icon {
        width: rem(14);
        height: rem(14);
        margin-right: rem(4);
      }
    }
    .total-source {
      font-size: rem(10);
      .arrow-icon {
        margin-left: 0;
        width: rem(2);
      }
    }
  }
}
