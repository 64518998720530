@import "assets/css/_base.scss";

.history-link-wrap {
  a {
    font-weight: $fw_4;
    font-size: rem(16);
    color: $col_blue !important;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .history-link-content {
    padding-top: rem(4);
  }

  .history-link-preview {
    margin-bottom: rem(2);
  }
}
