@import "assets/css/_base.scss";

.field-container {
  position: relative;

  &:first-child {
    label {
      margin-top: 0;
    }
  }

  label {
    margin: rem(24) 0 rem(10);
    font-size: rem(12);
    font-weight: $fw_5;
  }

  input {
    width: 100%;
    height: rem(40);
    border: rem(1) solid $col_gray-850;
    border-radius: rem(4);
    padding: 0 rem(16);
    font-size: rem(16);
    font-weight: $fw_4;
    color: $col_black_pearl;

    &:hover,
    &:focus {
      cursor: pointer;
      border-color: $col_gray-850;
      box-shadow: none; 
      outline: unset;
    }

    &.disabled {
      background-color: $col_gray-200; 
      opacity: 0.7; 
      cursor: not-allowed;
    }
  }

  .calender-icon {
    width: rem(24);
    height: rem(24);
    position: absolute;
    right: 0;
    bottom: 0;
    margin: rem(8) rem(10);
    cursor: pointer;

    &.disabled {
      filter: grayscale(100%); 
      cursor: not-allowed; 
    }
  }

  .calendar-container {
    position: absolute;
    bottom: rem(2);
    right: 0;
    margin-bottom: rem(48);
    background-color: $col_white;
    border-radius: rem(8);
    box-shadow: $box-shadow;
    height: fit-content;
    padding-bottom: rem(10);

    :global(.rdrCalendarWrapper) {
      border-radius: rem(8);
    }

    :global(.rdrMonthAndYearWrapper) {
      padding: 0;
    }
  }

  .select-date-container {
    font-size: rem(13);
    display: flex;
    align-items: center;
    padding-left: rem(10);
    padding-top: rem(15);
    padding-bottom: rem(5);
    border-top: rem(1) solid $col_gray-300;
    
    &__title {
      font-weight: bold;
      color: $col_blue;
      margin-right: rem(5);
    }
  }

  .save-btn {
    margin-left: auto;
    margin-right: rem(10);
  }

  .time-picker-control {
    display: flex;
    justify-content:center;
    padding-bottom: rem(15);
    .btn-group {
      display: flex;
      justify-content: center;
      margin-left: rem(10);
      a {
        background-color: $col_white;
        color: $col_black;
        padding: rem(6) rem(10);
        font-size: rem(14);
        border: rem(1) solid $col_gray-300;
      }
    }
  
    input {
      border: rem(1) solid $col_gray-300;
      border-radius: rem(4);
      width: rem(50);
      padding: rem(6);
      @include text-center;
      font-size: rem(16);
      margin: 0 rem(10);
    }
  
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      opacity: 1;
    }
  
    .nav-pills {
      .nav-item {
        .nav-link.active {
          background-color: $col_blue-2;
          color: $col_blue;
        }
        .nav-link {
          padding: rem(4) rem(8);
        }
      }
    }
  }

  .form-error {
    position: absolute;
    top: calc(100% + rem(4));
    left: 0;
  }
}
