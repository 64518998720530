@import "assets/css/base";

.section {
  width: rem(350);
  background-color: $col_white;
  border-radius: rem(6);
  box-shadow: $box_shadow;
  padding: rem(16);
  margin-top: rem(8);

  .container {
    .content {
      display: flex;
      flex-wrap: wrap;

      img {
        max-width: none !important;
        width: 100%;
        max-height: rem(350);
        object-fit: cover;
        object-position: center;
      }

      .is-clipped {
        overflow: hidden;
        .title {
          font-size: rem(14);
          font-weight: $fw_7;
          margin-top: rem(10);
          color: $col_gray_600;
        }

        .description {
          font-size: rem(12);
          font-weight: $fw_4;
          font-weight: rem(16);
          color: $col_gray;
          margin-top: rem(2);

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5; /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        .linkURL {
          font-size: rem(12);
          font-weight: $fw_4;
          font-weight: rem(16);
          color: $col_gray;
          margin-top: rem(4);
          white-space: nowrap;
        }
      }
    }
  }

  &-sm {
    width: rem(250);
    border-radius: rem(4);
    padding: rem(12);

    .container {
      .content {
        img {
          max-height: rem(250);
          min-height: rem(150);
          object-fit: contain;
          object-position: center;
        }

        .is-clipped {
          .title {
            font-size: rem(12);
          }

          .description {
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }
}
