@import "assets/css/base";

.modal-container {
  .modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: none;
    padding-bottom: rem(16) !important;
    position: relative;

    .modal-header-title {
      color: $col_gray-600;
      font-weight: $fw_5;
      font-size: rem(24);
      line-height: rem(32);
    }

    .header-close {
      position: absolute;
      top: rem(16);
      right: rem(16);
      cursor: pointer;
    }
  }

  .loading-admin {
    min-height: rem(686);
  }

  .loading {
    min-height: rem(588);
  }

  .modal-body {
    padding-top: 0 !important;

    .user-information {
      &__photo {
        margin-bottom: rem(24);
        display: flex;
        align-items: center;

        &-img {
          width: rem(160);
          height: rem(160);
          flex: 0 0 rem(160);
          border-radius: 50%;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }
        }

        &-create {
          padding-left: rem(54);
        }
      }
    }

    .modal-body-select {
      &:disabled {
        img {
          display: none;
        }
      }
    }
    
    .modal-body-note {
      font-size: rem(18);
      color: $col_gray;
      font-weight: $fw_4;
    }
    .modal-body-label {
      font-weight: $fw_5;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_gray-600;
      margin-bottom: rem(6);

      span {
        display: inline;
      }
    }

    .tooltip-info-icon {
      display: inline-block;
      position: relative;
      margin-left: rem(8);

      &:hover {
        .tooltip-info {
          display: block;
        }
      }
    }

    .tooltip-info {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      width: rem(253);
      background-color: rgba(34, 34, 34, 0.8);
      box-shadow: $box_shadow;
      border-radius: rem(4);
      padding: rem(16);
      margin-top: rem(2);
      z-index: 1000;

      p {
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(20);
        color: $col_white;
      }
    }

    p {
      font-weight: $fw_4;
      font-size: rem(16);
      line-height: rem(24);
      color: $col_gray-600;
      margin-bottom: 0 !important;

      a {
        color: $col_blue;
      }
    }

    .modal-body-input {
      font-size: rem(16);
      color: $col_gray-600;
      font-weight: $fw_4;
      padding: rem(12) rem(16);
      width: 100%;
      border: rem(1) solid $col_gray-300;
      border-radius: rem(6);
      height: rem(48);
    }

    .w-50 {
      width: 50%;
    }

    .phone-input {
      margin-bottom: rem(12);

      .area-code-group {
        width: 32%;
        margin-right: rem(16);
        user-select: none;

        .area-code {
          font-weight: $fw_4;
          font-size: rem(16);
          line-height: rem(48);
          padding-left: rem(50);
          color: $col_gray-600;
        }
      }
      .phone-number {
        width: 68%;
      }

      :global(.react-tel-input) {
        :global(.form-control) {
          padding-left: rem(50);
          width: 100%;
          height: rem(48);
          border: rem(1) solid $col_gray-300;
          display: none;
        }

        :global(.country-list) {
          position: absolute;
          bottom: 100%;
          @include scroll-flexible;
        }

        :global(.flag-dropdown) {
          width: 100%;
          height: rem(48);
          border: rem(1) solid $col_gray-300;
          border-radius: rem(6);
          background: url(~/public/images/icons/arrow-down.svg) rem(110) center /
            rem(10) no-repeat;

          :global(.selected-flag) {
            width: 100%;
            padding-left: rem(16);
            background-color: unset;

            &::before {
              height: 100%;
              position: absolute;
              top: 0;
            }

            :global(.arrow) {
              display: none;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    padding: rem(20) rem(24) !important;
    padding: rem(16);
    display: flex;

    & > * {
      margin: 0;
    }

    button {
      margin-left: rem(16) !important;
    }
  }

  :global(.modal-dialog) {
    max-width: rem(480);
  }

  @media only screen and (min-width: 3840px) {
    :global(.modal-dialog) {
      height: auto;
      margin-top: rem(160);
    }
  }
}
