@import "assets/css/_base.scss";

.container {
  .year-picker-container {
    display: flex;
    align-items: center;

    .year-picker-dropdown {
      display: flex;
      width: rem(70);
      position: relative;
      justify-content: center;
      align-items: center;

      .year-picker {
        width: rem(70) !important;
        background-color: $col_white;
        position: relative;
        height: rem(28);

        &::after {
          position: absolute;
          right: 0;
          top: 50%;
          display: block;
          content: "";
          width: rem(8);
          height: rem(8);
          background: url("~/public/images/icons/arrow-down.svg") center
            no-repeat;
        }

        input {
          cursor: pointer;
          font-size: rem(18);
          border: none !important;
          background-color: $col_white;
          color: $col_gray-600;
        }

        :global(.rdtYear),
        :global(.rdtSwitch) {
          font-size: rem(14);
        }

        :global(.rdtActive) {
          background-color: $col_blue;
        }
      }
    }

    .months-list {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin-left: rem(32);
      .months-disable {
        pointer-events: none;

        span {
          opacity: 0.3;
        }
      }

      span {
        font-size: rem(16);
        cursor: pointer;
        color: $col_gray-600;
      }

      .active {
        background-color: $col_blue;
        color: $col_white;
        padding: rem(8) rem(13);
        border-radius: rem(4);
      }
    }
  }

  .days-list {
    margin-top: rem(24);
    justify-content: space-between;
    display: flex;

    .days-disable {
      pointer-events: none;

      span {
        opacity: 0.3;
      }
    }

    span {
      font-size: rem(16);
      color: $col_gray-600;
      cursor: pointer;
    }

    .active {
      background-color: $col_blue;
      color: $col_white;
      padding: rem(8);
      border-radius: rem(4);
    }
    .sunday {
      color: $col_begonia;
    }
  }

  .days-list-disable {
    margin-top: rem(24);
    justify-content: space-between;
    display: flex;

    pointer-events: none;

    span {
      opacity: 0.3;
      font-size: rem(16);
      color: $col_gray-600;
      cursor: pointer;
    }

    .sunday {
      color: $col_begonia;
    }
  }
}
