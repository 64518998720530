@import "assets/css/_base.scss";

.wrap {
  display: flex;
  flex-direction: column;

  .name-wrap {
    display: flex;
    align-items: center;
    margin-bottom: rem(2);

    .name-person {
      font-weight: $fw_7;
      font-size: rem(12);
      display: block;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name-dot {
      width: rem(3);
      height: rem(3);
      border-radius: 50%;
      background-color: $col_black;
      margin: rem(8);
    }

    .chat-time {
      color: $col_gray;
    }

    img {
      width: rem(14);
      height: rem(14);
    }
  }

  .subject-wrap {
    font-weight: $fw_7;
    font-size: rem(16);
    display: block;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: rem(4) 0;
  }

  .divider-line {
    margin-top: rem(1);
    margin-bottom: rem(8);
    border-top: 1px solid #8a8a8a;
  }
}