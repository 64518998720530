@import "assets/css/_base.scss";

.field-container {
  position: relative;
  &:first-child {
    label {
      margin-top: 0;
    }
  }
  
  label {
    margin: rem(24) 0 rem(10);
    font-size: rem(12);
    font-weight: $fw_5;
  }

  input {
    width: 100%;
    height: rem(40);
    border: rem(1) solid $col_gray-850;
    border-radius: rem(4);
    padding: 0 rem(16);
    font-size: rem(16);
    font-weight: $fw_4;
    color: $col_black_pearl;

    &:hover,
    &:focus {
      border-color: $col_dark-jeans;
      box-shadow: 0 0 rem(2) $col_blue;
      outline: unset;
    }

    &:disabled {
      border-color: $col_gray-850;
      background-color: $col_gray-800;
      box-shadow: none;
    }
  }

  .form-error {
    position: absolute;
    top: calc(100% + rem(4));
    left: 0;
  }
}
