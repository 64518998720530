@import "assets/css/_base.scss";

.accordion-container {
    position: relative;
}

.accordion-button {
  position: absolute;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  outline: none;
  display: inline-block;
  white-space: nowrap;
  color: $col_blue;
  font-size: rem(12);
}

.accordion-button:hover {
  opacity: 0.75;
}

.accordion-button:active {
  opacity: 1;
}

.accordion-image {
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
}

.accordion-spinner {
  position: absolute;
  display: inline-block;
  margin-left: rem(34);
  margin-top: rem(7);
  transform: scale(0.75);
}