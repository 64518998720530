@import "assets/css/_base.scss";

.progress-bar {
  position: relative;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  height: 20px;

  .progress-fill {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #76c7c0;
    transition: width 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 12px;
  }
}