@import "assets/css/base";

.popup-notification-dialog {
  :global(.modal-dialog) {
    max-width: rem(320);
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .modal-header-custom {
      padding: rem(16) 0 rem(12) 0 !important;
      border-bottom: unset;
      @include text-center;
      display: block;

      .modal-header-title {
        font-weight: $fw_6;
        font-size: rem(16);
        line-height: rem(24);
        color: $col_black;
      }
    }

    .content {
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_black;
      @include text-center;
      padding: 0 rem(22);
    }

    .modal-footer-custom {
      padding: rem(21) 0 rem(22) !important;
      margin-bottom: 0;
      border-top: unset;
      justify-content: center;
    }

    :global(.modal-footer > *) {
      margin: 0;
    }
  }
}
