@import "assets/css/base.scss";

.wrap {
  position: relative;
  width: 100%;

  .form-control {
    position: relative;
    @include flex-between;

    &-input {
      height: rem(28);
      width: 100%;
      font-size: rem(12);
      line-height: rem(20);
      color: $col_gray-600;
      padding: rem(4) rem(8);
    }

    &-label {
      font-size: rem(12);
      line-height: rem(16);
      color: $col_black;
      width: 90%;
      @include text-ellipsis;

      img {
        cursor: pointer;
      }
    }

    .border-error {
      border-color: $col_red !important;
    }
  }

  .form-error {
    width: 100%;
    position: absolute;
    left: 0;
    top: calc(100% + rem(2));
  }
}
