@charset "utf-8";
/* ===================================================================
	common pc.css
=================================================================== */
@import "./_base.scss";

/* ----------------- Reset First ---------------- */
.guide {
  width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

.guide_lg {
  width: 1073px;
  margin: 0 auto;
  overflow: hidden;
}

.break_pc {
  display: block;
}

.break_sp {
  display: none;
}

.f_sp {
  display: none !important;
}

.f_pc {
  display: block !important;
}

/* ----------------- /. Reset First ---------------- */
#container {
  overflow: hidden;
  position: relative;
  background-color: $bg_color;
  padding: 0;
}

a {
  @include hov;
}

.main {
  padding: 0;
  margin: 0 auto rem(40) auto;
  min-height: calc(100vh - $height-header);
  width: vw(1480);
  min-width: rem(1000);

  &-container {
    width: rem(1135);
    min-height: 100%;
    border-right: solid rem(1) $col_gray-300;
    padding: rem(40) rem(32) rem(40) 0;
    float: left;
  }

  &-activities {
    padding: rem(32) 0 rem(40) vw(1167);
  }
}

header {
  height: $height-header;
  background-color: $col_white;
  display: flex;
  justify-content: space-between;
  border-bottom: solid rem(1) $col_gray-300;

  .logo {
    padding: rem(10) rem(10) rem(10) rem(40);
    margin: 0;
  }

  .nav_global {
    > ul {
      display: flex;
      height: 100%;
      align-items: center;

      li {
        position: relative;
      }

      .nav-administration {
        &:hover,
        &:hover .sub-nav-administration {
          opacity: 1 !important;
        }

        &:hover .sub-nav-administration {
          display: block;
        }

        span {
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            right: rem(-10);
            top: rem(15);
            width: 0;
            height: 0;
            border-left: rem(4) solid transparent;
            border-right: rem(4) solid transparent;
            border-top: rem(6) solid $col_gray-600;
            clear: both;
          }
        }

        &.active span {
          color: $col_blue;
          font-weight: bold;
          border-bottom: rem(1) solid $col_blue;

          &::after {
            border-top: rem(6) solid $col_blue;
          }
        }
      }

      .sub-nav-administration {
        display: none;
        min-width: rem(207);
        position: absolute;
        top: 100%;
        right: rem(16);
        box-shadow: $box_shadow;
        border-radius: rem(4);
        background-color: $col_white;
        z-index: 9999;

        li {
          margin: 0 rem(24);
          padding-bottom: rem(16);

          &:first-child {
            padding-top: rem(16);
          }

          a {
            color: $col_black;
            margin: 0;
            padding: 0;
          }
        }
      }

      a,
      span {
        font-size: rem(16);
        line-height: rem(24);
        padding: rem(5);
        margin: 0 rem(30);
        display: inline-block;
        white-space: nowrap;
        color: $col_gray-600;

        &.active {
          color: $col_blue;
          font-weight: bold;
          border-bottom: rem(1) solid $col_blue;
        }
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    padding: 0 rem(40) 0;

    .dropdown {
      display: flex;
      align-items: center;

      &-item {
        padding: rem(5) rem(10);
        display: flex;
      }
    }

    &-name {
      font-size: rem(16);
      line-height: rem(24);
      padding: 0 rem(14);
      position: relative;
      background-color: unset !important;
      border: none !important;
      color: $col_black !important;
      box-shadow: none !important;
      font-weight: $fw_4;
      color: $col_black;
      max-width: rem(160);
      @include text-ellipsis;

      &-dropdown {
        font-size: rem(16);
        padding: rem(10) 0;
        margin-top: rem(15) !important;
        background: $col_white;
        box-shadow: $box_shadow;
        border-radius: rem(4);
        border: unset !important;
        min-width: rem(185);
        z-index: 1999 !important;
        inset: 0 0 auto auto !important;

        .profile-name-icon {
          margin-right: rem(6);
        }
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: rem(4) solid transparent;
        border-right: rem(4) solid transparent;
        border-top: rem(6) solid $col_dark-charcoal;
        clear: both;
      }
    }
  }
}

.btn-default {
  width: 100%;
  background: $col_blue;
  border-radius: rem(6);
  border: rem(1) solid $col_blue;
  padding: rem(5);
  color: $col_white;
  font-weight: bold;
  font-size: rem(16);
  height: rem(64);
  cursor: pointer;
}

.entity-navigate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: rem(20);

  h2 {
    font-size: rem(32);
    line-height: rem(40);
    font-style: normal;
    font-weight: normal;
    white-space: nowrap;
  }

  .entity-search {
    display: flex;
    align-items: center;
    position: relative;

    .user-name {
      font-size: rem(16);
      margin-right: rem(16);
    }

    .icon-print {
      width: rem(40);
      margin-right: rem(5);
    }

    .btn-add-container {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: rem(5);

      img {
        position: absolute;
        width: rem(20);
        left: rem(10);
      }

      button {
        width: rem(160);
        height: rem(40);
        background: $col_white;
        border: rem(1) solid $col_blue;
        box-sizing: border-box;
        border-radius: rem(4);
        color: $col_blue;
        font-size: rem(16);
        text-align: right;
      }
    }

    .input-autocomplete {
      position: relative;

      input.btn-input[type="text"] {
        padding: rem(7) rem(25) rem(7) rem(10);
      }

      input[type="submit"] {
        background-color: $col_blue;
        color: $col_white;
        cursor: pointer;
      }
    }

    .icon-search {
      position: absolute;
      right: rem(10);
      width: rem(24);
      height: rem(24);
      // background: url('../images/icon-search.png') top left;
      background-size: 100%;
      border: none;
    }

    .autocomplete-items {
      position: absolute;
      max-height: rem(315);
      overflow-x: hidden;
      z-index: 99;
      top: 120%;
      left: 0;
      right: 0;
      background: $col_white;
      box-shadow: $box_shadow;
      border-radius: rem(8);
    }

    .autocomplete-items div {
      cursor: pointer;
      padding: rem(4) 0;
      font-size: rem(16);
      padding: rem(10);
    }

    /*when hovering an item:*/
    .autocomplete-items div:hover {
      background-color: #e9e9e9;
    }

    /*when navigating through the items using the arrow keys:*/
    .autocomplete-active {
      background-color: $col_blue;
      color: $col_white;
    }
  }
}

.btn-check,
.btn {
  transition: box-shadow 150ms ease-out;

  &:focus,
  &:focus {
    outline: 0;
    border-color: $col_dark-jeans;
    box-shadow: 0 0 rem(4) $col_blue;
  }
}

.btn-input {
  transition: box-shadow 150ms ease-out;
  background: $col_white;
  color: $col_black;
  width: rem(320);
  box-sizing: border-box;
  font-size: rem(16);
  border: rem(1) solid $col_gray-300;
  border-radius: rem(4);
  padding: rem(7) rem(10);

  &:hover,
  &:focus {
    border-color: $col_dark-jeans !important;
    box-shadow: 0 0 rem(4) $col_blue;
    outline: unset;
  }

  &-error {
    border-color: $col_red !important;
  }
}

.area-input {
  transition: box-shadow 150ms ease-out;
  background: $col_white;
  color: $col_black;
  width: rem(320);
  box-sizing: border-box;
  font-size: rem(16);
  border: rem(1) solid $col_gray-300;
  border-radius: rem(4);
  padding: rem(10) rem(15);
  min-height: rem(145);
  resize: vertical;

  &:hover,
  &:focus {
    border-color: $col_dark-jeans !important;
    box-shadow: 0 0 rem(4) $col_blue;
    outline: unset;
  }
}

.area-input {
  transition: box-shadow 150ms ease-out;
  background: $col_white;
  color: $col_black;
  width: rem(320);
  box-sizing: border-box;
  font-size: rem(16);
  border: rem(1) solid $col_gray-300;
  border-radius: rem(4);
  padding: rem(10) rem(15);
  min-height: rem(145);
  resize: vertical;

  &:hover,
  &:focus {
    border-color: $col_dark-jeans !important;
    box-shadow: 0 0 rem(4) $col_blue;
    outline: unset;
  }
}

.breadcrumb {
  margin: rem(11) 0;
  list-style: none;
  font-size: rem(12);
  background: none;
  border-radius: 0;
  padding: 0;

  li {
    display: inline;

    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
      color: $col_blue;
    }

    &.last {
      color: $col_gray-400;
    }
  }

  li + li:before {
    content: ">\00a0" !important;
    margin-left: rem(5);
  }
}

.back-top {
  margin-top: rem(11);

  &-item {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: rem(12);

    a {
      display: inline-block;
      margin-left: rem(6);
      color: $col_blue;
    }
  }
}

.btn-action {
  height: rem(40);
  border: rem(1) solid $col_blue;
  color: $col_blue;
  background-color: $col_white;
  background-position: 50%;
  background-repeat: no-repeat;
  font-weight: normal;
  font-size: rem(16);
}

img {
  vertical-align: middle;
  border-style: none;
}

.modal {
  .modal-content {
    border-radius: rem(8);

    .modal-header,
    .modal-body,
    .modal-footer {
      padding: rem(24);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.spinner-loading {
  position: absolute;
  top: calc(50% - rem(10));
  left: calc(50% - rem(10));
}

.spinner-loading-more {
  position: absolute;
  top: rem(5);
  bottom: auto;
  left: calc(50% - rem(10));
  z-index: 999;
  font-size: rem(10);
}

.spinner-bottom {
  bottom: rem(5);
  top: auto;
}

.spinner-loading-more-table {
  z-index: 999;
  font-size: rem(10);
}

.conversation-detail.table .spinner-loading-more {
  bottom: rem(20);
}

span.error {
  font-size: rem(12);
  margin-top: rem(6);
  display: block;
  line-height: rem(14);
}

.select-custom {
  position: relative;

  &:focus {
    outline: 0;
    border-color: $col_dark-jeans;
    box-shadow: 0 0 rem(4) $col_blue;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: rem(1);
    text-overflow: "";
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    font-weight: $fw_4;
    font-size: rem(16);
    line-height: rem(24);
    color: $col_dark-charcoal;
    padding: rem(8) rem(16);
    border-radius: rem(4);

    &:focus {
      outline: 0;
      border-color: $col_dark-jeans;
      box-shadow: 0 0 rem(4) $col_blue;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &::after {
    content: url("~/public/images/select-dropdown.svg");
    position: absolute;
    top: 50%;
    right: rem(16);
    z-index: 100;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

@media print {
  .print__option,
  header,
  .back-btn {
    display: none;
  }

  #container {
    overflow: inherit;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    header {
      width: 100%;
    }
  }

  .main {
    width: 100%;
  }

  @-moz-document url-prefix() {
    .main-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > div {
        width: 100%;
      }
    }
  }

  @page {
    size: landscape;
    margin: rem(10);
  }
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    print-color-adjust: exact;
  }
}

.spinner-border {
  display: table;
  margin: 0 auto;
  border-width: rem(2);
}

.page-title {
  font-size: rem(32);
  color: $col_gray-600;
}

.Toastify {
  &__toast-container {
    top: rem(100);
  }

  &__toast--success {
    color: $col_green-300;
    background: $color_feta;
    border: rem(1) solid $col_green-300;
  }

  &__toast--error {
    color: $col_begonia;
    background: $color_fantasy;
    border: rem(1) solid $col_begonia;
  }

  &__toast-body {
    > div:last-child {
      font-style: normal;
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
    }
  }
}

//style dropdown react select
.dropdown-react-select {
  font-weight: $fw_4;
  font-size: rem(16);
  width: rem(332);

  &:hover,
  &:focus {
    border-color: $col_dark-jeans !important;
    border-radius: rem(4);
    box-shadow: 0 0 rem(4) $col_blue;
    outline: unset;
  }

  div {
    @include scroll-flexible;
  }

  > div {
    cursor: pointer;
    border: rem(1) solid $col_gray-300;
    border-radius: rem(4);
    min-height: rem(40);

    span {
      background-color: $col_gray-300;
    }

    svg {
      color: $col_gray-600;
      width: rem(18);
    }
  }
}

// Hidden text default in chart
.highcharts-credits {
  display: none;
}

// Hidden map-copyright
.map-copyright {
  display: none;
}

//Style input field
.app-form-group {
  .app-form-label {
    font-size: rem(12);
    font-weight: $fw_5;
    color: $col_gray-600;
  }

  .app-form-input-md-disable {
    font-size: rem(14);
    border-radius: rem(4);
    outline: none;
    background-color: $col_gray-300;
    color: $col_gray;
  }

  .app-form-input-md {
    font-size: rem(14);
    border-radius: rem(4);
    outline: none;
    border: rem(1) dashed $col_blue;
    background-color: $col_white;
    color: $col_blue;

    &.error {
      border-color: $col_red;
    }
  }

  .app-form-input,
  .app-form-textarea {
    font-size: rem(16);
    border-radius: rem(4);
    padding: rem(6) rem(16);
    outline: none;
    border: rem(1) solid $col_gray-300;
    &.error {
      border-color: $col_red;
    }
  }
  .app-form-textarea {
    width: 100%;
  }
  .error-text {
    font-size: rem(12);
    margin-top: rem(6);
    display: block;
    line-height: rem(14);
    color: $col_red;
  }
}

.link-preview {
  font-size: rem(12);
  line-height: rem(20);
  font-weight: $fw_4;
  text-decoration: underline !important;
  color: $col_blue !important;
  cursor: pointer;
}

.link-preview-default {
  padding: rem(10);
  background-color: $col_gray-50;
  border-radius: rem(4);
  .img-wrap {
    padding: rem(10);
    background-color: white;
    width: rem(250);
    height: rem(180);

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1679px) {
  .main {
    width: 94vw;
    min-width: rem(1000);
  }
}

@media screen and (min-width: 3840px) {
  html,
  body {
    font-size: 16px;
  }

  .popup-confirm-dialog {
    .modal-dialog {
      height: auto;
      margin-top: rem(350);
    }
  }
}
