@import "assets/css/_base.scss";

.container {
  width: rem(254);
  height: rem(155);
  border: rem(1) solid rgba(211, 211, 211, 2.0);
  border-radius: rem(8);
  margin-top: rem(16);
  padding: rem(12);
  cursor: pointer;
  position: relative;
  z-index: 99;
  background-color: $col-white;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .source-container {
      display: flex;

      .progress-bar-container {
        width: rem(3);
        height: rem(24);
        position: relative;
        border-radius: rem(1);
        overflow: hidden;
      }

      .source {
        font-weight: $fw_5;
        font-size: rem(14);
        color: $col_gray-600;
        margin-left: rem(4);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .time {
      font-weight: $fw_4;
      font-size: rem(11);
      color: $col_gray;
      white-space: nowrap;
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    margin-top: rem(13);
    width: 100%;

    .total-record {
      width: 100%;

      .general-info {
        display: flex;
        align-items: center;
        padding-top: rem(8);

        span {
          font-weight: $fw_4;
          font-size: rem(14);
          color: $col_gray;
          margin-left: rem(8);
          @include text-ellipsis;
        }

        img {
          width: rem(16);
          height: rem(16);
        }

        .data-source {
          font-size: rem(12);
          width: rem(160);
          margin-left: rem(52);
          text-align: right;
          direction: rtl;
          unicode-bidi: plaintext;
          @include text-ellipsis;
        }
      }

      .general-info-chat {
        margin-top: rem(5);
      }

      .spacing-right {
        margin-right: rem(58);
      }
    }

    .member-list {
      position: absolute;
      right: rem(0);
      top: rem(50);
      margin-right: rem(12);
      width: rem(56);
    }

    .member-list-chat {
      position: absolute;
      right: rem(0);
      margin-right: rem(12);
      top: rem(245);
    }

    .member-list-email {
      position: absolute;
      right: rem(0);
      margin-right: rem(12);
      top: rem(320);
    }

    .title {
      @include text-ellipsis;
    }

    a.link {
      color: $col_blue;
      cursor: pointer;
    }

    &-datatype {
      font-size: rem(14);
      padding: rem(10);
      padding-top: 0;
      min-width: rem(200);
      width: 100%;
      height: rem(73);
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-flexible;
    }

    &-image {
      height: rem(205);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-video {
      height: rem(205);
      overflow: hidden;
    }

    &-audio {
      height: rem(98);
    }

    &-note {
      height: rem(205);
      border: rem(1) solid $col_gray-800;
      border-radius: rem(8);
    }

    &-email {
      border: rem(1) solid $col_gray-800;
      border-radius: rem(8);
      padding: rem(16);
      min-width: rem(200);
      width: 100%;
      height: rem(265);
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-flexible;
      background-color: $col_gray-300;
    }

    &-groupchat {
      border: rem(1) solid $col_gray-800;
      border-radius: rem(8);
      padding: rem(16);
      min-width: rem(200);
      width: 100%;
      height: rem(190);
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-flexible;

      .conversation-action {
        font-style: italic;
        font-size: rem(12);
        width: 100%;
        padding: rem(4) 0;
        @include flex-between;
  
        .content {
          color: $col_blue;
        }
        .leave {
          color: $col_red-4;
        }
        .date {
          color: $col_gray;
          padding: 0 rem(10);
          font-size: rem(11);
        }
      }

      li {
        + li {
          margin-top: rem(16);
          max-width: rem(300);
        }

        background-color: $col_gray-50;
        padding: rem(12);
        border-radius: rem(8);
        width: fit-content;
        margin-left: auto;

        &.body-groupchat-left {
          margin-left: unset;
          margin-right: auto;
        }
      }

      &-header {
        font-size: rem(12);
        display: flex;
        color: $col_gray;

        span {
          color: $col_gray-600;
          font-weight: $fw_7;
        }

        margin-bottom: rem(12);
      }

      &-message {
        font-size: rem(14);
        color: $col_gray;
        max-width: rem(256);
      }

      &-attachment-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-attachment {
        width: 50%;
        height: 50%;
      }
    }
  }

  .des {
    display: flex;
    position: absolute;
    bottom: calc(100% + rem(16));
    z-index: 9;
    background-color: $col-white;
    border-radius: rem(8);
    box-shadow: $box_shadow;
    padding: rem(16);
    min-width: rem(320);
    max-width: fit-content;
    cursor: default;

    &-body {
      position: relative;
      width: rem(320);
    }

    &-body-chat {
      width: rem(686);
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-doc-id-group {
      width: rem(210);
      margin-right: rem(20);

      .doc-id-list {
        height: rem(304);
        overflow-x: hidden;
        overflow-y: auto;
        @include scroll-flexible;

        &-item {
          display: flex;
          align-items: center;
          width: rem(205);
          height: rem(32);
          margin-bottom: rem(8);
          border-radius: rem(4);
          padding: 0 rem(10);
          overflow: hidden;

          &:hover {
            cursor: pointer;
          }

          &__active {
            background-color: $col_gray-4;
          }

          .icon-type,
          .icon-type-chat {
            @include flex-center;
          }

          .icon-type {
            width: rem(20);
            height: rem(20);
            background-color: $col-white;
            border-radius: rem(3);

            img {
              width: rem(14);
              height: rem(14);
            }
          }

          .icon-type-chat img {
            width: rem(16);
            height: rem(16);
          }

          p {
            font-weight: $fw_4;
            font-size: rem(13);
            margin-left: rem(6);
            @include text-ellipsis;
          }
        }
      }
    }

    &-content {
      display: flex;
    }

    &-detail {
      min-width: rem(300);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-chat {
      margin-left: rem(16);
      border: rem(1) solid $col_gray-800;
      border-radius: rem(8);
      padding: rem(16);
      min-width: rem(370);
      width: 100%;
      height: rem(304);
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-flexible;

      .conversation-action {
        font-style: italic;
        font-size: rem(12);
        width: 100%;
        padding: rem(4) 0;
        @include flex-between;
  
        .content {
          color: $col_blue;
        }
        .leave {
          color: $col_red-4;
        }
        .date {
          color: $col_gray;
          padding: 0 rem(10);
          font-size: rem(11);
        }
      }

      li {
        + li {
          margin-top: rem(16);
        }

        background-color: $col_gray-50;
        padding: rem(16);
        border-radius: rem(8);
        width: fit-content;
        margin-left: auto;

        &.des-chat-left {
          margin-left: unset;
          margin-right: auto;
        }
      }

      &-header {
        font-size: rem(12);
        display: flex;
        color: $col_gray;

        span {
          color: $col_gray-600;
          font-weight: $fw_7;
        }

        margin-bottom: rem(12);
      }

      &-body {
        font-size: rem(14);
        color: $col_gray;
        max-width: rem(256);
      }
    }

    &-label {
      font-size: rem(18);
      margin-bottom: rem(16);
      margin-top: rem(2);
      font-weight: $fw_7;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      font-size: rem(13);
      margin-bottom: rem(12);

      &-icon {
        width: rem(24);
        padding: rem(4);
        background-color: $col_gray-50;
        margin-right: rem(4);
        border-radius: rem(3);
      }

      &-title {
        color: $col_gray-400;
        white-space: nowrap;
      }

      &-value {
        margin-left: rem(8);
        color: $col_gray-600;
        font-weight: $fw_7;
        text-align: end;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-sub {
        font-weight: $fw_4;
        font-size: rem(12);
        color: $col_gray;
      }

      &-img {
        width: rem(24);
        height: rem(24);
        border-radius: 50%;
      }
    }

    &-participant {
      z-index: 9;

      &-avatar {
        margin-left: rem(-8);
        position: relative;

        img {
          width: rem(24);
          height: rem(24);
          object-fit: cover;
          border-radius: 50%;
          border: rem(1) solid $col-gray-50;

          &:hover + .des-participant-hover {
            display: block;
          }
        }
      }

      &-hover,
      .des-participants-more {
        display: none;
        position: absolute;
        top: calc(100% + rem(8));
        right: 0;
        border-radius: rem(4);
        box-shadow: $box_shadow;
        padding: rem(8);
        white-space: nowrap;
        background-color: $col-white;
        color: $col_gray-600;
        font-size: rem(14);
        max-height: rem(184);
        overflow-y: auto;
        @include scroll-flexible;

        img {
          margin-right: rem(8);
        }
      }

      &-more {
        width: rem(20);
        height: rem(20);
        background-color: $col-blue;
        border-radius: 50%;
        color: $col-white;
        font-size: rem(11);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: rem(-8);

        &:hover .des-participants-more {
          display: block;
        }
      }

      &-name {
        font-weight: $fw_7;
        margin-left: rem(8);
      }
    }
  }

  &:nth-child(4n) {
    .des {
      left: unset;
      right: 0;
    }
  }
}

.chat-container {
  width: inherit;
  height: inherit;
}

.email-container {
  width: inherit;
  height: rem(350);
}

.media-container {
  height: rem(290);
}

.audio-container {
  height: rem(182);
}

.note-container {
  height: rem(290);
}

@media print {
  .container {
    width: 100%;
  }
}
