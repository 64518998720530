@import "assets/css/base";

.user-administration-page {
  padding-top: rem(40) !important;
  min-height: calc(100vh - rem(96));

  .user-administration-header {
    font-size: rem(32);
    line-height: rem(40);
    margin-right: rem(12);
    color: $col_gray-600;
  }

  .user-administration-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: rem(681);
    background: $col_white;
    box-shadow: $box_shadow_icon;
    border-radius: rem(8);
    padding: rem(24) rem(24);
    margin-top: rem(24);
    margin-bottom: rem(72);
    position: relative;

    .count-user {
      @include flex-start;

      span {
        margin: rem(10) 0;
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(24);
        color: $col_gray;
      }
    }

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .no-result {
      margin: auto;
    }

    .table-user-administration {
      border: rem(1) solid $col_gray-800;
      border-radius: rem(8);
      margin-top: rem(16);
      flex: 1;
      position: relative;
      box-sizing: border-box;
      border-collapse: unset;
      border-spacing: inherit;

      table {
        width: 100%;
        height: auto;
        table-layout: fixed;

        thead,
        tbody tr {
          width: 100%;
        }

        thead {
          background: $col_gray-50;
          height: rem(48);
          border-bottom: rem(1) solid $col_gray-800;
          overflow: hidden;

          th {
            text-align: left;
            font-weight: $fw_7;
            font-size: rem(12);
            line-height: rem(16);
            color: $col_gray-400;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:first-child {
              border-top-left-radius: rem(8);
            }

            &:last-child {
              border-top-right-radius: rem(8);
            }

            a {
              padding-right: rem(14);
              position: relative;
              white-space: nowrap;
              cursor: pointer;
              color: $col_gray-400;

              &::after {
                content: "";
                position: absolute;
                right: 0;
                top: rem(5);
                width: 0;
                height: 0;
                border-left: rem(4) solid transparent;
                border-right: rem(4) solid transparent;
                border-top: rem(6) solid $col_gray-600;
                clear: both;
              }
            }

            :global(a.sort-asc::after) {
              content: "";
              border-top: none;
              border-bottom: rem(6) solid $col_gray-600;
            }

            :global(a.sort-desc::after) {
              content: "";
              border-top: rem(6) solid $col_gray-600;
              border-bottom: none;
            }
          }
        }

        tbody {
          tr {
            text-align: left;
            font-weight: $fw_4;
            font-size: rem(12);
            line-height: rem(16);
            height: rem(48);

            td {
              color: $col_gray-600;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              .action {
                display: inline-block;
                cursor: pointer;
                color: $col_blue;
                margin-right: rem(16);
                font-weight: $fw_5;
              }

              .status-pending {
                background: rgba($col_begonia, 0.15);
                border: rem(1) solid $col_begonia;
                border-radius: rem(4);
                padding: rem(8) rem(12);
                cursor: default;
              }

              .status-activated {
                background: rgba($col_polished_pine, 0.15);
                border: rem(1) solid $col_polished_pine;
                border-radius: rem(4);
                padding: rem(8) rem(12);
                cursor: default;
              }
            }

            .td-project {
              overflow: unset;

              .assigned-project {
                display: inline-block;
                position: relative;

                &:hover {
                  .project-description {
                    display: block;
                  }

                  &::after {
                    display: block;
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: rem(10);
                  }
                  &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: rem(10);
                    top: rem(-8);
                  }
                }

                span {
                  background: $col_gray-300;
                  border-radius: rem(4);
                  padding: rem(3) rem(12);
                  cursor: default;
                  display: block;
                }

                .project-description {
                  display: none;
                  position: absolute;
                  top: 100%;
                  right: 0;
                  min-width: rem(250);
                  max-height: rem(200);
                  overflow-y: auto;
                  background: rgba(34, 34, 34, 0.8);
                  box-shadow: $box_shadow;
                  border-radius: rem(4);
                  padding: rem(20) rem(16);
                  margin-top: rem(8);
                  z-index: 1000;
                  @include scroll-flexible;

                  &-rev {
                    top: unset;
                    bottom: 100%;
                    margin-top: 0;
                    margin-bottom: rem(8);
                  }

                  p {
                    color: $col_white;
                    font-weight: $fw_4;
                    font-size: rem(14);
                    line-height: rem(20);
                    margin-top: rem(16);

                    &:first-child {
                      margin-top: 0;
                    }
                  }
                }
              }
            }

            &:last-child {
              td:first-child {
                border-bottom-left-radius: rem(8);
              }

              td:last-child {
                border-bottom-right-radius: rem(8);
              }
            }
          }

          tr:hover {
            background: $color_lavender;
          }
        }

        th,
        td {
          padding-left: rem(10);

          &:first-child {
            width: 15%;
            padding-left: rem(32);
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 12%;
          }

          &:nth-child(4) {
            width: 16%;
          }

          &:nth-child(7) {
            width: 7%;
          }

          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(8) {
            width: 10%;
          }
        }
      }
    }
  }
}
