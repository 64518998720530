@import "assets/css/_base.scss";

.container {
  border: rem(1) solid $col_blue;
  border-radius: rem(4);
  margin: rem(16) 0;
  min-height: rem(80);

  .content {
    margin: rem(12);

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: $fw_7;
        font-size: rem(14);
        color: $col_gray-600;
      }

      .title-active {
        color: $col_gray-600;
      }

      .btn-radio {
        width: rem(16);
        height: rem(16);
        border: rem(1) solid $col_gray-800;

        &:checked {
          background-color: $col_blue;
          border: rem(1) solid $col_blue;
        }
      }
    }

    .query-container {
      margin-top: rem(10);

      .query {
        font-weight: $fw_4;
        font-size: rem(14);
      }

      .query-active {
        color: $col_gray-600;
      }
    }
  }
}

.disable {
  border: rem(1) solid $col_gray-300 !important;
  background-color: rgba(217, 217, 217, 0.15) !important;
  cursor: pointer;
}

.source {
  background-color: rgba(21, 128, 239, 0.15);
  border-color: $col_blue;
}

.entity {
  background-color: rgba(93, 98, 181, 0.15);
  border-color: $col_liberty;
}

.datatype {
  background-color: rgba(41, 195, 190, 0.15);
  border-color: $col_blue_green;
}

.date {
  background-color: rgba(242, 114, 111, 0.15);
  border-color: $col_begonia;
}

.global {
  background-color: rgba(103, 205, 242, 0.15);
  border-color: $col_maya_blue;
}
