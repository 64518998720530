@import "assets/css/base.scss";

.form-action {
  position: absolute;
  right: 0;
  top: calc(100% + rem(4));
  z-index: 9;
  display: flex;

  span {
    cursor: pointer;
    width: rem(16);
    height: rem(16);
    font-size: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
  span:last-child {
    margin-left: rem(4);
  }
}

.actions {
  @include flex-end;
  z-index: 0;

  span {
    cursor: pointer;
    width: rem(16);
    height: rem(16);
    font-size: 0;
    margin-left: rem(6);
  }
}
