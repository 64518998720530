@import "assets/css/_base.scss";

.wrap {
  border-radius: rem(5);
  background: $col_white;
  box-shadow: $box_shadow_icon;
  padding: rem(16);
}

.review-wrap {
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  position: relative;

  .item-count-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9e9e9;
    color: black;
    border-radius: rem(8) rem(8) 0 0;
    padding: rem(5);
    font-size: rem(14);
    margin-top: rem(10);
    position: relative;

    &-active {
        background-color: $col_blue !important;
        color: white !important;
    }

    .banner-count {
        flex: 1;
        text-align: center;
    }

    .banner-checkbox {
        position: absolute; 
        right: 12px;
        margin: 0;

        &-active {
          right: 17px;

          .form-check-input:checked {
            border: rem(1) solid #ccc !important;
            border-color: white !important;
          }
        }
    }
}

  .review-wrap-card-body {
    background-color: $col_white;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    margin-top: rem(10);
    border: rem(1) solid $col_gray-800;
    box-shadow: none !important;
    border-radius: rem(8);
    height: 100%;
    padding: rem(10);
    @include scroll-flexible;

    &-banner {
      margin-top: unset !important;
      border-top: none !important;
      border-radius: 0 0 rem(8) rem(8) !important;
    }

    .conversation-action {
      font-style: italic;
      font-size: rem(14);
      width: 100%;
      padding: rem(4) 0;
      @include flex-between;
      margin-left: rem(5);

      .card-thumb {
        height: rem(18);
      }

      .content {
        color: $col_blue;
      }
      .leave {
        color: $col_red-4;
      }
      .date {
        color: $col_gray;
        padding: 0 rem(10);
        font-size: rem(12);
      }
    }
  }

  .divider {
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .divider-line {
    margin-top: 4rem;
    margin-bottom: 2rem;
    border-top: 1px solid #8a8a8a;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .divider-text {
    font-weight: $fw_5;
    font-size: rem(14);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff; 
    padding: 0 10px; 
    color: #8a8a8a;
  }

  .hidden-messages-container {
    display: flex;
    justify-content: center;
  }

  .hidden-messages-text {
    font-size: rem(12);
    color: rgba(0, 0, 0, 0.9);
  }

  .search-message-container {
    position: relative;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    border: rem(1) solid $col_gray-300;
    border-radius: rem(8);
    width: 100.7%;
    padding: 1rem;
  }

  .top-accordion-container {
    display: flex;
    justify-content: center;
    height: 3.5rem;
    padding-right: 7.7rem;
  }

  .bottom-accordion-container {
    display: flex;
    justify-content: center;
    height: 3.5rem;
    padding-right: 7.7rem;
    padding-top: 2.1rem;
  }

  .messages-items {
    display: flex;
    padding: rem(8) 0;

    &.messages-others {
      justify-content: space-between;
      .card-thumb {
        margin-left: rem(5);
      }
    }

    &.messages-participant {
      .card-thumb {
        margin-right: rem(5);
        order: -1;

        .tag-thumb {
          flex-wrap: wrap-reverse;
        }
      }
    }
  }

  .card-message {
    max-width: 80%;
    display: flex;
  }

  .chat-select {
    display: block;
    min-height: rem(15);
    padding-left: rem(15);
    margin-bottom: rem(1.25);
    margin-left: rem(10);
    width: rem(13);

    .form-check-input {
      width: rem(13);
      height: rem(13);
      margin-top: 0.25em;
      vertical-align: top;
      background-color: $col_white;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: rem(1) solid rgba(0, 0, 0, 0.25);
      border-radius: 0.25em;
      float: left;
      margin-left: -1.5em;
      box-sizing: border-box;
      padding: 0;
      outline: none;
      cursor: pointer;
    }
  }

  .bg-avatar {
    border-radius: 50%;
    padding: rem(5);
    margin-right: rem(10);
    height: rem(25);
    width: rem(25);
  }

  .person-chat {
    background-color: $col_gray-50;
    box-shadow: none !important;
    border-radius: rem(8) !important;
    border: none;
    flex: 1 1;
    font-size: rem(12);
    padding: rem(15) !important;
    position: relative;
    overflow: hidden;

    .fw-bold {
      display: block;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name-dot {
      width: rem(3);
      height: rem(3);
      background-color: $col_black;
      margin: rem(6) rem(7.5);
      display: inline-block;
      border-radius: 50%;
    }

    .link-message,
    .attachment-decoration {
      color: $col_blue;
      cursor: pointer;
    }

    .attachment-decoration {
      text-decoration: underline;
      font-weight: $fw_7;
    }

    .LowerContainer {
      display: none;
    }

    .disable-events {
      pointer-events: none;
    }

    .chat-time {
      color: $col_gray;
      margin-bottom: rem(5);
    }

    .chat-content {
      font-size: rem(14);
      white-space: pre-wrap;

      button {
        border: none;
        background: none;
        cursor: pointer;
      }

      img {
        max-width: rem(250);
        cursor: pointer;
      }
    }
    .attachment-not-available {
      display: flex;

      img {
        width: rem(16);
        height: auto;
      }

      p {
        font-style: italic;
        margin-left: rem(4);
      }
    }
  }

  .card-thumb {
    width: calc(25% + rem(10));
    position: relative;
    flex: 1;
  }

  .tag-thumb {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    flex-wrap: wrap;
    bottom: rem(-3);
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    align-content: flex-start;
    gap: 0 rem(4);
    @include scroll-horizontal;
  }
}

/* --- Start Add Preview Result Container */
.is-result-preview-head {
  @include flex-between;
  width: 100%;

  .viewer-control {
    display: flex;
    align-items: center;
    height: rem(28);
  }

  .is-result-preview-select {
    display: flex;
    align-items: center;

    .is-result-preview-title {
      font-weight: $fw_5;
      font-size: rem(18);
      color: $col_gray-600;
      padding-right: rem(16);
      display: flex;
      align-items: center;
      position: relative;

      div {
        position: relative;
        margin-top: rem(8);
      }

      .source-icon img {
        width: rem(20);
        height: rem(20);
        margin-right: rem(8);
      }
    }

    &-filter {
      .search-wrapper .btn-input {
        height: rem(28);
        width: vw(187);
        font-size: rem(14);
        padding-right: rem(25);
      }

      .icon-search,
      .icon-clear {
        width: rem(16);
        height: rem(16);
      }
    }
  }
  .is-result-preview-search {
    input {
      height: rem(28) !important;
    }
  }
}

.message-detail {
  width: 100%;
  height: 100%;
  min-height: 96.4%;

  .message-body {
    height: 100%;

    .loading-preview {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// more styles
.label-title {
  font-size: rem(16);
}
.add-hotkey {
  font-size: rem(16);
  color: $col_blue;
  font-weight: $fw_5;
  cursor: pointer;
}

.btn {
  font-size: rem(14);
  border-radius: rem(4);
  border-width: rem(1);
  background-color: $col_white;

  &::after {
    content: none;
  }
}

.dropdown-item {
  width: auto;
  padding: 0;
  margin-right: rem(16);
}

.btn-select {
  width: rem(73);
  height: rem(32);
  font-weight: $fw_5;
  border: solid rem(1) $col_blue;
  color: $col_blue;

  &:hover {
    background-color: unset;
    color: $col_blue;
  }
}

.btn-select.disable {
  height: rem(32);
  background-color: $col_white !important;
  border: rem(1) solid $col_gray-700 !important;
  font-weight: $fw_5;
  color: $col_gray !important;
  margin-right: rem(16);
  font-size: rem(14) !important;
}

.btn-select-cancel {
  height: rem(32);
  font-weight: $fw_5;
  color: $col_white;
  border-color: $col_blue !important;
  background-color: $col_blue !important;
  margin-right: rem(16);
}

.btn-select-cancel-pop-up {
  height: rem(32);
  font-weight: $fw_5;
  color: $col_blue;
  border-color: $col_blue;
  background-color: $col_white;
  border: rem(1) solid $col_blue;
  min-width: rem(64);
  @include text-center;
}

.btn-select-save {
  height: rem(32);
  color: $col_gray;
  background-color: $col_gray-300;
  border: rem(1) solid $col_gray-300;
  min-width: rem(64);
  @include text-center;
}

.btn-select-save.active {
  color: $col_white;
  background-color: $col_blue;
}

.btn-select-tagcancel {
  &:hover {
    background-color: unset;
  }
}

.btn-tag-selected {
  margin-bottom: rem(10);

  .btn-outline-primary {
    border-radius: rem(4);
  }
}

.btn-select-tag,
.btn-select-untag {
  height: rem(32);
  background-color: $col_white !important;
  border: rem(1) solid $col_gray-700 !important;
  color: $col_gray !important;
  margin-right: rem(16);
  font-size: rem(14) !important;

  &::after {
    content: none !important;
  }
}

.btn-select-tag {
  font-weight: $fw_5;
}

.btn-select-tag.active,
.btn-select-untag.active {
  background-color: $col_white !important;
  border: rem(1) solid $col_blue !important;
  color: $col_blue !important;
}

.btn-select-tagall {
  height: rem(32);
  background-color: $col_white;
  border: rem(1) solid $col_gray-700;
  font-weight: $fw_5;
  color: $col_gray;
}

.btn-select-tagall.active {
  border: rem(1) solid $col_blue;
  color: $col_blue;
}

.btn-select-tag.active {
  background-color: unset;
  border: none;
}

.select-tag {
  display: flex;
  justify-content: left;
  width: 100%;
  margin-left: rem(11);
}

.select-tag-items {
  display: flex;

  .dropdown-menu-select-item p {
    margin-right: rem(4);
  }
}

.btn-change-message {
  display: flex;
  font-size: 0;
  justify-content: flex-end;
  flex: 1;

  .btn {
    width: rem(32);
    height: rem(32);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: unset !important;
    }
  }

  :global(.btn-common button) {
    padding: 0;
    height: rem(32);
    width: rem(32);
    margin-left: rem(9);
  }
}

.btn-message-prev,
.btn-message-next {
  background-color: red;
  border: rem(1) solid $col_blue;
  height: rem(32);
  width: rem(32);
  padding: 0;
  border-radius: rem(4);

  &:hover {
    background-color: unset;
  }
}

.dropdown-menu {
  min-width: rem(240) !important;
  max-width: rem(360);
  box-shadow: $box_shadow;
  border-radius: rem(4);
  border: none;
  padding-bottom: 0;
  max-height: rem(556);

  @include scroll-flexible;

  &-select {
    margin: 0 rem(16) rem(16);

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: rem(5);
      padding-bottom: rem(5);
    }

    span {
      width: rem(16);
      height: rem(16);
      display: block;
      border-radius: rem(2);
      margin-right: rem(10);
    }

    p {
      flex: 1;
      font-size: rem(14);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: rem(8);
    }
  }

  &-action {
    border-top: rem(1) solid rgba($col_gray-700, 0.5);

    a {
      width: auto;
      padding: 0;
      margin-right: rem(16);

      button {
        width: rem(84);
        height: rem(32);
      }
    }
  }
}
.dropdown-tag-tab {
  min-width: rem(324) !important;
}

.form-check-input{
  appearance: none;
  width: rem(14) !important;
  height: rem(14) !important;
  border: rem(1) solid #ccc !important;
  border-radius: rem(3) !important;
  position: relative !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  display: inline-flex !important;
  align-items: center !important;
}

.form-check-input:checked {
  background-color: #1580EF !important;
  border-color: #1580EF !important;
}

.form-check-input:checked::before {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 8px;
}

.form-check-input:hover {
  background-color: #f6f6f6 !important;
  border-color: #ccc !important;
}

/* --- End Add Preview Result Container */

@media print {
  .is-result-preview-search {
    input {
      width: rem(120) !important;
    }
  }
}