@import "assets/css/base";
.filter-item {
  margin-bottom: rem(16);
  .label {
    color: $col_gray-600;
    font-weight: fw-5;
    font-size: rem(12);
    margin-bottom: rem(6);
  }
  .data-select {
    position: relative;
    font-weight: fw-4;
    font-size: rem(14);
    color: $col_gray-600;
    .data-list {
      position: absolute;
      top: rem(50);
      background: $col-white;
      box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.12);
      border-radius: rem(8);
      padding: rem(12);
      overflow: hidden;
      width: 100%;
      z-index: 999;
    }

    .show-data {
      display: block;
    }
  }
}
