@import "assets/css/_base.scss";

.view-detail {
  position: relative;

  .more-icon {
    border-radius: rem(2);
    width: rem(14);
    height: rem(14);
    background: $col_white;
    border: rem(1) solid $col_blue;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-list {
    padding: rem(24);
    position: fixed;
    background: $col_white;
    box-shadow: $box_shadow;
    border-radius: rem(8);
    width: rem(540);
    max-height: rem(340);
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-flexible;
    z-index: 9999;
    @include flex-between;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 6%;

    &-item {
      flex: 47%;
      display: flex;
      align-items: center;
      font-size: rem(14);
      font-weight: $fw_5;
      line-height: rem(20);
      margin-bottom: rem(16);
      position: relative;
      @include text-ellipsis;

      .app-name {
        @include text-ellipsis;
      }

      .source-icon {
        width: rem(24);
        height: rem(24);
        margin-right: rem(10);
      }
    }
  }
}