@import "assets/css/_base.scss";

.export-preview {
  height: rem(850);

  h3 {
    font-weight: $fw_5;
    font-size: rem(20);
    line-height: rem(32);
    color: $col_gray-600;
  }

  &-content {
    .export-preview-name {
      font-size: rem(24);
      line-height: rem(32);
      font-weight: $fw_5;
      color: $col_gray-600;
      margin: rem(24) 0;
    }

    .export-summary {
      position: relative;
      min-height: rem(120);

      &-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: rem(15);

        .spinner-border {
          display: inline-block;
        }
      }

      &-title {
        font-size: rem(16);
        line-height: rem(24);
        font-weight: $fw_7;
        color: $col_blue;
        margin-bottom: rem(12);
      }

      &-info {
        &-item {
          @include flex-between;

          span {
            font-size: rem(16);
            line-height: rem(24);
            font-weight: $fw_4;
            color: $col_gray-600;

            &:first-child {
              width: 60%;
              text-align: left;
            }

            &:last-child {
              width: 40%;
              text-align: right;
            }
          }
        }

        &:last-child {
          margin-bottom: rem(24);

          span {
            font-weight: $fw_5;
          }
        }
      }
      .note-info {
        font-weight: $fw_5;
        font-size: rem(12);
        color: $col_gray;
      }

      hr {
        border-top: rem(1) solid $col_gray-300;
      }
    }

    .export-config {
      padding-bottom: rem(8);

      &-title {
        font-size: rem(16);
        line-height: rem(24);
        font-weight: $fw_7;
        color: $col_blue;
        margin-bottom: rem(12);
      }

      &-label {
        font-size: rem(12);
        line-height: rem(16);
        font-weight: $fw_5;
        color: $col_gray-600;
        margin-bottom: rem(8);
      }

      &-value {
        font-size: rem(16);
        line-height: rem(24);
        font-weight: $fw_4;
        color: $col_gray-600;
        margin-bottom: rem(16);
        white-space: initial;
      }

      .through-text {
        text-decoration-line: line-through;
      }

      &-list {
        &-item {
          display: flex;
          align-items: flex-start;
          white-space: pre-wrap;

          img {
            margin-right: rem(8);
            width: rem(12);
            margin-top: rem(6);
          }
        }
      }
    }
  }
}

@media print {
  .export-preview {
    height: auto;
    h3 {
      font-size: rem(18);
    }
    &-content {
      .export-preview-name {
        font-size: rem(22);
      }
      .export-summary {
        &-title {
          font-size: rem(14);
        }
        &-info {
          &-item {
            span {
              font-size: rem(14);
            }
          }
        }
      }
      .export-config {
        &-title {
          font-size: rem(14);
        }
        &-label {
          font-size: rem(10);
        }
        &-value {
          font-size: rem(14);
        }
      }
    }
  }
}
