@import "assets/css/base";

.error-result {
  height: 100vh;
  width: rem(1024);
  height: rem(667);
  background: $col_gray-70;
  border-radius: rem(100);
  margin: rem(40) auto;

  .error-result-body {
    width: rem(500);
    margin: 0 auto;

    &__img {
      padding: rem(90) 0 rem(76);
      @include text-center;
    }

    &__title {
      font-weight: $fw_5;
      font-size: rem(32);
      color: $col_gray-600;
      margin-top: 0;
      margin-bottom: rem(16);
    }

    &__content {
      font-weight: $fw_4;
      font-size: rem(16);
      color: $col_gray;
    }

    &__link {
      display: block;
      font-weight: $fw_4;
      font-size: rem(16);
      line-height: rem(20);
      color: $col_blue;
      margin-top: rem(16);
    }
  }
}

.page-error {
  display: none !important;
}
