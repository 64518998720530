@import "assets/css/base";

.project-items_users {
  display: flex;
  justify-content: right;

  .project-detail-members_item {
    display: inline-block;
    font-size: 0;
    position: relative;

    &:hover {
      cursor: pointer;

      .dropdown-menu {
        display: block;
        z-index: 1000;
      }

      &::after,
      &::before {
        position: absolute;
        content: "";
        width: rem(50);
        height: rem(10);
        top: 100%;
        right: 0;
      }

      &::before {
        top: unset;
        bottom: 100%;
      }
    }

    .dropdown-menu {
      li {
        display: flex;
        align-items: center;
        font-size: rem(16);
        padding: rem(5) 0;
        flex-direction: row;

        figure {
          margin-right: rem(5);
          width: rem(31);
          height: rem(31);
        }

        .user-name {
          color: $col_black;
          font-size: rem(12);
          text-align: left;
          white-space: pre-wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical;
        }
      }
      &-show-bottom {
        bottom: unset !important;
        left: rem(5);
      }
    }

    .dropdown-menu-info {
      width: rem(220) !important;
      margin-left: rem(-190) !important;
    }

    figure img {
      width: rem(24);
      height: rem(24);
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .avatar-count {
    width: rem(24);
    height: rem(24);
    border-radius: 50%;
    @include text-center;
    background: $col_blue;
    margin-left: rem(-8);

    .dropdown-menu.dropdown-menu-info {
      max-height: rem(340);
      @include scroll-flexible;
      overflow-x: hidden;
      overflow-y: auto;
    }

    p {
      font-size: rem(12);
      font-weight: $fw_4;
      width: 100%;
      color: $col_white;
      @include text-center;
      line-height: rem(24);
    }
  }
  &_md {
    a {
      width: rem(31) !important;
      height: rem(31) !important;
    }

    li p {
      font-weight: $fw_4;
      font-size: rem(15) !important;
    }

    .dropdown-menu {
      li {
        display: flex;
        align-items: center;
        a {
          margin-right: rem(5);
          width: rem(26) !important;
          height: rem(26) !important;
        }

        .user-name {
          margin-left: rem(4);
        }
      }
    }

    .avatar-count {
      width: rem(31);
      height: rem(31);
      @include flex-center;
    }
  }
}

.project-detail-members_item {
  display: inline-block;
  font-size: 0;
  position: relative;

  a {
    width: rem(24);
    height: rem(24);
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    transform-origin: center;
    margin-left: rem(-8);
    transition: all 0.3s 0s ease;
    border: rem(1) solid $col_gray-50;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  .dropdown-menu {
    width: rem(282);
    padding: rem(10) rem(16);
    background: $col_white;
    box-shadow: $box_shadow;
    border-radius: rem(8);
    margin-top: 0;
    position: absolute;
    bottom: calc(100% + rem(8));
    left: 0;
    display: none;

    li {
      display: flex;
      align-items: center;
      figure {
        width: rem(24) !important;
        height: rem(24) !important;
        position: relative;

        a {
          margin-left: 0;
        }
      }
    }
  }
}
