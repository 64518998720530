@import "assets/css/base.scss";

.form-group {
  position: relative;

  .form-error {
    position: absolute;
    top: calc(100% + rem(4));
    left: 0;
  }

  .form-label {
    font-weight: $fw_7;
    font-size: rem(14);
    line-height: rem(20);
    color: $col_gray-600;
    margin-bottom: rem(6);
    @include flex-between;

    .count-word {
      font-weight: $fw_4;
      font-size: rem(12);
      line-height: rem(16);
      color: $col_gray;
    }
  }
  
  .form-text {
    margin-bottom: rem(24);
    height: rem(48);
  }
  
  .form-textarea {
    min-height: rem(90);
    position: relative;
    @include scroll-flexible;
  }
}

