@import "assets/css/base.scss";

.form-control {
  position: relative;
  @include flex-between;
  width: 100%;

  &-input {
    height: rem(28);
    width: 100%;
    font-size: rem(12);
    line-height: rem(20);
    color: $col_gray-600;
    padding: rem(4) rem(8);
  }

  &-label {
    font-size: rem(12);
    line-height: rem(16);
    color: $col_black;
    width: 90%;
    @include text-ellipsis;

    img {
      cursor: pointer;
    }
  }
  
  :global(.btn-input) {
    width: 100%;
    font-size: rem(12);
    padding: rem(4) rem(8);
    color: $col_gray-600;
    line-height: rem(20);
  }
}
