@import "assets/css/_base.scss";

.wrap {
  display: flex;
  flex-direction: column;
  border-bottom: none;
  height: calc(100% - 10px);
  overflow-y: auto;
  @include scroll-flexible;

  .empty-page {
    width: 100%;
    @include text-center;
    color: $col_gray-400;
    padding-bottom: rem(5);
    font-size: rem(14);
  }

  .data-source-list {
    padding: rem(5) rem(12) 0;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: rem(12);
      padding: 0;

      .data-source-icon {
        width: rem(20);
        height: rem(20);
        margin-right: rem(4);
      }

      .data-source-item {
        width: 90%;
        p {
          font-size: rem(14);
          white-space: pre-wrap;
        }

        span {
          font-size: rem(12);
          color: $col_gray;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .spinner-container {
    width: 100%;
    height: rem(20);
    position: relative;
  }
}

.table-view {
  margin-top: rem(10);
  border-right: rem(1) solid $col_gray-300;

  &-label {
    margin-right: rem(16);
  }

  .data-source-list {
    height: rem(165);
    margin: rem(10) rem(16) 0 0;

    li {
      flex: 1;
      .data-source-item {
        width: 90%;
      }
    }
  }

  .label-title {
    margin: 0;
  }

  .spinner-container {
    height: rem(100);
  }
}