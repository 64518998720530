@import "assets/css/_base.scss";

.container {
  display: flex;
  flex-direction: column;
  @include text-center;
  height: calc(100% - 10px);
  overflow-y: auto;
  @include scroll-flexible;

  .word-cloud-list {
    padding: 0 rem(12) 0;
  }

  .expand-icon {
    transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }

  .word {
    display: inline-block;
    line-height: 1.2;
    vertical-align: middle;
    margin: 0 rem(6);
    word-break: break-word;
    cursor: pointer;
  }

  .spinner-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container-table-view {
  margin: rem(10) 0 0;
  padding: 0 rem(16);
  border-right: rem(1) solid $col_gray-300;

  .word-cloud-list {
    max-height: rem(165);
    overflow-y: auto;
    @include scroll-flexible;
  }
}

.no-result {
  font-size: rem(14);
  padding-bottom: rem(5);
  color: $col_gray-400;
}

@media print {
  .container {
    .word-cloud-list {
      height: rem(300);
      overflow-y: hidden;
    }
  }
}
