@import "assets/css/base.scss";

.wrap {
  h5 {
    font-weight: $fw_7;
    font-size: rem(14);
    line-height: rem(20);
    color: $col_gray-600;
    margin-bottom: rem(6);
  }

  .associated-identifier {
    width: 100%;
    height: rem(384);
    background-color: $bg_color;
    padding: rem(16);
    border-radius: rem(8);

    &-info {
      max-height: rem(272);
      overflow-x: hidden;
      overflow-y: auto;
      @include scroll-flexible;
      margin-bottom: rem(16);
      
      li {
        display: flex;
        align-items: center;
        margin-bottom: rem(20);
        margin-right: rem(12);

        &:last-child {
          margin-bottom: rem(20);
        }

        .source-icon {
          width: rem(20);
          height: rem(20);
          margin-right: rem(10);
        }

        input {
          width: 100%;
          height: rem(28);
          font-size: rem(14);
        }

        .associated-form {
          > span {
            font-size: rem(14);
            line-height: rem(20);
            color: $col_gray-600;
            width: 100%;
            width: rem(310);
            white-space: pre;
          }
        }
        
        .associated-form-error {
          > span {
            color: $col_red;
          }
        }
      }
    }

    .add-more-row {
      display: inline-flex;
      align-items: center;
      padding-left: rem(24);
      color: $col_blue-10;
      font-size: rem(12);
      line-height: rem(16);
      font-weight: $fw_4;
      background: url("~/public/images/add-ic.svg") left center/rem(16)
        no-repeat;
      cursor: pointer;
    }

    .add-new-associated {
      position: relative;
      margin-bottom: rem(24);

      span {
        display: flex;
        align-items: center;
        .source-icon {
          width: rem(20);
          height: rem(20);
          margin-right: rem(10);
        }

        input {
          width: 100%;
          height: rem(28);
          font-size: rem(14);
        }
      }
    }

    .social-list {
      background-color: $col_white;
      box-shadow: $box_shadow_icon;
      width: rem(280);
      height: rem(310);
      padding: rem(12);
      border-radius: rem(6);
      margin-top: rem(8);
      overflow-x: hidden;
      overflow-y: auto;
      @include scroll-flexible;

      li {
        display: flex;
        align-items: center;
        padding: rem(8);

        &:hover {
          background-color: $col_blue-15;
          cursor: pointer;
        }

        img {
          margin-right: rem(8);
          width: rem(20);
          height: rem(20);
        }

        span {
          font-weight: $fw_4;
          font-size: rem(16);
          line-height: rem(24);
          color: $col_gray-600;
          @include text-ellipsis;
        }
      }
    }
  }
}
