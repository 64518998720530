@import "assets/css/_base.scss";

.matter-avatar-container {
    display: flex;
    align-items: center;
    gap: rem(8);
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}