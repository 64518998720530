@import "assets/css/base";

.rdrNoSelection {
  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: #fff;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: $col-black;

    &:after {
      background: $col-blue;
    }
  }
}

.date-picker-control {
  .modal-dialog {
    width: fit-content;
    max-width: unset;
    transform: translate(0, -50%) !important;
    top: 50%;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    a {
      background-color: $col_white;
      color: $col_black;
      padding: rem(30) rem(30);
      font-size: rem(14);
    }
  }

  .body-picker {
    min-width: rem(350);
    align-self: center;
  }

  .btn-close {
    border: rem(1) solid $col_gray-600;
    border-radius: rem(4);
  }
  .rdrDateDisplay {
    display: none;
  }

  &__body {
    padding: rem(12) 0 rem(14) 0 !important;
    .rdrNextPrevButton {
      border-radius: 50%;
      margin: 0 rem(20);
      i {
        border: none;
        width: rem(7);
        height: rem(10);
        transform: unset;
        margin: auto !important;
      }
    }

    .error {
      @include text-center;
      color: $col_red;
      height: rem(10);
    }

    .rdrPprevButton i {
      background: url("~/public/images/left-arrow.svg") center no-repeat;
    }
    .rdrNextButton i {
      background: url("~/public/images/right-arrow.svg") center no-repeat;
    }

    .rdrMonthPicker select {
      background: url("~/public/images/down-arrow-2.svg") 90% no-repeat;
    }

    .rdrYearPicker select {
      background: url("~/public/images/down-arrow-2.svg") 90% no-repeat;
    }

    .rdrCalendarWrapper {
      width: 100%;
      align-items: center;
    }
  }

  .date-picker-control__footer {
    display: block;
    font-size: rem(14);

    .date_to_title {
      color: $col_gray-300;
    }

    .btn_filter_date {
      background: $col_blue;
      color: $col_white;
      height: rem(32);
      padding: 0 rem(10);
      margin: 0 0 0 rem(10);
    }

    .btn_clear_filter_date {
      background: $col_white;
      color: $col_blue;
      height: rem(32);
      padding: 0 rem(10);
    }

    .select-date-container {
      display: flex;
      align-items: center;
      &__title {
        font-weight: bold;
        color: $col_blue;
        margin-right: rem(5);
      }
    }

    .group-btn_clear_submit {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: rem(18);

      .btn_group {
        display: flex;
        gap: rem(16);
      }

      .blank_date_container {
        display: flex;
        align-items: center;

        .invalid_date_txt {
          font-size: rem(12);
          font-weight: $fw_4;
          color: $col_gray-600;
          margin: 0 rem(22) 0 rem(8);
          cursor: pointer;
        }
        .blank_date_checkbox {
          width: rem(12);
          height: rem(12);
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          cursor: pointer;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: rem(12);
            height: rem(12);
            background-color: $col_white;
            border: rem(1) solid $col_gray-300;
            border-radius: rem(2);
          }
          &:checked::before {
            position: absolute;
            top: 0;
            left: 0;
            background: url(~/public/images/checked-checkbox.svg) center/rem(12)
              no-repeat;
          }
        }
      }
    }

    .disable-block {
      display: block;

      .btn_group {
        margin-top: rem(16);
        justify-content: flex-end;
      }
    }
  }

  .nav-pills {
    .nav-item {
      border: rem(1) solid $col_gray-300;

      .nav-link.active {
        background-color: $col_blue;
      }
      .nav-link {
        padding: rem(4) rem(30);
      }
    }
    .nav-item:nth-child(2n) {
      border-left: unset;
      border-right: unset;
    }
  }

  .rdrMonthAndYearPickers {
    font-size: rem(16);
  }
  .rdrMonthAndYearWrapper {
    padding-top: 0;
    border-top: rem(0.5) solid $col_gray-300;
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    border: rem(1) solid $col_gray-300;
    border-radius: rem(4);
  }
  .rdrMonthAndYearPickers select {
    padding: rem(4) rem(30) rem(4) rem(10);
  }
}

.rdrDefinedRangesWrapper {
  display: none;
}

.time-picker-control {
  display: flex;
  align-items: center;
  .btn-group {
    display: flex;
    justify-content: center;
    margin-left: rem(10);
    a {
      background-color: $col_white;
      color: $col_black;
      padding: rem(6) rem(10);
      font-size: rem(14);
      border: rem(1) solid $col_gray-300;
    }
  }

  input {
    border: rem(1) solid $col_gray-300;
    border-radius: rem(4);
    width: rem(50);
    padding: rem(6);
    @include text-center;
    font-size: rem(16);
    margin: 0 rem(10);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }

  .nav-pills {
    .nav-item {
      .nav-link.active {
        background-color: $col_blue-2;
        color: $col_blue;
      }
      .nav-link {
        padding: rem(4) rem(8);
      }
    }
  }
}

.only-date-picker {
  .modal-header {
    padding: rem(16) rem(24) rem(12);
    .modal-title {
      font-size: rem(18);
    }
  }

  .modal-body {
    margin-top: rem(-20);

    .rdrMonthAndYearWrapper {
      padding-top: 0;
      border-top: unset;
    }

    .rdrCalendarWrapper {
      font-size: rem(14);
    }
  }

  .modal-footer {
    display: flex;
    padding: rem(16) rem(16) rem(16) rem(12) !important;
    .group-btn_clear_submit {
      margin-top: 0;

      .select-date-container {
        margin-right: rem(8);
      }

      .btn_group {
        gap: rem(8);
      }
    }
  }
}
