@import "assets/css/_base.scss";

.check-box {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
  }
  label {
    position: relative;
    left: 0;
    display: inline-block;
    cursor: pointer;

    .label {
      font-size: rem(16);
      margin-left: rem(20);
      display: flex;
      align-items: center;

      .color {
        width: rem(12);
        height: rem(12);
        border-radius: rem(2);
        display: inline-block;
        margin-right: rem(6);
      }
    }

    .label-small {
      font-size: rem(12);
    }

    .label-unset {
      display: unset;
    }

    &::before {
      content: "";
      width: rem(12);
      height: rem(12);
      border: rem(1) solid $col_gray;
      border-radius: rem(2);
      position: absolute;
      top: rem(3);
      left: 0;
    }

    &.checkbox-disabled {
      cursor: not-allowed;
      &::before {
        opacity: 0.5;
        background-color: $col_gray;
      }
    }
  }

  &.indeterminate label::before {
    content: "\2014"; // Unicode for em dash
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: $col_blue;
    width: rem(16);
    height: rem(16);
    font-size: rem(12);
    border: none;
  }

  input[type="checkbox"]:checked + label::before {
    content: "";
    background-color: $col_blue;
    border: none;
    background: url(~/public/images/checked-checkbox.svg) no-repeat;
    background-size: rem(12);
  }
  &-md {
    label {
      .label {
        font-size: rem(14);
        line-height: rem(20);
      }
      span {
        font-size: rem(14);
        margin-left: rem(20);
      }
      &::before {
        top: 50%;
        transform: translateY(-50%);
        width: rem(14);
        height: rem(14);
      }
    }
    input[type="checkbox"]:checked + label::before {
      background-size: rem(14);
    }
  }
  &-lg {
    label {
      span {
        font-size: rem(16);
        margin-left: rem(22);
      }
      &::before {
        width: rem(16);
        height: rem(16);
        border-color: $col_gray-300;
      }
    }
    input[type="checkbox"]:checked + label::before {
      background-size: rem(16);
    }
  }
}

.check-box-black {
  label {
    width: 100%;
    &::before {
      border: rem(1) solid $col_black;
    }
  }
}
