@import "assets/css/base";

.tag-item {
  width: rem(13);
  height: rem(13);
  border-radius: rem(2);
  display: block;
  margin-bottom: rem(4);
}

.tag-horizontal {
  width: rem(15);
  height: rem(15);
  border-radius: rem(2);
  display: block;
  margin-bottom: rem(4);
  margin: rem(4);
}
