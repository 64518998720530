@import "assets/css/base";

.title {
  margin-right: rem(15);
  font-weight: $fw_5;
  font-size: rem(20);
  color: $col_gray-600;
  @include flex-between;
}

.box-wrap {
  background: $col-white;
  border-radius: rem(8);
  padding: rem(8) rem(10);
  height: 100%;
  min-height: rem(25);
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.wrap {
  position: relative;
  display: flex;

  .matter-date-control {
    @include flex-between;
    align-items: center;

    .date-range {
      font-weight: $fw_5;
      font-size: rem(20);
      color: $col_gray-600;
    }

    .btn-group {
      margin-left: rem(10);
      margin-right: rem(10);
      display: flex;
      width: rem(24);

      img {
        position: relative;
        border-left: 1px solid $col_gray-600;
        padding-left: rem(10); 
      }
    }
    
  }

  .disabled {
    cursor: not-allowed;
    background-color: $col_gray-300;
    border-color: $col_gray-300;
    color: $col_gray;
    opacity: 0.7;
  }

  .matter-date-spinner {
    position: relative;
    align-items: right;
    padding: rem(8) rem(10);
  }
}
