@import "assets/css/base.scss";

.wrap {
  .header {
    @include flex-between;
    margin-bottom: rem(16);

    h5 {
      font-size: rem(14);
      font-weight: $fw_7;
      line-height: rem(20);
      color: $col_gray_600;
    }
  }

  .body {
    min-height: rem(240);
    max-height: rem(240);
    overflow-y: auto;
    @include scroll-flexible;
    position: relative;

    .no-result {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: rem(32);
    }

    table {
      width: 100%;
      overflow: hidden;

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      thead {
        background: $col_gray-50;
        height: rem(40);
        overflow: hidden;
        border-radius: rem(8) rem(8) 0 0;

        th {
          text-align: left;
          font-weight: $fw_7;
          font-size: rem(12);
          line-height: rem(16);
          color: $col_gray-400;
          white-space: nowrap;

          &:first-child {
            border-top-left-radius: rem(8);
          }

          &:last-child {
            border-top-right-radius: rem(8);
          }
        }
      }

      tbody {
        display: block;
        max-height: rem(200);
        overflow-y: auto;
        @include scroll-flexible;

        tr {
          text-align: left;
          font-weight: $fw_4;
          font-size: rem(12);
          line-height: rem(16);
          height: rem(48);

          td {
            color: $col_gray-600;
            @include text-ellipsis;

            .likely-name {
              opacity: 0.5;
            }
          }

          .action {
            height: 100%;

            img {
              width: rem(16);
              margin-right: rem(8);
              cursor: pointer;
            }
          }
        }

        td.overflow-unset {
          overflow: unset;
        }

        tr:hover {
          background: $color_lavender;
        }
      }

      th,
      td {
        padding-left: rem(12);
      }
    }
  }
}
