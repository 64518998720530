@import "assets/css/base";

.wrap {
  padding-bottom: rem(40) !important;
  min-height: calc(100vh - rem(96));
  .header {
    @include flex-between;
    .header-title {
      margin-top: rem(8);
      font-weight: $fw_4;
      font-size: rem(32);
      line-height: rem(40);
      color: $col_gray-600;
    }

    .header-control {
      display: flex;
      justify-content: space-between;
      .print {
        margin-right: rem(16);
      }
    }
  }

  .export-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: rem(697);
    background: $col_white;
    box-shadow: $box_shadow_icon;
    border-radius: rem(8);
    padding: rem(16) rem(24) rem(24);
    margin-top: rem(24);
    position: relative;

    &-box {
      @include flex-between;
    }

    .content-title {
      margin-right: rem(14);
      font-weight: $fw_4;
      font-size: rem(24);
      line-height: rem(20);
      color: $col_gray-600;
    }
    .control-header {
      display: flex;

      .wrap-btn {
        margin-right: rem(16);
      }
    }

    .total-export {
      @include flex-end;
      span {
        margin: rem(10) 0;
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(24);
        color: $col_gray;
      }
    }

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .export-table {
      border: rem(1) solid $col_gray-800;
      box-sizing: border-box;
      border-radius: rem(8);
      border-collapse: unset;
      border-spacing: inherit;
      position: relative;
      flex: 1;
    }
  }
}

@media print {
  @-moz-document url-prefix() {
    .wrap {
      min-height: fit-content;
      padding-bottom: 0 !important;

      .export-body {
        margin-top: rem(12);
        padding: rem(14);
      }
    }
  }
}
