@import "assets/css/base";

.bread-crumb-container {
  display: flex;
  align-items: center;
  margin-top: rem(7);

  .icon {
    padding-right: rem(9);
    cursor: pointer;
  }

  .bread-crumb {
    &-item {
      font-size: rem(16);
      line-height: rem(24);

      span {
        color: $col_gray;
        padding: 0 rem(5);
      }
    }

    &-active {
      cursor: pointer;
      color: $col_blue;
    }

    &-disable {
      color: $col_gray;
      cursor: default;
      pointer-events: none;
    }
  }
}
@media print {
  .bread-crumb-container {
    flex-direction: row !important;
  }
}
