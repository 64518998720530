@import "assets/css/base";

.wrap {
  width: fit-content;
  position: relative;

  ul {
    min-width: rem(120);
    width: fit-content;
    padding: rem(16);
    background-color: $col-white;
    box-shadow: $box_shadow_icon;
    border-radius: rem(4);
    position: absolute;
    top: calc(100% + rem(8));
    right: 0;
    z-index: 999;

    li {
      font-size: rem(14);
      font-weight: $fw_4;
      color: $col_gray-600;
      margin-bottom: rem(8);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
