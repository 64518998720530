@import "assets/css/base.scss";

.menu {

  &-list {

    &-item {
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_gray-600;
      margin-bottom: rem(12);
      padding: 0 rem(2);
      width: fit-content;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }

      .list-item-search {
        @include flex-between;
        user-select: none;

        .search-name {
          display: flex;
          align-items: center;
          cursor: pointer;
          width: 80%;

          span {
            width: 94%;
          }
        }

        img {
          width: rem(12);
          height: rem(12);
          margin-right: rem(8);
          cursor: pointer;
        }
      }

      .selected-search {
        margin-right: rem(6);
        color: $col_blue;
        cursor: pointer;
      }

      .active {
        font-weight: $fw_7;
        color: $col_black;
      }

      .preview-save-search {
        width: 100%;
        height: auto;
        border: rem(1) solid $col_gray-700;
        background: $bg_color;
        border-radius: rem(4);
        margin-top: rem(10);
        padding: rem(12) rem(12) rem(4);

        li {
          @include flex-between;
          margin-bottom: rem(8);
          font-size: rem(14);
          line-height: rem(20);

          &:last-child {
            margin-bottom: 0;
          }

          .preview-label {
            font-weight: $fw_4;
            color: $col_gray;
            margin-right: 8%;
          }

          .preview-value {
            font-weight: $fw_5;
            color: $col_gray-600;
          }

          .search-queries {
            @include flex-end;
            flex-wrap: wrap;

            > div {
              margin: 0 0 rem(8) rem(8);
            }
          }
        }
      }
    }

    .highlight-item {
      width: 100%;
      background-color: $col_blue-op-10;
    }
  }

  .list-title {
    font-weight: $fw_7;
    font-size: rem(14);
    line-height: rem(16);
    color: $col_gray-600;
    margin: rem(12) 0;
  }
}
