@import "assets/css/base.scss";

.avatar {
  margin-bottom: rem(28);
  position: relative;
  height: rem(160);

  figure {
    width: rem(160);
    height: rem(160);
    margin: auto;
    border-radius: 50%;
    background-color: $col_blue-15;
    @include flex-center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }

    .default-avatar {
      width: rem(64);
      height: rem(64);
      border-radius: unset;
    }
  }

  .choose-avatar {
    position: absolute;
    top: calc(100% - rem(12));
    left: 50%;
    transform: translateX(-50%);
    background-color: $col_blue;
    border-radius: 50%;
    width: rem(24);
    height: rem(24);
    z-index: 9;

    img {
      width: rem(16);
      height: rem(16);
      @include flex-center;
      margin: rem(4) auto;
    }

    &:hover {
      cursor: pointer;
    }
  }
}