@import "assets/css/base";

.progress-bar-wrap {
  display: flex;
  align-items: center;

  .progress-bar-cell {
    margin: rem(0.5);
    width: vw(24);
    height: rem(32);
  }
}
