@import "assets/css/_base.scss";

.container {
  border: rem(1) solid $col_blue;
  border-radius: rem(4);
  margin-right: rem(8);
  position: relative;
  overflow: hidden;

  .content {
    padding: rem(6) rem(12);
    cursor: default;

    .title {
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_gray-600;

      &-dot {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &:hover {
    .description {
      display: block;
    }
  }

  .description {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: rem(360);
    height: auto;
    background: rgba(34, 34, 34, 0.8);
    box-shadow: $box_shadow;
    border-radius: rem(4);

    padding: rem(16) rem(12);
    margin-top: rem(8);
    z-index: 1000;

    p {
      color: $col_white;
      font-weight: $fw_7;
      font-size: rem(14);
      line-height: rem(20);
    }
    span {
      display: block;
      width: 100%;
      margin-top: rem(12);
      color: $col_white;
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      white-space: normal;

      p {
        margin: rem(10) 0;
        font-weight: $fw_4;
      }
    }
  }
}
.container-fluid {
  min-width: fit-content !important;
}

.combine {
  background-color: rgba($col_gray, 0.15);
  border-color: $col_gray;
}
