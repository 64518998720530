@import "assets/css/base";

.download-btn-icon {
    font-size: rem(14);
    border-radius: rem(4);
    border-width: rem(1);
    border: solid rem(1) $col_blue;
    background-color: transparent;
}

.download-btn {
    height: rem(28);
    padding: 0 rem(16);
    border: none;
    background-color: $col_blue;
    border-radius: rem(4);
    border-width: rem(1);

    span {
        color: $col_white;
        font-weight: $fw_5;
        font-size: rem(12);
        line-height: rem(16);
        white-space: nowrap;
    }
}