@import "assets/css/_base.scss";

.modal-container {
  .modal-dialog {
    min-width: rem(432);

    .modal-content {
      padding: rem(24) 0;
      border-radius: rem(8);

      .modal-header {
        border: none;
        padding: 0 rem(24);
        div {
          font-size: rem(24);
          font-weight: $fw_5;
          color: $col_gray-600;
        }
        button {
          border: rem(1) solid $col_gray-600;
          opacity: 1;
          margin: 0;
          position: absolute;
          top: rem(16);
          right: rem(16);
        }
      }

      .modal-body {
        padding: rem(24) rem(24) rem(32);

        label {
          font-size: rem(14);
          margin-bottom: rem(8);
        }

        :global(.field-select__control) {
          height: rem(48);
          border-radius: rem(6);
          margin-bottom: rem(24);
        }

        .field-input {
          input {
            height: rem(48);
            border-radius: rem(6);
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: flex-end;
        padding: rem(24) rem(24) 0;

        > * {
          margin: 0;
        }

        .group-btn {
          display: flex;
          gap: rem(16);
        }
      }
    }
  }
}
