@import "assets/css/base";

.voice-mail-container {
  background-color: $col_gray-10;
  border-radius: rem(100);
  width: 100%;
  display: flex;
  align-items: center;

  .media-icon {
    cursor: pointer;
    padding-left: rem(18);
    position: relative;

    .play-pause {
      width: rem(16);
      height: rem(16);
    }
  }

  .voice-mail-body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: rem(8);

    .progress-bar {
      width: 100%;
      height: rem(4) !important;
      border-radius: rem(100);
      cursor: pointer;
      margin-right: rem(4);
    }

    span {
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_gray-600;
      margin-left: rem(8);
    }

    .disabled-icon {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
