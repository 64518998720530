@import "assets/css/_base.scss";

.export-name-form {
  margin-bottom: rem(24);
}

.export-title-header {
  @include flex-between;
  margin-bottom: rem(24);

  h4 {
    font-weight: $fw_5;
    font-size: rem(16);
    line-height: rem(24);
    color: $col_gray-600;
    margin-right: rem(16);
  }

  .header-line {
    flex: 1;
    border: rem(1) solid $col_gray-700;
    opacity: 0.5;
  }
}

.export-setting {
  width: 100%;
  height: fit-content;
  background-color: $col_white;
  border: rem(1) solid $col_blue-4;
  border-radius: rem(8);
  padding: rem(16);
  display: flex;
  margin-bottom: rem(32);

  &-select {
    margin-bottom: rem(20);
  }

  :global(.react-select__single-value) {
    grid-area: 1/1/2/2;
  }

  .select-container {
    flex: 1;
  }

  &-form {
    width: 100%;
  }

  .setting-starting {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: rem(16);
    margin-left: rem(14);
    margin-bottom: rem(20);

    .starting-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      .form-error-starting {
        margin: rem(2) 0 rem(-16);
      }
    }

    &-form {
      height: rem(40);
    }
  }

  .toggle-selector {
    margin: rem(26) rem(16);
  }

  .note-tooltip-group {
    position: relative;

    .note-tooltip {
      margin: 0 0 rem(4) rem(6);
      width: rem(12);
    }

    span.tooltip-description {
      position: absolute;
      top: calc(100% + rem(2));
      left: 0;
      animation-delay: 0s !important;
      display: none;
      background: $col_gray-750;
      box-shadow: $box_shadow;
      border-radius: rem(4);
      width: rem(420);
      min-height: rem(24);
      padding: rem(16);
      z-index: 99;
      color: $col_white;
      font-weight: $fw_4;
      font-size: rem(14);
    }

    .tooltip-description {
      display: none;
      z-index: 9999;
    }

    &:hover>.tooltip-description {
      display: block;
    }
  }
}

.text-disabled {
  opacity: 0.5;
}

.app-form-required {
  color: $col_red;
}

:global(.app-form-group) {
  position: relative;

  .form-error {
    position: absolute;
    top: calc(100% - rem(2));
  }

  .error {
    :global(.react-select__control) {
      border: rem(1) solid $col_red;
    }
  }
}

@media print {
  :global(.react-select__placeholder) {
    font-size: rem(12);
  }

  :global(.react-select__single-value) {
    font-size: rem(13);
  }
}