@import "assets/css/base.scss";

.wrap {
  min-height: rem(528);

  .no-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  table {
    width: 100%;
    height: auto;
    table-layout: fixed;

    thead,
    tbody tr {
      width: 100%;
    }

    thead {
      background: $col_gray-50;
      height: rem(48);
      border-bottom: rem(1) solid $col_gray-800;
      overflow: hidden;

      th {
        text-align: left;
        font-weight: $fw_7;
        font-size: rem(12);
        line-height: rem(16);
        color: $col_gray-400;
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: rem(8);
        }

        &:last-child {
          border-top-right-radius: rem(8);
        }

        .sort-column:not(.no-sort) {
          padding-right: rem(14);
          position: relative;
          cursor: pointer;
          user-select: none;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: rem(5);
            width: 0;
            height: 0;
            border-left: rem(4) solid transparent;
            border-right: rem(4) solid transparent;
            border-top: rem(6) solid $col_gray-600;
            clear: both;
          }
        }

        .no-sort {
          pointer-events: none;
        }

        :global(.sort.sort-asc::after) {
          border-top: none;
          border-bottom: rem(6) solid $col_gray-600;
        }

        :global(.sort.sort-desc::after) {
          border-top: rem(6) solid $col_gray-600;
          border-bottom: none;
        }
      }
    }

    tbody {
      tr {
        text-align: left;
        font-weight: $fw_4;
        font-size: rem(12);
        line-height: rem(16);
        height: rem(48);

        td {
          color: $col_gray-600;
          @include text-ellipsis;

          .entity-name {
            white-space: pre;
          }

          .other-icon img {
            width: rem(20);
            height: rem(20);
          }

          .non-combine-info {
            @include text-ellipsis;
            width: 100%;
          }

          .combine-items {
            cursor: default;
            position: absolute;

            .combine-info {
              background-color: $col_gray-300;
              border-radius: rem(4);
              width: fit-content;
              padding: rem(4) rem(12);
              position: relative;
              bottom: rem(14);
            }

            &:hover {
              .combine-list {
                display: block;
              }
              &::after {
                content: "";
                width: 100%;
                height: rem(16);
                position: absolute;
                bottom: calc(100% + rem(8));
              }
            }

            .combine-list {
              position: absolute;
              right: 0;
              top: calc(100% + rem(8));
              display: none;
              border-radius: rem(4);
              min-width: rem(250);
              max-width: rem(250);
              max-height: rem(200);
              padding: rem(20) rem(16);
              color: $col-white;
              box-shadow: $box_shadow;
              background-color: $col-white;
              z-index: 1000;
              overflow-y: auto;
              @include scroll-flexible;

              .combine-list-item {
                display: flex;
                align-items: center;
                margin: 0 0 rem(10);
                cursor: default;

                &:last-child {
                  margin-bottom: 0;
                }

                img {
                  width: rem(20);
                  height: rem(20);
                  margin-right: rem(10);
                }

                span {
                  font-size: rem(14);
                  color: $col_gray-600;
                  @include text-ellipsis;
                }
              }
            }
          }

          .action {
            color: $col_blue;
            font-size: rem(12);
            font-weight: $fw_5;
            line-height: rem(16);
            cursor: pointer;
          }
        }
      }

      td.overflow-unset {
        overflow: unset;
      }

      tr {
        &:hover {
          background: $color_lavender;
        }

        &:nth-child(n+7) {
          .combine-list {
            position: absolute;
            top: unset !important;
            bottom: 100%;
            margin-bottom: rem(8);
          }
        }
      }

    }

    th,
    td {
      padding-left: rem(24);

      &:first-child {
        width: 3%;
      }

      &:nth-child(2) {
        padding-left: rem(12);
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 14%;
      }

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        width: 10%;
      }

      &:last-child {
        width: 8%;
      }
    }
  }
}
