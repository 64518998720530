@import "assets/css/_base.scss";

.chat-content {
  font-size: rem(14);
  position: relative;
  width: fit-content;

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  img {
    max-width: rem(250);
    max-height: rem(250);
    object-fit: contain;
    object-position: left center;
    border-radius: rem(4);
  }

  .blur-action {
    position: absolute;
    top: calc(100% - rem(34));
    left: rem(10);
    z-index: 999;
    background-color: $col_white;
    width: rem(24);
    height: rem(24);
    padding: rem(2);
    box-shadow: 0 rem(4) rem(4) rgba(0, 0, 0, 0.25);
    border-radius: rem(4);
    cursor: pointer;
  }

  .loading-blur {
    position: relative;
    height: 100%;

    .blur-img,
    .blur-loading {
      filter: blur(rem(5));
    }

    &-chat {
      min-height: rem(200);
    }
  }

  .file-attach-img {
    cursor: pointer;
  }

  .video-thumbnail-container {
    @include flex-center;
    width: rem(250);
    height: rem(250);
    cursor: pointer;
    position: relative;

    :global(.video-react) {
      pointer-events: none;
      border-radius: rem(6);
    }

  }
}

.image-type {
  width: 100%;
  height: 100%;
  min-height: rem(200);
}

.attachment-query-hit {
  border: solid rem(4) #ebb515;
  border-radius: rem(4);
  display: flex;
  align-items: center;
  padding: rem(2);
  margin-top: rem(6);
  cursor: pointer;
}

.attachment-query {
  padding: rem(4);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.attachment-decoration {
  font-size: rem(12);
  line-height: rem(20);
  font-weight: $fw_4;
  text-decoration: underline !important;
  color: $col_blue !important;
  cursor: pointer;
}

.attachment-not {
  &-available {
    display: flex;
    margin-top: rem(4);
  
    img {
      width: rem(16);
      height: auto;
    }
  
    p {
      font-style: italic;
      margin-left: rem(4);
    }
  }

  &-content-type {
    font-size: rem(12);
    line-height: rem(20);
    color: $col_blue;
    font-weight: $fw_5;
    cursor: pointer;
    margin-top: rem(4) !important;
  }
}
