@import "assets/css/_base.scss";

.preview-img-wrap {
  height: 100%;

  .cursor-pointer {
    cursor: pointer;
  }

  .h-100 {
    height: 100% !important;
  }

  .loading-blur {
    position: relative;
    height: 100%;
  }

  .img-review {
    width: 100%;
    padding-bottom: rem(10);
  }

  .img-detail {
    object-fit: contain;
    object-position: left top;
    width: 100%;
    height: 100%;
  }

  p {
    color: $col_gray-600;
    font-size: rem(16);
  }
}

.preview-img-wrap-fr {
  cursor: pointer;

  &-tag {
    margin-right: rem(24);
  }
}

@media print {
  .loading-blur {
    .img-review {
      position: absolute;
      top: 0;
      height: 100%;
    }
  }
}
