@import "assets/css/_base.scss";

.video-container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: rem(8);

  .load-video-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: rem(80);
    height: rem(35);
    background-color: rgba(43, 51, 63, 0.7);
    color: white;
    border: rem(1) solid white;
    border-radius: rem(10);
    font-size: rem(12);
    cursor: pointer;
  }

  .load-video-button:hover {
    background-color: rgba(115, 133, 159, 0.5);
  }

  .hidden-play-button {
    display: none !important;
  }

  .video-box {
    height: 100%;
    width: 100%;
    background-color: dimgray;
  }

  .error-container {
    @include text-center;
    color: $col_gray-600;
    font-style: italic;
    padding: rem(16);

    img {
        width: 100%;
        max-width: rem(200);
        height: 100%;
        max-height: rem(200);
        object-fit: contain;
    }
    
    p {
      margin-top: rem(8);
      font-size: rem(24);
  }
}
}

@media print {
  .video-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;

    .video-box {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
}