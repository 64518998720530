@import "assets/css/base.scss";

.dropdown-selected {
  :global(.react-select__indicator-separator) {
    display: none;
  }

  :global(.react-select__dropdown-indicator) {
    padding: 0 rem(12);
  }

  :global(.react-select__value-container) {
    padding: rem(6) rem(14);
  }

  :global(svg) {
    width: rem(18);
    color: $col_gray-600 !important;
  }

  :global(.react-select__control--is-disabled) {
    background-color: $col_gray-800;
  }
}

:global(.react-select__control--is-disabled) {
  background-color: $col_gray-50 !important;

  :global(svg) {
    opacity: 0.4;
  }
}

:global(.react-select__value-container) {
  padding: 0 rem(14) !important;
}
