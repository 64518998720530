@import "assets/css/base.scss";

.wrap {
  :global(.modal-dialog) {
    max-width: rem(360);
    margin: 0 auto;
  }

  .modal-header {
    @include flex-between;
    border-bottom: none;
    position: relative;
    justify-content: left;
    padding-top: rem(16) !important;

    .title {
      font-size: rem(20);
    }

    .close-icon {
      position: absolute;
      top: rem(16);
      right: rem(16);
      cursor: pointer;

      img {
        width: rem(20);
        height: rem(20);
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: left;
    min-height: rem(180);
    width: 100%;
    padding-left: rem(24) !important;
    padding-right: rem(24) !important;
    padding-top: rem(18) !important;

    .date-control {
      font-size: rem(16) !important;
      margin-bottom: rem(30);
    }

    .date-control input {
      font-size: rem(16) !important;
    }

    .date-control span {
      margin-bottom: 0 !important;
    }

    .modal-label {
      font-size: rem(16);
      margin-bottom: rem(4);
    }

    .time-zone-control {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: left;

      .time-zone-dropdown {
        margin-top: rem(5);

        .time-zone-dropdown-selector {
          width: rem(311);
          font-size: rem(16);
        }

        .time-zone-dropdown-selector-dropdown {
          width: rem(311) !important;
        }
      }
    }

    .blank-date-control {
      display: flex;
      align-items: left;
      justify-content: left;
      font-size: rem(16);
      margin-top: rem(30);

      .blank-date-checkbox-control {
        display: flex;
        align-items: center;
        justify-content: center;

        .blank-date-checkbox {
          appearance: none;
          width: rem(16);
          height: rem(16);
          border: 1px solid #ccc;
          border-radius: 3px;
          background-color: #f0f0f0;
          position: relative;
          cursor: pointer;
          transition: background-color 0.3s ease;
          display: inline-flex;
          align-items: center;
        }
        
        .blank-date-checkbox:checked {
          background-color: #1580EF;
          border-color: #1580EF;
        }
        
        .blank-date-checkbox:checked::before {
          content: '✔';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 10px;
        }
      }

      .invalid-date-text {
        margin-left: rem(10);
      }
    }
  }

  .modal-footer {
    padding-top: rem(18) !important;
    padding-bottom: rem(18) !important;
    @include flex-between;
    justify-content: right;
    
    .btn-control {
      display: flex;
      justify-content: space-between;
      
      button {
        height: rem(30);
      }
    }

    .multi-button {
      width: 100%;
    }
    
    .btn-reset {
      margin-left: 0;
    }
    
    .btn-right-group {
      display: flex;
      gap: rem(10);
    }
    
    &>* {
      margin: 0;
    }
  }
}

:global {
  .e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
    color: $col_blue !important;
  }

  .e-cell {
    &.e-today {
      span.e-day {
        border-color: $col_blue !important;
        color: $col_blue !important;
      }
    }

    &.e-selected {
      span.e-day {
        background-color: $col_blue !important;
        color: white !important;
      }
    }
  
    &.e-selected.e-focused-date {
      span.e-day {
        background-color: $col_blue !important;
        background: $col_blue !important;
        color: white !important;
      }
    }
  
    &.e-selected.e-focused-date.e-today {
      span.e-day {
        color: white !important;
        background-color: $col_blue !important;
      }
    }
  
    &.e-focused-date.e-today {
      span.e-day {
        background-color: white !important;
      }
    }
  }

  .e-popup-open {
    width: rem(311) !important;
  
    .e-calendar.e-lib.e-keyboard,
    .e-header.e-month,
    .e-content.e-month,
    .e-footer-container {
      width: rem(311) !important;
      max-width: rem(311) !important;
    }
  }  
}