@import "assets/css/base";

.modal-container {
  margin-top: rem(80) !important;

  .modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: none;
    padding: rem(16) !important;

    .modal-header-title {
      color: $col_gray-600;
      font-weight: $fw_5;
      font-size: rem(24);
      line-height: rem(32);
    }
  }

  .modal-body {
    padding: rem(16) !important;

    .modal-body-label {
      font-weight: $fw_5;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_gray-600;
      margin-bottom: rem(8);
      span {
        display: inline;
      }
    }

    .modal-body-input {
      font-size: rem(16);
      color: $col_gray-600;
      font-weight: $fw_4;
      padding: rem(12) rem(16);
      width: 100%;
      border: rem(1) solid $col_gray-300;
      border-radius: rem(6);
      height: rem(48);
    }

    .radio-color-group {
      position: relative;
      width: rem(32);
      height: rem(32);
      margin-right: rem(8);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      .radio-color {
        position: absolute;
        display: none;
      }

      .check-mark {
        position: absolute;
        top: rem(4);
        left: rem(4);
        width: rem(24);
        height: rem(24);
        border-radius: 50%;
      }

      .border-check-mark {
        position: absolute;
        top: 0;
        left: 0;
        width: rem(32);
        height: rem(32);
        border: rem(1) solid;
        border-radius: 50%;
      }

      &:hover input ~ .check-mark,
      &:hover input ~ .border-check-mark {
        opacity: 0.8;
      }

      .check-mark::after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked ~ .check-mark::after {
        display: block;
      }

      .check-mark::after {
        position: absolute;
        top: rem(6);
        left: rem(6);
        background: url(~/public/images/checked-white-icon.svg) center no-repeat;
        width: rem(12);
        height: rem(12);
      }
    }

    .more-color {
      span {
        font-weight: $fw_5;
        font-size: rem(12);
        line-height: rem(16);
        color: $col_gray-600;
      }

      .color-picker-wrapper {
        margin-left: rem(12);
        cursor: pointer;
        position: relative;
        width: rem(24);
        height: rem(24);

        &:hover {
          opacity: 0.8;
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1000;
        }
      }

      input[type="color"] {
        width: rem(24);
        height: rem(24);
        border: none;
        background: none;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
      }

      input[type="color"]::-webkit-color-swatch {
        border-radius: 50%;
      }

      .hidden {
        visibility: hidden;
      }
    }
    .modal-error {
      font-size: rem(12);
    }
  }
  .modal-re-choose:hover {
    animation: circle-wheel;
    animation-duration: 2s;
  }
  .modal-footer {
    padding: rem(16) !important;
    display: flex;

    & > * {
      margin: 0;
    }

    button {
      margin-left: rem(16) !important;
    }
  }

  :global(.modal-dialog) {
    max-width: rem(480);
    margin: rem(160) auto;
  }
}

.tab-container {
  margin-top: 0 !important;
  .tab-header {
    padding-top: rem(12) !important;
    .tab-title {
      font-size: rem(16);
      display: flex;
      align-items: center;
      img {
        margin-left: rem(-6);
      }
    }
  }
  .tab-close {
    font-size: rem(16);
    color: $col_blue;
    font-size: $fw_5;
    cursor: pointer;
  }
  .tab-body {
    padding-top: 0 !important;
    padding-bottom: rem(16) !important;
    label.modal-body-label {
      font-size: rem(14);
    }
    .modal-body-input {
      height: rem(32);
      font-size: rem(12);
      border-radius: rem(4);
    }
  }
}
@keyframes circle-wheel {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}
