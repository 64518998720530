@import "assets/css/base";

.PII {
  position: absolute;
  right: rem(-60);
  top: 0;
  width: rem(48);
  height: rem(48);
  background: $col_blue-4;
  box-shadow: $box_shadow_icon;
  border-radius: 50%;
  font-weight: $fw_7;
  &-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(48);
    position: relative;
    cursor: pointer;

    &-text {
      font-weight: $fw_7;
      font-size: rem(16);
    }
  }

  &-popup {
    position: relative;
  }
}
