@import "assets/css/base.scss";

.wrap {
  .filter-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;

    .filter-icon {
      width: rem(16);
      height: rem(16);
      margin-right: rem(10);
    }

    span {
      display: flex;
      align-items: center;

      h5 {
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(20);
        color: $col_gray-600;
        margin-right: rem(8);
      }

      .arrow-icon {
        width: rem(8);
        height: rem(8);
        margin-right: rem(10);
      }
    }
  }

  .menu-filter {
    width: rem(280);
    height: rem(430);
    background-color: $col_white;
    box-shadow: $box_shadow;
    padding: rem(16) 0 rem(16) rem(16);
    border-radius: rem(6);
    position: absolute;
    margin-top: rem(8);
    z-index: 999;

    &-header {
      margin-bottom: rem(16);

      h5 {
        font-weight: $fw_7;
        font-size: rem(14);
        line-height: rem(20);
        color: $col_gray-600;
        margin-bottom: rem(8);
      }

      p {
        font-weight: $fw_4;
        font-size: rem(11);
        line-height: rem(16);
        color: $col_gray;
      }

      .close-icon {
        position: absolute;
        top: 0;
        right: 0;
        margin: rem(12);
        width: rem(16);
        height: rem(16);
        cursor: pointer;
      }
    }

    &-body {
      height: rem(290);
      padding-right: rem(16);
      overflow-x: hidden;
      overflow-y: auto;
      @include scroll-flexible;
    }

    &-footer {
      @include flex-end;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $col_white;
      width: 100%;
      height: rem(64);
      padding: rem(16);
      border-bottom-left-radius: rem(6);
      border-bottom-right-radius: rem(6);

      button {
        margin-left: rem(12);
      }
    }
  }
}