@import "assets/css/base";

// Style popup upload avartar
.upload-avatar-container {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: rem(16);
        border-bottom: unset;

        .modal-title {
          font-size: rem(16);
        }
      }

      .upload-body {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: rem(10);

        &__image {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: rem(140);
          height: rem(140);
          border-radius: 50%;
          cursor: pointer;

          .img-container {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;

            figure {
              position: relative;
              width: 100%;
              height: 100%;
              
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }
        }

        &__control {
          p {
            padding-top: rem(14);
            font-weight: $fw_4;
            font-size: rem(12);
            color: $col_gray-600;
          }

          .upload-image {
            font-size: rem(14);
            padding: rem(1) rem(10);
          }
        }
      }

      .modal-footer {
        padding: rem(16);
        display: flex;
        justify-content: space-between;

        .msg-err-container {
          color: $col_red-2;
          width: calc(100% - rem(150));

          img {
            margin-right: rem(10);
          }

          span {
            font-size: rem(12);
          }
        }

        .btn-control {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
        .btn-common + .btn-common {
          margin-left: rem(16);
        }
      }

      .btn-cancel-upload {
        color: $col_black;
        margin-right: rem(10);
        font-size: rem(14);
      }

      .btn-confirm-upload {
        background: $col_blue-10;
        color: $col-white;
        font-weight: $fw_5;
        font-size: rem(14);
      }

      .smallIcon {
        width: rem(32) !important;
      }
    }
  }
}

// Style popup confirm
.popup-confirm-dialog {
  .modal-dialog {
    max-width: rem(320);
  }

  .modal-header {
    padding: rem(16) 0 rem(26) 0 !important;
    border-bottom: unset;
    @include text-center;
    position: relative;
    display: block;

    .modal-title {
      font-weight: $fw_6;
      font-size: rem(16);
      line-height: rem(24);
      color: $col_black;
    }

    .btn-close {
      position: absolute;
      top: rem(10);
      right: rem(10);
    }
  }

  .modal-content {
    .modal-header-custom {
      width: 100%;
    }

    .content {
      font-weight: $fw_4;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_black;
      @include text-center;
      padding: 0 rem(22);
      width: 100%;

      .newline {
        margin: rem(8) 0;
      }
    }
  }

  .modal-footer {
    border-top: unset;
    justify-content: center;
    padding: rem(26) 0 0 0 !important;
    margin-bottom: rem(20);
    .btn-common + .btn-common {
      margin-left: rem(16);
    }
  }
}

.popup-confirm-dialog-entity {
  .modal-dialog {
    height: calc(100vh - rem(96));
    margin: 0 auto;
    display: flex;
    align-items: center;

    .modal-content {
      min-width: rem(320);
      min-height: rem(172);
    }
  }

  button span {
    font-size: rem(14);
  }

  button.btn-cancel {
    color: $col_gray-600;
    min-width: rem(86);
    height: rem(32);
    justify-content: center;
  }
}

// Style popup Request Archive
.request-archive {
  .modal-dialog {
    width: rem(320);
    margin-top: rem(130);
  }

  .modal-content {
    width: 100%;
    position: relative;
  }

  .modal-header {
    border: none;
    padding: 0 !important;

    .btn-close {
      position: absolute;
      right: rem(11);
      top: rem(11);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: rem(16);
    line-height: rem(24);
    font-weight: $fw_6;
    margin-bottom: rem(16);
    margin-top: rem(16);
  }

  &__text {
    font-size: rem(14);
    line-height: rem(20);
    width: rem(276);
    @include text-center;
  }

  .modal-footer {
    border: none;
    justify-content: center;
    padding: rem(26) 0 rem(21) !important;
  }

  &__btn-control {
    .btn-common + .btn-common {
      margin-left: rem(16);
    }
  }
}

// Style popup Confirm Archive
.request-archive__title.is-admin {
  margin-top: rem(16);
}

.create-project-modal {
  .modal-footer {
    .btn-close-modal {
      color: $col_gray-600;
    }
  }
}

//style for popup download file
.modal-download {
  .modal-dialog {
    width: rem(480);
    height: 90%;
    align-items: center;
    display: flex;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: none !important;
      margin: 0 auto;
    }
  }
}

.popup-confirm-dialog-acc-lock {
  .modal-dialog {
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .modal-content {
      min-width: rem(320);
      min-height: rem(179);
    }

    .modal-footer-custom {
      .btn-common + .btn-common {
        margin-left: rem(16);
      }
    }
  }
}
.modal-container {
  .modal-dialog {
    margin-top: rem(80);
  }
  .header-notification {
    padding: rem(16) !important;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    .modal-header-title {
      color: $col_gray-600;
      font-weight: $fw_5;
    }
    .title-OTP-font {
      font-size: rem(24);
    }
    .title-notification-font {
      font-size: rem(16);
      font-weight: $fw_5;
      color: $col_black;
    }
    .modal-header-close,
    .modal-close-no-border {
      display: flex;
      align-items: center;
      height: rem(24);
      box-sizing: border-box;
      cursor: pointer;
      p {
        font-size: rem(16);
        width: 100%;
        @include text-center;
      }
    }
    .modal-header-close {
      position: absolute;
      top: rem(16);
      right: rem(16);
    }
    .modal-close-no-border {
      img {
        width: rem(10);
        height: rem(10);
      }
    }
  }
  .modal-otp-body {
    .modal-otp-body-note {
      display: block;
      font-size: rem(18);
      color: $col_gray;
      font-weight: $fw_5;
      margin-bottom: rem(16);
    }
    .modal-otp-body-label {
      font-size: rem(14);
      color: $col_gray-600;
      font-weight: $fw_5;
      margin-bottom: rem(6);
      span {
        display: inline;
      }
    }
    .modal-otp-body-input {
      font-size: rem(16);
      color: $col_gray;
      font-weight: $fw_4;
      padding: rem(12) rem(16);
      width: 100%;
      border: rem(1) solid $col_gray-300;
      border-radius: rem(6);
    }

    .dont-have_code {
      font-weight: $fw_4;
      font-size: rem(14);
      margin-top: rem(8);
      .disable-resend-otp {
        pointer-events: none;
        cursor: default;
      }
      a {
        color: $col_blue;
      }
    }
  }
  .modal-footer {
    .btn-control {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .btn-common + .btn-common {
      margin-left: rem(16);
    }

    > * {
      margin: 0;
    }
  }

  .modal-body-notification {
    padding: 0 rem(16) 0 rem(16) !important;
    .body-notification {
      .notification {
        width: 100%;
        height: rem(356);
        border: rem(1) solid $col_gray-700;
        border-radius: rem(8);
        @include scroll-flexible;
        overflow-y: auto;
        padding: rem(16);
        font-size: rem(14);

        .link-note {
          display: flex;
          margin-top: rem(16);

          a {
            cursor: pointer;
            color: $col_blue;
            margin-right: rem(16);
          }
        }
      }
      .checkbox-group {
        margin: rem(16) 0 rem(24) 0;
      }
    }
  }
  .modal-footer-notification {
    padding: rem(16) !important;

    .btn-common + .btn-common {
      margin-left: rem(16);
    }
  }
  .btn-submit-otp {
    font-size: rem(16);
    color: $col_white;
    font-weight: $fw_5;
    font-family: "Helvetica Neue", sans-serif;
    border-radius: rem(4);
    padding-left: rem(16);
    padding-right: rem(16);
  }
}

.popup-confirm-dialog-user {
  .modal-dialog {
    max-width: rem(320);
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .modal-header {
    padding: rem(16) 0 rem(26) 0 !important;
    border-bottom: unset;
    @include text-center;
    position: relative;
    display: block;
    .modal-title {
      font-weight: $fw_6;
      font-size: rem(16);
      color: $col_black;
    }
    .btn-close {
      position: absolute;
      top: rem(10);
      right: rem(10);
    }
  }
  .modal-content {
    .modal-header-custom {
      width: 100%;
    }
    .content {
      font-weight: $fw_4;
      font-size: rem(14);
      color: $col_black;
      @include text-center;
      padding: 0 rem(22);
      width: 100%;
    }
  }
  .modal-footer {
    border-top: unset;
    justify-content: center;
    padding: rem(26) 0 0 0 !important;
    margin-bottom: rem(20);
    .btn-common + .btn-common {
      margin-left: rem(16);
    }
  }
  @media only screen and (min-width: 3840px) {
    .modal-dialog {
      height: auto;
      margin-top: rem(350);
    }
  }
}
