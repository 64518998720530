@import "assets/css/base";

.preview-not-rs-wrap {
  max-height: rem(500);
  color: $col_gray-600;

  .not-rs-title {
    font-weight: $fw_7;
    font-size: rem(16);
    align-items: center;
  }

  .not-rs-content {
    font-weight: $fw_4;
    font-size: rem(16);
    white-space: pre-wrap;
  }

  .attach-item {
    margin-bottom: rem(8);

    figure {
      position: relative;
      max-width: rem(200);
      min-height: rem(200);
      
      .image-preview {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left top;
      }
    }
    .attach-item-link {
      color: $col_blue;
      font-weight: $fw_5;
      font-size: rem(14);
      cursor: pointer;
      padding-top: rem(4);
    }

    .attach-item-video {
      width: 100%;
    }
  }

  .attachment-not {
    &-available {
      display: flex;
      margin-top: rem(4);
    
      img {
        width: rem(20);
        height: auto;
      }
    
      p {
        font-style: italic;
        font-size: rem(14);
        line-height: rem(24);
        margin-left: rem(4);
      }
    }
  }
}
