@import "assets/css/base";

.search-filter {
  position: relative;

  .search-box {
    padding: rem(7) rem(58) rem(7) rem(10);
    width: 100%;
    height: rem(40);
    font-size: rem(16);
    background-color: $col_white;
    border: rem(1) solid $col_gray-300;
    border-radius: rem(4);

    &:disabled {
      background-color: $col_gray-300;
      border-color: $col_gray-300;
      color: $col_gray;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover,
    &:focus {
      border-color: $col_dark-jeans !important;
      box-shadow: 0 0 rem(4) $col_blue;
      outline: unset;
    }
  }

  img {
    cursor: pointer;
  }

  .search-icon {
    position: absolute;
    right: rem(10);
    top: 50%;
    transform: translateY(-50%);
    width: rem(24);
    height: rem(24);
    border: none;
  }

  .clear-icon {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
    width: rem(16);
    height: rem(16);
    border: none;
  }

  .margin-position {
    right: 4% !important;
  }
}

.sm {
  .search-box {
    padding-right: rem(52);
    font-size: rem(14);
  }

  .clear-icon {
    position: absolute;
    right: 16%;
  }
}
