@import 'assets/css/_base.scss';

.image-container {
    display: flex;
    align-items: center;
    gap: rem(8);
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .embedded-image {
        position: relative;
        object-fit: contain;

        &-large {
            width: 100%;
            height: 100%;
        }
    }
}
