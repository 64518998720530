@import "assets/css/base";

.wrap {
  position: relative;
  background-color: $col_white;
  width: max-content;
  font-size: rem(16);
  z-index: 9999;
  padding: rem(16);
  box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.15);
  border-radius: rem(8);
  min-width: rem(200);
  text-align: left;

  li {
    padding: rem(8);
    cursor: pointer;
    &:hover {
      color: $col_blue;
    }
    &.active {
      color: $col_blue;
    }
  }
}
