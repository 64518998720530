@import "assets/css/_base.scss";

.information-participants {
  display: flex;
  flex-direction: column;
  border-bottom: none;
  overflow-y: auto;
  @include scroll-flexible;
  height: calc(100% - 10px);

  .empty-page {
    width: 100%;
    @include text-center;
    color: $col_gray-400;
    padding-bottom: rem(5);
    font-size: rem(14);
  }

  .information-participants-list {
    flex: 1;
    min-height: rem(60);
    max-height: 100%;
    margin: 0;
    padding: rem(5) rem(12) 0;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-flexible;

    .participant-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(12);
      padding: 0;
      .participants-email {
        display: flex;
        align-items: flex-start;
        width: calc(100% - rem(16));
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .label-title {
    font-size: rem(14);
    font-weight: $fw_5;
    margin: rem(10) 0;
  }

  .participants-info {
    overflow: hidden;

    .participants-name {
      font-size: rem(14);
      padding-right: rem(6);
    }

    .color-red {
      color: $col_red !important;
    }

    .participants-desc {
      font-size: rem(12);
      color: $col_gray;
      display: block;
      @include text-ellipsis;
    }
  }

  .participants-action {
    height: rem(20);
    position: relative;
    cursor: pointer;

    &:hover {
      .participants-drop-info {
        display: table;
      }
    }

    .participants-drop-info {
      display: none;
      position: fixed;
      top: rem(18);
      right: 0;
      z-index: 999;

      .participants-drop-info-group {
        position: relative;
        width: rem(220);
        max-height: rem(110) !important;
        overflow-x: hidden;
        margin: 0;
        padding: rem(10);
        text-align: left;
        list-style-type: none;
        background-color: $col_white;
        -webkit-background-clip: padding-box;
        border-radius: rem(4);
        outline: none;
        box-shadow: 0 rem(2) rem(8) rgb(0 0 0 / 15%);
        -webkit-transform: translateZ(0);
        @include scroll-flexible;

        .participant-item-group {
          @include flex-center;
          padding: rem(4);
          font-weight: $fw_4;
          font-size: rem(14);
          line-height: rem(24);
          transition: all 0.3s;
          cursor: default;

          img {
            height: rem(16);
            margin-right: rem(8);

            &.ic-email {
              width: rem(22);
              height: auto;
            }
          }

          span {
            display: block;
            width: 100%;
            @include text-ellipsis;
          }
        }
      }
    }
  }

  .spinner-container {
    flex: 1;
    @include flex-center;
  }
}

.container-table-view {
  margin-top: rem(10);
  border-right: rem(1) solid $col_gray-300;

  .information-participants-label {
    margin: 0 rem(16);
  }

  .information-participants-list {
    margin: rem(10) rem(16) 0;
  }

  .spinner-container {
    height: rem(100);
  }
}

@media print {
  .information-participants {
    height: rem(200);
  }
}
