@import "assets/css/base";

.heatmap-cell {
  text-align: center !important;
  cursor: pointer;

  span {
    position: absolute;
    bottom: calc(100% + rem(20));
    left: 0;
    animation-delay: 0s !important;
    display: none;
    background: $col_gray-750;
    box-shadow: $box_shadow;
    border-radius: rem(4);
    width: rem(180);
    padding: rem(16);
    z-index: 99;
    @include text-center;
    color: $col_white;
    font-weight: $fw_7;
    font-size: rem(14);
  }

  .count-msg {
    display: none;
  }

  img {
    margin: rem(-1);
  }

  &-info {
    position: relative;
  }

  &:hover > .heatmap-cell-info .total-msg {
    display: block;
    text-align: left;

    p {
      font-weight: $fw_4;
      padding-bottom: rem(8);
    }
  }
}

.heatmap-cell-disable {
  pointer-events: none;
}

@media print {
  .heatmap-cell {
    .count-msg {
      display: block;
      max-width: rem(20);
      min-width: rem(20);
      font-size: rem(8);
    }

    img {
      display: none;
    }
  }
}
