@import "assets/css/base";

.no-highlight {
  color: $col_gray-400;
  white-space: pre !important;
}

.highlight {
  font-weight: $fw_7 !important;
  white-space: pre !important;
}
