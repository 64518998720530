@import "assets/css/base";

.et-header {
  margin-top: rem(16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-timeline {
  margin-top: rem(24);
  min-height: rem(903);

  display: flex;

  .et-quick-select {
    margin-right: rem(32);
    min-width: rem(320);
  }

  .et-overview {
    display: flex;
    width: 100%;
  }
}

@media print {
  .et-quick-select {
    width: 23% !important;
    min-width: auto !important;
    margin-right: rem(15) !important;
  }

  .et-overview {
    width: 77% !important;
    display: block !important;
  }

  :global(.quick-select__content) {
    padding: rem(16) rem(10) !important;
  }
}
