@import "assets/css/_base.scss";

.wrap {
  @include flex-between;
  margin-bottom: rem(8);
  color: $col_gray-400;

  dt {
    font-weight: $fw_4;
    max-width: 70%;
  }

  dd {
    margin-left: rem(4);
    color: $col_gray-600;
    @include text_ellipsis;
    max-width: 60%;
  }
}
