@import "assets/css/_base.scss";

.call-log-item {
  @include text-ellipsis;
  .call-log-label {
    font-size: rem(13);
    display: flex;
    align-items: center;
    color: $col_gray;
    padding-bottom: rem(8);
  }

  .call-log-value,
  .location-value {
    font-size: rem(16);
    display: block;
    align-items: center;
    color: $col_gray-600;
    @include text-ellipsis;

    img {
      margin-right: rem(8);
      width: rem(24);
      height: rem(24);
    }

    a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a,
    a:visited,
    a:hover {
      color: $col-blue;
    }
  }

  .location-value {
    display: block;
    white-space: nowrap;
  }
  .call-log-value-block {
    display: block !important;
  }

  padding-bottom: rem(16);

  &:last-child {
    padding-bottom: 0;
  }

  .list-container div:not(:first-child) {
    margin-top: rem(16);
  }

  .participants,
  .data-source {
    border-radius: rem(8);

    ul {
      height: fit-content;
    }
  }

  .participants-title {
    font-size: rem(13);
    display: flex;
    align-items: center;
    color: $col_gray;
    padding-bottom: rem(8);
  }

  .unknown {
    font-size: rem(16);
    display: flex;
    align-items: center;
    color: $col_gray-600;
    overflow-wrap: anywhere;
  }
}
