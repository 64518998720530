@import "assets/css/base";

.tag-management-page {
  height: calc(100vh - rem(96));
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  .tag-header {
    display: flex;
    align-items: center;
    margin-top: rem(8);

    &-title {
      font-size: rem(32);
      line-height: rem(40);
      color: $col_gray-600;
      margin-right: rem(16);
    }
  }

  .tag-management-body {
    flex: 1;
    height: 100%;
    background-color: $col_white;
    box-shadow: $box_shadow_icon;
    border-radius: rem(8);
    margin: rem(24) 0 rem(40);
    padding: rem(24);
    overflow: hidden;

    h3 {
      font-weight: $fw_5;
      font-size: rem(18);
      line-height: rem(28);
      color: $col_gray-600;
      margin-bottom: rem(12);
    }
    .tags-table {
      height: calc(100% - rem(56));
      margin-top: rem(16);
    }

    .available-tags,
    .selected-tags {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 45%;

      &-content {
        position: relative;
        width: 100%;
        background-color: $col_white;
        border: rem(1) solid $col_gray-300;
        border-radius: rem(8);
        margin-bottom: rem(24);
        padding: rem(16);
        overflow: auto;
        flex: 1;
        @include scroll-flexible;

        .loading {
          position: absolute;
          top: 50%;
          left: 50%;
        }
      }

      .no-result {
        display: flex;
        align-items: center;
        height: 100%;
      }

      &-result {
        display: flex;
        align-items: center;
        width: 100%;
        height: rem(48);
        background-color: $col_white;
        border: rem(1) solid $col_gray-300;
        border-radius: rem(6);
        padding: rem(12) rem(16);
        margin-bottom: rem(16);

        &:hover {
          background-color: $col_gray-100;
          cursor: pointer;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-info {
          width: 70%;
          display: flex;
          align-items: center;

          .checkbox {
            margin-right: rem(16) !important;
            width: rem(16);
            height: rem(16);
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;

            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: rem(16);
              height: rem(16);
              background-color: $col_white;
              border: rem(1) solid $col_gray-300;
              border-radius: rem(2);
            }

            &:checked::before {
              position: absolute;
              top: 0;
              left: 0;
              background: url(~/public/images/checked-checkbox.svg)
                center/rem(16) no-repeat;
            }
          }

          span {
            font-weight: $fw_4;
            font-size: rem(16);
            line-height: rem(24);
            color: $col_gray-600;
          }

          &-color {
            width: rem(24);
            height: rem(24);
            border-radius: rem(2);
            margin-right: rem(16);
          }
        }

        &-action {
          width: 30%;
          display: flex;
          justify-content: flex-end;

          img {
            margin-left: rem(16);
            cursor: pointer;
          }
        }
      }
    }

    .tags-option {
      margin: rem(70) rem(24);

      .tags-option-icon {
        margin-bottom: rem(64);
      }
    }
  }
}
