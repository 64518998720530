@import "assets/css/base";

.tab-list {
  .tab-item {
    display: inline-table;
    text-align: center;

    font-weight: $fw_4;
    margin-right: rem(16);
    padding: 0 rem(6) 0;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      color: $col_gray-400;
    }

    span.normal {
      margin: 0;
      &:hover {
        color: $col_blue;
      }
    }

    span.disabled {
      margin: 0;
    }

    span.arrow-down {
      position: relative;
      margin-right: rem(8);
      &::after {
        content: "";
        position: absolute;
        right: rem(-15);
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: rem(4) solid transparent;
        border-right: rem(4) solid transparent;
        border-top: rem(6) solid $col_dark-charcoal;
        clear: both;
      }
    }

    &.active {
      border-bottom: rem(1) solid $col_blue;
      span {
        color: $col_blue;
        font-weight: 700;
      }
    }

    .children {
      position: absolute;
      z-index: 999;
    }
  }

  .lg {
    font-size: rem(16);
  }

  .md {
    font-size: rem(14);
  }
}
