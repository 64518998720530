@import "assets/css/base.scss";

.wrap {
  min-height: rem(528);

  .no-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  table {
    width: 100%;
    height: auto;
    table-layout: fixed;

    thead,
    tbody tr {
      width: 100%;
    }

    thead {
      background: $col_gray-50;
      height: rem(48);
      border-bottom: rem(1) solid $col_gray-800;
      overflow: hidden;

      th {
        text-align: left;
        font-weight: $fw_7;
        font-size: rem(12);
        line-height: rem(16);
        color: $col_gray-400;
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: rem(8);
        }

        &:last-child {
          border-top-right-radius: rem(8);
        }

        span:not(.no-sort) {
          padding-right: rem(14);
          position: relative;
          cursor: pointer;
          user-select: none;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: rem(5);
            width: 0;
            height: 0;
            border-left: rem(4) solid transparent;
            border-right: rem(4) solid transparent;
            border-top: rem(6) solid $col_gray-600;
            clear: both;
          }
        }

        :global(span.sort-asc::after) {
          border-top: none;
          border-bottom: rem(6) solid $col_gray-600;
        }

        :global(span.sort-desc::after) {
          border-top: rem(6) solid $col_gray-600;
          border-bottom: none;
        }
      }
    }

    tbody {
      tr {
        text-align: left;
        font-weight: $fw_4;
        font-size: rem(12);
        line-height: rem(16);
        height: rem(48);

        td {
          color: $col_gray-600;
          @include text-ellipsis;

          :global(.dropdown-react-select) {
            > div {
              min-height: rem(28);
            }
          }

          .identifier,
          .likely-name {
            white-space: pre;
          }

          .name-percent {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }
      }

      td.overflow-unset {
        overflow: unset;
      }

      tr:hover {
        background: $color_lavender;
      }
    }

    th,
    td {
      padding-left: rem(16);

      &:first-child {
        width: 12%;
      }

      &:nth-child(2) {
        width: 10%;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 14%;
      }

      &:nth-child(5) {
        padding-left: rem(24);
        width: 9%;
      }

      &:nth-child(6) {
        width: 15%;
        padding-right: rem(10);
      }

      &:nth-last-child(2),
      &:nth-last-child(3),
      &:last-child {
        width: 8%;
      }
    }
  }
}
