@import "assets/css/_base.scss";

.wrap {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  h5 {
    font-size: rem(20);
    font-weight: $fw_5;
    color: $col_gray-600;
    margin-bottom: rem(16);
  }

  .no-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .source-item {
    padding: rem(12);
    box-shadow: $box_shadow_icon;
    border-radius: rem(8);
    min-height: 100%;
    max-height: rem(776);
    background-color: $col-white;

    &-header {
      margin-bottom: rem(1);
      @include flex-between;
      height: 10%;

      .source-name {
        display: flex;
        align-items: center;
        width: 90%;
        .source-info-img {
          position: relative;
          display: flex;
          min-width: rem(40);
          height: rem(40);
          background-color: $col_green-300;
          border-radius: rem(4);

          &-none {
            background-color: $col_begonia;
          }

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      img {
        width: rem(24);
        height: rem(24);
        cursor: pointer;
      }

      .source-info-name {
        font-size: rem(24);
        margin-left: rem(12);
        @include text-ellipsis;
      }
    }

    &-body {
      font-size: rem(16);
      line-height: rem(24);
      font-weight: $fw_4;
      flex: 1;
      height: 80%;
      overflow-y: scroll;
      @include scroll-flexible;

      .source-device {
        margin-bottom: rem(24);
        
        p {
          color: $col_gray-600;
        }

        .source-type {
          display: flex;
          align-items: center;

          img {
            width: rem(24);
            margin-right: rem(8);
          }
        }

        .source-id {
          @include flex-between;
          margin-bottom: rem(8);
          color: $col_gray-600;
        }

        .source-description,
        .identifier {
          @include text-ellipsis;
        }
      }

      .source-info {
        margin-bottom: rem(24);

        &-user {
          height: fit-content;
        }

        .info-item {
          @include flex-between;
          margin-bottom: rem(8);
        }

        .info-item-child {
          margin-left: rem(24);
        }
      }
    }

    &-footer {
      @include flex-center;
      gap: rem(16);
      height: 10%;
    }
  }
}
