@import "assets/css/base";

.info-data-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    overflow-y: auto;
    @include scroll-flexible;
    max-height: rem(920);

    .info-tab-list {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(20);
        height: auto;
    }

    .info-result-list {
        margin-top: rem(6);
        overflow-y: auto;
        padding-right: rem(15);
        height: 100%;
        @include scroll-flexible;

        .panel-container {
          border-radius: rem(8) !important;        
          box-shadow: none !important;
          border-color: $col_gray-300 !important;
        }

        .panel-container:hover {
          background-color: unset !important;
          box-shadow: none !important;
          transform: none !important;
          border-color: $col_gray-300 !important;
        }

        .close-icon {
          position: absolute;
          top: rem(10);
          right: rem(10);
          cursor: pointer;
    
          img {
            width: rem(16);
            height: rem(16);
          }
        }

        .no-data {
          padding: rem(8) rem(16);
          font-size: rem(16);
          color: $col_gray-600;
          line-height: rem(24);
          font-style: italic;
          text-align: center;
        }
    }

    .panel-checkbox-image {
      position: absolute;
      right: -1.5%;
      cursor: pointer;
    }

    .panel-checkbox-container {
        position: absolute;
        top: 3%;
        right: 0%;
        width: rem(160);
        height: auto;
        background: $col_white;
        box-shadow: $box_shadow;
        border-radius: rem(4);
        padding: rem(12);
        margin-top: rem(8);
        z-index: 10000;

        .panel-item {
            display: flex;
            align-items: center;
        }

        .panel-checkbox {
            appearance: none;
            width: 16px;
            height: 16px;
            border: 1px solid #ccc;
            border-radius: 3px;
            background-color: #f0f0f0;
            position: relative;
            cursor: pointer;
            transition: background-color 0.3s ease;
            display: inline-flex;
            align-items: center;
          }
          
          .panel-checkbox:checked {
            background-color: #1580EF;
            border-color: #1580EF;
          }
          
          .panel-checkbox:checked::before {
            content: '✔'; 
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 10px;
          }
          
          .panel-checkbox:hover {
            background-color: #e0e0e0;
            border-color: #e0e0e0;
          }
          
          .panel-checkbox:disabled {
            background-color: #d3d3d3;
            border-color: #b0b0b0;
            cursor: not-allowed;
          }

        .panel-label {
            display: inline-flex;
            align-items: center;
            font-size: rem(14);
            margin-left: rem(6);
        }
    }
}

:global {
    .e-panel-container .e-panel-header { 
        font-size: rem(14) !important;
        font-weight: $fw_5 !important;
        color: black !important;
        padding-top: rem(10) !important;
        margin-bottom: rem(10) !important;
    }
}
