@import "assets/css/base";

.heatmap-page {
  padding: 0 rem(180) rem(40) rem(180);
  min-height: calc(100vh - rem(96));

  .heatmap-page-content {
    display: flex;
    height: rem(880);

    .quick-select-heatmap {
      width: 21%;
      padding-right: rem(32);
      height: 100%;
    }

    .heatmap-wrap {
      flex: 1;
      width: calc(100% - 35.2%);
      height: 100%;

      .heatmap-container {
        background: $col_white;
        box-shadow: $box_shadow_icon;
        border-radius: rem(8);
        padding: rem(16);
        position: relative;
        min-height: rem(880);
        height: fit-content;

        .heatmap-header {
          width: 100%;
          @include flex-between;
          padding-bottom: rem(16);
        }

        .heatmap-title {
          font-weight: $fw_5;
          font-size: rem(18);
          color: $col_gray-600;
        }

        .loading {
          position: absolute;
          top: 50%;
          left: 50%;
        }

        .heatmap-empty-data {
          @include text-center;
          height: rem(792);
          width: 100%;
          border: rem(1) solid $col_gray-500;
          border-radius: rem(8);
        }
      }
    }
  }
}

@media print {
  .heatmap-page {
    padding: rem(8) rem(20) 0;

    .heatmap-page__sub-heading {
      font-size: rem(24);
    }

    .heatmap-page-content {
      width: 100%;

      .heatmap-wrap {
        width: 100%;
      }
      .quick-select-heatmap {
        width: 29%;
      }
      .heatmap-wrap {
        width: 71%;
      }
    }
  }
}
