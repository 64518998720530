@import 'BaseAttachmentRenderer.module.scss';

.spreadsheet-renderer {
    padding: rem(8);
    height: 100%;
}

.spreadsheet-renderer-hidden {
    visibility: hidden;
}
