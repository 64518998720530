@import "assets/css/_base.scss";

.search-wrapper .input-autocomplete .suggestions.suggestion-users {
  max-height: rem(300);

  .name {
    font-weight: $fw_4;
    font-size: rem(16);
    line-height: rem(24);
    color: $col_gray-600;
  }

  .email {
    font-weight: $fw_3;
    font-size: rem(12);
    line-height: rem(16);
    color: $col_gray;
  }
}
