@import "assets/css/_base.scss";

.mb-16 {
  margin-bottom: rem(16);
}

.active-project {
  .main-container-projects {
    min-height: 100%;
    border-right: solid rem(1) $col_gray-300;
    padding: rem(40) rem(32) rem(40) 0;
    float: left;
  }

  .main-container-projects {
    &.main-container-auto-width {
      padding: rem(40) rem(32) 0 0;
    }
  }

  .active-project__dropdown {
    min-width: rem(254);
    height: rem(40);
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: rem(16);
    border: rem(1) solid $col_gray-300;
    border-radius: rem(4);
    color: $col_gray-600;
  }

  .projects .dropdown-menu {
    width: 100%;
    box-shadow: $box_shadow;
    border-radius: rem(4);
    border: none;
    padding-bottom: 0;
    max-height: 60vh;
    overflow-y: auto;
  }

  .projects .active-project__dropdown + .dropdown-menu .dropdown-item {
    font-size: rem(16);
    color: $col_gray-600;
    padding: rem(5);
    white-space: pre-wrap;
  }

  .entity-navigate {
    .entity-search {
      display: flex;
      gap: rem(16);
      .search-wrapper {
        .icon-search {
          right: rem(5);
        }

        .input-autocomplete {
          input.btn-input {
            padding-right: rem(50);
          }
        }
      }
    }
  }

  .print-dropdown {
    margin-left: rem(16);
  }

  .btn-create-project {
    background: $col_white;
    border: rem(1) solid $col_blue;
    box-sizing: border-box;
    border-radius: rem(4);
    font-weight: $fw_4;
    font-size: rem(16);
    line-height: rem(24);
    color: $col_blue;
    padding: rem(8) rem(16);
    margin-left: rem(16);
    &:focus-visible {
      outline: none;
    }
  }

  .projects {
    margin-bottom: rem(20);
    &-count {
      font-size: rem(14);
      line-height: 1;
      color: $col_gray;
      text-align: right;
      margin-bottom: rem(15);
    }
  }

  .project {
    &-ttl {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: rem(24);
      line-height: rem(32);
      margin-bottom: rem(20);

      figure {
        height: rem(24);
        position: relative;
        margin-bottom: rem(8);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      h4 {
        font-size: rem(24);
        font-weight: $fw_4;
        flex: 0 0 calc(100% - rem(40));
        @include text-ellipsis;
      }
    }

    &-items {
      font-size: rem(16);

      dl {
        @include flex-between;
        margin: rem(10) 0;

        dt {
          color: $col_gray-400;
          line-height: rem(24);
          font-weight: $fw_4;
        }

        dd {
          color: $col_gray-600;
        }
      }

      &_users-more {
        background-color: $col_blue;
        width: rem(31);
        height: rem(31);
        border-radius: 50%;
        color: $col_white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /* dropdown menu show member */
      &-members {
        ul {
          top: 100%;
          bottom: unset !important;
          left: rem(5);
        }
      }
    }
  }

  .main-activities {
    &-ttl {
      font-size: rem(18);
      line-height: rem(28);
      margin-bottom: rem(12);
    }

    &-list {
      display: flex;
      flex-flow: column;
    }

    &-box {
      background: $col_gray-100;
      border-radius: rem(8);
      padding: rem(16);
      flex: 1;
      margin-bottom: rem(16);

      &_ttl {
        font-size: rem(16);
        line-height: rem(24);
        display: flex;
        align-items: center;

        figure {
          margin-right: rem(8);
          width: rem(24);
          height: rem(24);
          overflow: hidden;
          border-radius: rem(4);

          img {
            width: 100%;
          }
        }
      }

      &_content {
        margin-top: rem(18);
        color: $col_gray-600;

        dt {
          font-weight: bold;
          font-size: rem(12);
          line-height: rem(16);
        }

        dd {
          font-size: rem(14);
          margin-top: rem(6);

          a {
            color: $col_blue;
            font-style: italic;
          }
        }
      }

      .post-time {
        color: $col_gray;
        font-size: rem(12);
        text-align: right;
        display: block;
        margin-top: rem(8);
      }
    }
  }

  .main-activities.main-activities-modified {
    padding: rem(40) 0 0 rem(32);

    h3 {
      margin-bottom: rem(12);
    }
  }

  .avatar-count {
    width: rem(31);
    height: rem(31);
    object-fit: cover;
    border-radius: 50%;
    @include text-center;
    background: $col_blue;
    margin-left: rem(-8);
    position: relative;

    p {
      font-size: rem(13);
      width: 100%;
      color: $col_white;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover .dropdown-menu {
      display: block;
      z-index: 1000;
    }
  }

  .project-no-results {
    width: 100%;
    @include text-center;
    margin-top: rem(25);
    font-size: rem(24);
    font-weight: normal;
    color: $col_gray-400;
  }

  .not-found-image {
    margin-top: rem(160);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project-padL38 {
    padding-left: rem(38);
  }

  .project-padR17 {
    padding-right: rem(17);
  }

  .project-detail-members {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  .project-detail-members_item {
    display: inline-block;
    font-size: 0;
    padding: 0 0 rem(7);
    position: relative;

    &:hover {
      cursor: pointer;

      .dropdown-menu {
        display: block;
        z-index: 1000;
      }
    }

    a {
      width: rem(31);
      height: rem(31);
      border-radius: 50%;
      display: inline-block;
      overflow: hidden;
      -webkit-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
      margin-left: rem(-8);
      -webkit-transition: all 0.3s 0s ease;
      transition: all 0.3s 0s ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    figure {
      img {
        width: rem(31) !important;
        height: rem(31);
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        text-indent: -9999px;
      }
    }

    .dropdown-menu {
      width: rem(282);
      padding: rem(16);
      background: $col_white;
      box-shadow: $box_shadow;
      border: none;
      border-radius: rem(8);
      position: absolute;
      top: 100%;
      left: rem(5);
      display: none;
      max-height: rem(220);
      overflow-y: auto;
      overflow-x: hidden;

      @include scroll-flexible;

      li {
        display: flex;
        align-items: center;
        font-size: rem(16);
        padding: rem(5) 0;

        figure {
          margin-right: rem(5);
          width: rem(31);
          height: rem(31);
          object-fit: cover;
          margin-left: 0;
          flex: 0 0 rem(31);
        }

        span {
          flex: 0 0 100%;
          color: $col_gray;
          font-size: rem(16);
          margin-left: rem(30);
        }
      }
    }
  }

  .dropdown-menu-info {
    width: rem(220) !important;
    margin-left: rem(-200) !important;
  }

  .lazyload-wrapper {
    display: inline-block;
    font-size: 0;
    width: 100%;
    background: $col_white;
    border: rem(1) solid $col_gray-10;
    box-sizing: border-box;
    border-radius: rem(8);
    padding: rem(20) rem(24);
    min-height: rem(350);
    height: 100%;
  }

  .client-filter {
    background: $col_white;
    box-sizing: border-box;
    border-radius: rem(4);
    margin-right: auto;
    margin-left: rem(16);
    select {
      width: rem(180);
    }
  }
}

.create-project-modal {
  &.is-submitting {
    .modal-content {
      position: relative;
    }
  }

  .modal-title {
    font-weight: $fw_5;
    font-size: rem(24);
    line-height: rem(32);
    color: $col_gray-600;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0 !important;
  }

  .modal-body {
    .form-label {
      font-weight: $fw_5;
      font-size: rem(14);
      line-height: rem(20);
      color: $col_gray-600;
    }

    .select-client-label {
      display: block;
      font-weight: $fw_4;
      font-size: rem(24);
      line-height: rem(32);
      color: $col_gray-600;
    }

    .form-text {
      line-height: rem(20);
      font-size: rem(12);

      p {
        padding-top: rem(6);
      }
    }

    select,
    input {
      border: rem(1) solid $col_gray-300;
      box-sizing: border-box;
      border-radius: rem(6);
      padding: rem(11) rem(16);
      font-weight: $fw_4;
      font-size: rem(16);
      line-height: rem(24);
      color: $col_gray-600;
    }

    .form-select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: rem(1);
      text-overflow: "";
      background-image: url(~/public/images/down-arrow-3.svg);
      background-size: rem(10);
      background-position: rem(420);
      &::-ms-expand {
        display: none;
      }
    }
  }

  .modal-footer {
    .btn-common + .btn-common {
      margin-left: rem(16) !important;
    }
    .create-project-btn {
      font-weight: $fw_5;
    }

    & > * {
      margin: 0;
    }
  }

  .modal-dialog {
    max-width: rem(480);
    margin: rem(-160) auto;
  }
}

@media (max-width: 1039px) {
  .entity-navigate {
    flex-wrap: wrap;
  }

  .active-project .entity-search {
    margin-left: 0;
  }
}

@media (max-width: 1500px) {
  .active-project {
    .entity-navigate {
      .entity-search {
        .btn-input {
          width: rem(250);
        }
      }
    }
  }
}

@media print {
  .active-project {
    .main {
      > .row {
        margin: 0 !important;
      }
    }

    .row > * {
      min-width: rem(344);
    }

    .main-container-projects,
    .main-activities {
      padding-top: rem(16) !important;
    }

    .main-container,
    .main-container-projects {
      width: 77% !important;
      padding: rem(10);
      float: none !important;
    }

    .main-activities {
      width: 23% !important;
      min-width: unset !important;

      .activities-box {
        margin-bottom: rem(11);
      }

      .project-detail__last-activity {
        width: 100% !important;
      }
    }

    .lazyload-wrapper {
      padding: rem(16);
      width: 100%;
      min-height: fit-content !important;

      .project-ttl {
        margin-bottom: rem(12);
        h4 {
          font-size: rem(24);
        }
      }
    }
  }

  @-moz-document url-prefix() {
    .main-container-projects,
    .main-activities {
      padding-top: rem(16) !important;
    }

    .main-container-projects {
      padding-right: rem(16) !important;
    }
    .main-activities {
      padding-left: rem(16) !important;
      min-width: unset !important;
      width: fit-content !important;

      .activities-box {
        margin-bottom: rem(24);
        height: fit-content !important;

        img {
          height: rem(18);
          margin-top: rem(4);
        }
        span {
          font-size: rem(15);
        }
        p {
          font-size: rem(12);
        }
      }
    }


    .active-project {
      .row > * {
        min-width: rem(265);
        padding: 0 rem(8);
        margin-top: rem(16) !important;
      }

      .projects-count {
        margin-bottom: rem(20);
      }

      .lazyload-wrapper {
        padding: rem(16);
        width: fit-content;
        min-height: fit-content !important;

        .project-ttl {
          margin-bottom: rem(12);
          h4 {
            font-size: rem(24);
          }
        }
      }

      dl {
        margin: 0 !important;
        dd, dt {
          font-size: rem(14);
        }
      }
    }
  }
}
