@import "assets/css/_base.scss";

.container {
  height: calc(100% - 10px);
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  @include scroll-flexible;
  padding: 0 rem(12) 0;
}

.tag-item {
  display: flex;
  align-items: center;
  margin: rem(7) 0;
}

.tag-checkbox {
  appearance: none;
  width: rem(16);
  height: rem(16);
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f0f0f0;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  margin-right: rem(12);
}

.tag-checkbox:checked {
  background-color: #1580EF;
  border-color: #1580EF;
}

.tag-checkbox:checked::before {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 10px;
}

.tag-checkbox:hover {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.tag-checkbox:disabled {
  background-color: #d3d3d3;
  border-color: #b0b0b0;
  cursor: default;
}

.tag-color {
  display: inline-flex;
  align-items: center;
  width: rem(16);
  height: rem(16);
  border-radius: rem(3);
}

.tag-name {
  display: inline-flex;
  align-items: center;
  font-size: rem(14);
  margin-left: rem(6);
}

.not-tagged {
  display: inline-flex;
  align-items: center;
  width: rem(13);
  height: rem(13);
  border-radius: rem(2);
  margin-left: rem(3);
  font-size: 1.2rem;
}