@import "assets/css/_base.scss";

.card-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: rem(12);
  margin-right: rem(12);
}

.card-item-header {
  background-color: #f5f5f5;
  padding: rem(8) rem(8);
  border-bottom: 1px solid #ddd;
}

.card-item-body {
  padding: rem(12);

  .info-item {
    @include flex-between;
    margin-bottom: rem(8);

    .info-label {
      color: $col_gray-400;
    }

    .info-value {
      color: $col_gray-600;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: rem(4);
      position: relative;

      .view-detail-app {
        display: flex;
        align-items: center;
        z-index: 999;
      }
    }
  }
}

.info-item-child {
  margin-left: rem(12);
}