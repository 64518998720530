@import "assets/css/base";

.wrap {
  .header {
    @include flex-between;

    h2 {
      font-size: rem(32);
      font-weight: $fw_4;
    }

    &-control {
      display: flex;
      z-index: 9;

      > div {
        margin-left: rem(16);
      }

      .delete-icon {
        margin: 0 rem(16);
        @include flex-center;
        width: rem(40);
        height: rem(40);
        border-radius: rem(4);
        border: rem(1) solid $col_orange;
        cursor: pointer;
      }

      .disable {
        border: rem(1) solid $col_gray;
        cursor: not-allowed;
      }

      .option-view {
        display: flex;
        align-items: center;
        height: rem(40);
        position: relative;

        img {
          cursor: pointer;

          &:first-child {
            margin-right: rem(10);
          }
          &:last-child {
            margin-left: rem(10);
          }
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: rem(1);
          height: rem(32);
          background-color: $col_gray-300;
        }
      }
    }
  }
}

