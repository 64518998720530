@import "assets/css/_base.scss";

.wrap {
  position: relative;
  min-height: rem(776);

  .no-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .source-control {
    @include flex-between;
  }

  .total-source {
    color: $col_gray;
    font-size: rem(14);
    font-weight: $fw_4;
    display: flex;
    justify-content: flex-end;
    margin: rem(10) 0;
  }

  table {
    border: rem(1) solid $col_gray-800;
    border-radius: rem(8);
    box-sizing: border-box;
    border-collapse: unset;
    border-spacing: inherit;
    flex: 1;

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    th, td {
      padding: 0 rem(16);
      color: $col_gray-400;
      font-size: rem(12);
      min-width: 16%;
      max-width: 20%;

      &:first-child {
        padding-left: rem(24);
      }

      &:first-child, // Device Name
      &:nth-child(2) { // Custodian
        width: 14%;
      }

      &:nth-child(3) // Created DateTime
      &:nth-child(4) { // Added DateTime
        width: 15%;
      }

      &:nth-child(5), // Data Status
      &:nth-child(6) { // ImportStatus
        width: 10%;
      }

      &:nth-child(7) { // Progress
        width: 12%;
      }

      &:nth-child(8) { // Imported Records
        width: 8%;
      }

      &:nth-child(9) { // Imported Size
        width: 8%;
      }

      &:last-child { // Action
        width: 5%;
      }
    }

    td {
      @include text-ellipsis;
    }

    thead {
      height: rem(56);
      background: $col_gray-50;
      border-bottom: rem(1) solid $col_gray-800;
      border-top-left-radius: rem(8);
      border-top-right-radius: rem(8);

      th {
        font-weight: $fw_7;
        white-space: normal;
        line-height: 1.2;
        vertical-align: middle;
        text-overflow: initial;
        overflow: visible;

        span:not(.no-sort) {
          padding-right: rem(14);
          position: relative;
          cursor: pointer;
          user-select: none;
          display: inline-flex;
          align-items: center;
          min-height: rem(32);

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-left: rem(4) solid transparent;
            border-right: rem(4) solid transparent;
            border-top: rem(6) solid $col_gray-600;
            clear: both;
          }
        }

        :global(span.sort-asc::after) {
          border-top: none;
          border-bottom: rem(6) solid $col_gray-600;
        }

        :global(span.sort-desc::after) {
          border-top: rem(6) solid $col_gray-600;
          border-bottom: none;
        }
      }
    }

    tbody {
      display: block;
      height: rem(720);
      overflow-x: hidden;
      overflow-y: auto;
      @include scroll-flexible;

      tr {
        height: rem(48);
        font-size: rem(12);
      }

      tr:hover,
      .active {
        background: $color_lavender;
        cursor: pointer;
      }

      td {
        position: relative;
        .loading {
          position: absolute !important;
          top: 60% !important;
          left: 60% !important;
        }
      }
    }

    tbody, td:last-child {
      overflow: unset !important;
    }
  }
}

@media print {
  .wrap {
    min-height: fit-content;

    .total-source {
      margin: rem(5) 0;
    }
  }
}
