@import "assets/css/base";

.wrap {
  padding: rem(16);
  text-align: center;

  .title {
    font-size: rem(16);
    font-weight: $fw_5;
    padding-bottom: rem(10);
  }

  .footer {
    font-size: rem(14);
    padding-top: rem(10);
    white-space: pre-wrap;

    &-loading {
      margin-top: rem(20);
      @include flex-center;

      .fade-loader {
        top: rem(18) !important;
        left: rem(14) !important;
      }
      
      p {
        font-size: rem(12);
        font-style: italic;
        color: $col_blue;
      }
    }
  }
}
