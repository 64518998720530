@import "assets/css/base";

.is-saved-search-page {
  padding-bottom: rem(40) !important;
  min-height: calc(100vh - rem(96));

  .is-header {
    margin-top: rem(8);
    @include flex-between;

    .is-header-title {
      font-weight: $fw_4;
      font-size: rem(32);
      line-height: rem(40);
      color: $col_gray-600;
    }
  }

  button {
    padding: rem(6) rem(16);
    margin-left: rem(16);

    img {
      margin-right: rem(6) !important;
    }
  }

  .saved-search-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: rem(697);
    background: $col_white;
    box-shadow: $box_shadow_icon;
    border-radius: rem(8);
    padding: rem(16) rem(24) rem(24);
    margin-top: rem(24);
    position: relative;

    &-box {
      display: flex;
      justify-content: flex-end;
    }

    .count-saved-search {
      @include flex-end;
      span {
        margin: rem(10) 0;
        font-weight: $fw_4;
        font-size: rem(14);
        line-height: rem(24);
        color: $col_gray;
      }
    }

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .no-result {
      margin: auto;
    }

    .project-saved-search {
      @include flex-end;
      p {
        margin-right: rem(14);
        font-weight: $fw_5;
        font-size: rem(14);
        line-height: rem(20);
        color: $col_gray-600;
      }
    }

    .table-saved-search {
      border: rem(1) solid $col_gray-800;
      box-sizing: border-box;
      border-radius: rem(8);
      border-collapse: unset;
      border-spacing: inherit;
      position: relative;
      flex: 1;

      table {
        width: 100%;
        height: auto;
        table-layout: fixed;

        thead,
        tbody tr {
          width: 100%;
        }

        thead {
          background: $col_gray-50;
          height: rem(48);
          border-bottom: rem(1) solid $col_gray-800;
          overflow: hidden;

          th {
            text-align: left;
            font-weight: $fw_7;
            font-size: rem(12);
            line-height: rem(16);
            color: $col_gray-400;
            white-space: nowrap;

            &:first-child {
              border-top-left-radius: rem(8);
            }

            &:last-child {
              border-top-right-radius: rem(8);
            }

            a, span:not(.no-sort) {
              padding-right: rem(14);
              position: relative;
              white-space: nowrap;
              cursor: pointer;
              color: $col_gray-400;

              &::after {
                content: "";
                position: absolute;
                right: 0;
                top: rem(5);
                width: 0;
                height: 0;
                border-left: rem(4) solid transparent;
                border-right: rem(4) solid transparent;
                border-top: rem(6) solid $col_gray-600;
                clear: both;
              }
            }

            .combine::after {
              content: none !important;
            }

            :global(span.sort-asc::after) {
              border-top: none;
              border-bottom: rem(6) solid $col_gray-600;
            }

            :global(span.sort-desc::after) {
              border-top: rem(6) solid $col_gray-600;
              border-bottom: none;
            }
          }
        }

        tbody {
          tr {
            text-align: left;
            font-weight: $fw_4;
            font-size: rem(12);
            line-height: rem(16);
            height: rem(48);

            td {
              color: $col_gray-600;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              a {
                cursor: pointer;
              }

              .img-action {
                display: inline-block;
                position: relative;

                img {
                  cursor: pointer;
                }

                .menu-action {
                  position: absolute;
                  top: 100%;
                  right: 0;
                  width: rem(160);
                  height: auto;
                  background: $col_white;
                  box-shadow: $box_shadow;
                  border-radius: rem(4);
                  padding: rem(12);
                  margin-top: rem(8);
                  z-index: 100;

                  li {
                    font-weight: $fw_5;
                    font-size: rem(14);
                    line-height: rem(20);
                    color: $col_gray_600;
                    padding-top: rem(12);
                    span {
                      cursor: pointer;
                      display: block;
                    }
                    &:first-child {
                      padding-top: 0;
                    }
                  }
                }
              }
            }

            .search-queries,
            .more-action {
              overflow: unset;
            }

            .search-queries {
              &-item {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
              }
            }

            &:last-child {
              td:first-child {
                border-bottom-left-radius: rem(8);
              }

              td:last-child {
                border-bottom-right-radius: rem(8);
              }
            }
          }

          tr:hover {
            background: $color_lavender;
          }

          .loading {
            position: absolute;
            top: 50%;
            left: 50%;
          }
        }

        th,
        td {
          padding-left: rem(10);

          &:first-child {
            width: 7%;
            padding-left: rem(32);
          }
          &:nth-child(2) {
            width: 16%;
          }
          &:nth-child(3) {
            width: 10%;
          }
          &:nth-child(4) {
            width: 36%;
          }
          &:nth-child(6),
          &:nth-child(7) {
            width: 8%;
          }

          &:nth-child(5),
          &:nth-child(8),
          &:nth-child(9) {
            width: 5%;
          }
        }
      }
    }
  }
}
