@import "assets/css/base.scss";

.entity-table-list {
  padding: rem(24);
  box-shadow: $box_shadow_icon;
  background: $col-white;
  border-radius: rem(8);
  margin-top: rem(24);

  h5 {
    font-size: rem(24);
    font-weight: $fw_4;
    line-height: rem(32);
    margin-bottom: rem(16);
  }

  .entity-table {
    border: rem(1) solid $col_gray-800;
    box-sizing: border-box;
    border-radius: rem(8);
    border-collapse: unset;
    border-spacing: inherit;
    position: relative;
    flex: 1;
    background-color: $col_white;
    min-height: rem(530);
  }
}

@media print {
  @-moz-document url-prefix() {
    .entity-table-list {
      padding: rem(12);
      margin-top: rem(16);

      h5 {
        margin-bottom: rem(12);
      }
      .entity-table {
        th,
        td {
          padding-left: rem(16);
          &:first-child {
            width: 5%;
          }
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(5) {
            width: 10%;
          }
        }
      }
    }
  }
}
